// EstimateDTO
// id	integer($int32)
// title	string
// area_size	integer($int32)
// sync_type	string
// apt_name	string
// dimensions	{...}
// calc_dimensions	{...}
// selected	{
// }
// collections	{...}
// total_price	integer($int32)
// state	string
// address	string
// created_at	string($date-time)

import { Checkbox, Input } from 'antd';
import { comma } from '../common/utils';

// TODO 고객정보, 잔여입찰기간, 공사예정일 등등 없는 것 확인
export const ESTIMATE_REQUS_DATA = [
  {
    id: 1,
    status: '열람',
    apt_name: '개봉 푸르지오',
    address: '서울시 구로구 남부순환로 775',
    area_size: '34PY',
    consumer: {
      name: '홍길동',
      phone: '010-1234-5678',
    },
    total_count: 3,
    memo_count: 1,
    total_price: 3400,
    created_at: new Date(),
    due_date: {
      remaining_period: '1',
      deadline: new Date(),
    },
    deadline: new Date(),
    remainBidDate: null,
    constructionDate: null,
  },
];

// TODO status enum으로 빼기
export const ESTIMATE_ONGOING_DATA = [
  {
    id: 1,
    status: '제안 대기중',
    apt_name: '개봉 푸르지오',
    proposal_price: 44999000,
    customer_price: 45139000,
    request_help: new Date(),
    submit_date: new Date(),
    view_day: new Date(),
    // memo_count: 1,
    // created_at: new Date(),
    // updated_at: new Date(),
    remainBidDate: null,
    constructionDate: null,
  },
];
export const ESTIMATE_APPLICATION_DATA = [
  {
    id: 1,
    status: '상담중',
    apt_name: '개봉 푸르지오',
    // area_size: '34PY',
    // address: '서울시 구로구 남부순환로 775',
    consumer: {
      name: '홍길동',
      phone: '010-1234-5678',
    },
    customer_price: 45139000,
    first_price: 44000000,
    final_price: 44000000,
    updated_at: new Date(),
    submit_date: new Date(),
    view_day: new Date(),
    // remainBidDate: null,
    // constructionDate: null,
  },
];

export const ESTIMATE_DETAIL_DATA = {
  id: 1,
  customer: {
    name: '홍길동',
    phone: '010-1234-5678',
  },
  apt_name: '개봉 푸르지오',
  address: '서울시 구로구 남부순환로 775',
  area_size: '34PY',
  created_at: new Date(),
  updated_at: new Date(),
  view_at: new Date(),
  total_price: 3400,
  memo_count: 1,
};

export const MEMOS = [
  {
    id: 1,
    content: '싸게 하면서 한샘으로 다 해달라고 함',
    name: '홍길동',
    create_at: new Date(),
  },
  {
    id: 2,
    content: '강마루 LG 껄로...',
    name: '홍길동',
    create_at: new Date(),
  },
  {
    id: 3,
    content: '실크벽지로 요청',
    name: '홍길동',
    create_at: new Date(),
  },
];

export const SCHEDULE_DATA = [
  {
    id: 1,
    type: '철거',
    company: '행복 인테리어',
    category: '바닥',
    construction: '바닥철거',
    schedule: Array.from({ length: 30 }).map((_, index) => ({
      day: index + 1,
      color: index % 3 === 0 ? '#FDFD96' : index % 7 === 0 ? '#A9CBD7' : '#FFFFFF',
    })),
  },
  {
    id: 2,
    type: '철거',
    company: '행복 인테리어',
    category: '가구',
    construction: '가구철거',
    schedule: Array.from({ length: 30 }).map((_, index) => ({
      day: index + 1,
      color: index % 6 === 0 ? '#808080' : index % 8 === 0 ? '#CFF0CC' : '#FFFFFF',
    })),
  },
];

export const MOCK_IMAGE_100 = 'https://via.placeholder.com/100';

export const ESTIMATE_AREA_DATA = [
  {
    title: '거실',
    size: 25.4,
    price: null,
  },
  {
    title: '주방',
    size: 25.4,
    price: null,
  },
  {
    title: '안방',
    size: 25.4,
    price: null,
  },
  {
    title: '방1',
    size: 25.4,
    price: null,
  },
  {
    title: '방2',
    size: 25.4,
    price: 12000000,
  },
  {
    title: '방3',
    size: 25.4,
    price: 9446026,
  },
  {
    title: '현관',
    size: 25.4,
    price: null,
  },
  {
    title: '화장실1',
    size: 25.4,
    price: null,
  },
  {
    title: '화장실2',
    size: 25.4,
    price: null,
  },
  {
    title: '베란다',
    size: 25.4,
    price: null,
  },
];

export const ESTIMATE_TYPE_DATA = [
  {
    title: '철거공사',
    price: null,
  },
  {
    title: '바닥공사',
    price: null,
  },
  {
    title: '도배공사',
    price: null,
  },
  {
    title: '전기공사',
    price: null,
  },
  {
    title: '페인트공사',
    price: 12000000,
  },
  {
    title: '주방공사',
    price: 9446026,
  },
  {
    title: '화장실공사',
    price: null,
  },
  {
    title: '베란다확장공사',
    price: null,
  },
  {
    title: '현관공사',
    price: null,
  },
  {
    title: '창호공사',
    price: null,
  },
];

export const ESTIMATE_TYPE_DETAIL_DATA = [
  {
    type: '페인트',
    name: '벽체페인트',
    material_name: undefined,
    unit: 'PY',
    quantity: '1.2',
    price: 100000,
    amount: 120000,
    sale_price: 90000,
    sale_amount: 108000,
    diff: 12000,
    deal_amount: undefined,
    deal_diff: 2000,
  },
];

export const ESTIMATE_AREA_DETAIL_DATA = [
  {
    type: '도배/페인트',
    name: '벽체페인트',
    material_name: undefined,
    unit: 'PY',
    price: 100000,
    amount: 120000,
    sale_price: 90000,
    sale_amount: 108000,
    deal_amount: 99000,
  },
];

export const ESTIMATE_ORDER_LIST_DATA = [
  {
    type: '페인트',
    name: '벽체페인트',
    check: <Checkbox />,
    product_name: '무궁화 페인트',
    company_name: '유통사A',
    unit: 'PY',
    count: <Input />,
    sale_price: 90000,
    sale_amount: 108000,
    delivery_date: <Input />,
    order_date: '22-05-27',
    id: 'D-2',
  },
];

export const CALCULATE_PAYMENT_DATA = [
  {
    created_at: '2022-05-20 00:00:00',
    type: '전도금',
    from: '홍길동 기사',
    income: 500000,
    remain: 490000,
    center: '',
    etc: '',
    dummy: '',
    detail: '',
  },
];

export const CALCULATE_USED_DATA = [
  {
    created_at: '2022-05-20 00:00:00',
    type: '식대',
    from: '홍길동 기사',
    outcome: 10000,
    etc: '',
    detail: '',
  },
];

export const ESTIMATE_DATA = [
  {
    id: 1,
    proposal_price: comma(41000000),
    final_date: new Date(),
    view_day: new Date(),
    modify: '',
  },
];

export const ESTIMATE_FLOOR_PLAN_DATA = [
  {
    id: 1,
    file_name: '개봉_푸르지오_주방수정.PDF',
    upload_date: new Date(),
    view_day: new Date(),
    delete: '',
  },
];

export const ESTIMATE_SPACE_DATA = [
  {
    id: 1,
    title: '등기구 확인해 주세요.',
    writer: '업체',
    upload_date: new Date(),
    view_day: new Date(),
    delete: '',
  },
];
export const ESTIMATE_ETC_DATA = [
  {
    id: 1,
    title: '등기구 확인해 주세요.',
    writer: '업체',
    upload_date: new Date(),
    view_day: new Date(),
    delete: '',
  },
];

export const MANAGER_STATUS_DATA = [
  {
    id: 1,
    status: '요청접수',
    apt_name: '개봉 푸르지오',
    customer_price: 45139000,
    first_price: 44000000,
    final_price: 44000000,
    updated_at: new Date(),
    submit_date: new Date(),
    view_day: new Date(),
  },
  {
    id: 2,
    status: 'IM 진행중',
    apt_name: '개포 래미안',
    customer_price: 45139000,
    first_price: 44000000,
    final_price: 44990000,
    updated_at: new Date(),
    submit_date: new Date(),
    view_day: new Date(),
  },
  {
    id: 3,
    status: '신청거절',
    apt_name: '개포 래미안',
    customer_price: 45139000,
    first_price: 44000000,
    final_price: 44990000,
    updated_at: new Date(),
    submit_date: new Date(),
    view_day: new Date(),
  },
];
