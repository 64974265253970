import React from 'react';

const useReference = () => {
  const [data, setData] = React.useState();
  return {
    data,
  };
};

export default useReference;
