import React from 'react';
import { Card, Divider, Image, Space, Typography } from 'antd';
import styles from './style.module.less';
import { MOCK_IMAGE_100 } from '../../../../../../mocks';
import { default as EstimateTable } from '../../../../../../components/construction';

const Estimate = () => {
  return (
    <Card className={styles.EstimateJSX}>
      <div className={styles.header}>
        <div className={styles['header-image']}>
          <Typography.Title level={5}>고객이 선택한 스타일</Typography.Title>
          <Space size="large">
            <div className={styles['image-wrapper']}>
              <Image src={MOCK_IMAGE_100} className={styles.image} />
              <span className={styles['image-title']}>거실</span>
            </div>
            <div className={styles['image-wrapper']}>
              <Image src={MOCK_IMAGE_100} className={styles.image} />
              <span className={styles['image-title']}>방1</span>
            </div>
            <div className={styles['image-wrapper']}>
              <Image src={MOCK_IMAGE_100} className={styles.image} />
              <span className={styles['image-title']}>방2</span>
            </div>
            <div className={styles['image-wrapper']}>
              <Image src={MOCK_IMAGE_100} className={styles.image} />
              <span className={styles['image-title']}>현관</span>
            </div>
          </Space>
        </div>
        <div className={styles['header-price']}>
          <span className={styles.sum}>전체 합계</span>
          <span className={styles.price}>21,446,026 원</span>
        </div>
      </div>
      <Divider />
      <EstimateTable />
    </Card>
  );
};

export default Estimate;
