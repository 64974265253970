import React from 'react';
import { PageHeader } from 'antd';

const SettingsQnaIndex = (props) => {
  return (
    <div>
      <PageHeader className="site-page-header" title="Q&A" subTitle="설정" />
    </div>
  );
};

export default SettingsQnaIndex;
