import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from '../../utils/classnames';
import styles from './Calendar.module.less';

const StyledBody = styled.table`
  text-align: center;
  margin: 20px;
  th {
    position: static;
    width: 32px;
    height: 32px;
    color: #292929;
  }
`;

export default function Calendar() {
  //day
  const dayjs = require('dayjs');
  const weekday = require('dayjs/plugin/weekday');
  const isoWeek = require('dayjs/plugin/isoWeek');
  const weekOfYear = require('dayjs/plugin/weekOfYear');

  // day extend
  dayjs.extend(weekday);
  dayjs.extend(isoWeek);
  dayjs.extend(weekOfYear);

  const [viewDate, setViewDate] = useState(dayjs());
  const [selectDate, setSelectDate] = useState(dayjs());

  const CONSTRUCT_SCHEDULE = {
    id: 1,
    start_at: '2023-04-02',
    close_at: '2023-05-15',
  };
  const startDate = CONSTRUCT_SCHEDULE.start_at.slice(5, 10);
  const closeDate = CONSTRUCT_SCHEDULE.close_at.slice(5, 10);

  const createCalendar = () => {
    const today = dayjs();
    const startWeek = viewDate.startOf('month').week(); // 이달의 시작주
    const endWeek = viewDate.endOf('month').week() === 1 ? 53 : viewDate.endOf('month').week(); // 이달의 끝주
    let calender = [];

    for (let week = startWeek; week <= endWeek; week++) {
      calender.push(
        <tr key={week}>
          {Array(7)
            .fill(0)
            .map((data, index) => {
              let currentDays = viewDate
                .startOf('week')
                .week(week) // 이번주 첫날
                .add(index, 'day');
              if (viewDate.format('MM') === '12') {
                currentDays = viewDate
                  .startOf('week')
                  .week(week - 52)
                  .add(data + index, 'day');
              }
              // 현재 날짜 (기준)
              let isSelected = selectDate.format('YYYYMMDD') === currentDays.format('YYYYMMDD') ? 'selected' : '';
              let isToday = today.format('YYYYMMDD') === currentDays.format('YYYYMMDD') ? 'today' : '';
              let isNone = currentDays.format('MM') !== viewDate.format('MM') ? 'none' : '';

              let currentDate = currentDays.format('MM-DD');

              let isScheduled = startDate <= currentDate && currentDate <= closeDate ? 'schedule' : '';
              let isStartDate = startDate === currentDate ? 'startDate' : '';
              return (
                <td className={styles.box} key={`${week}_${index}`}>
                  <div
                    className={classNames(
                      styles.text,
                      `${isSelected && styles.selected}`,
                      `${isToday && styles.today}`,
                      `${isNone && styles.none}`,
                      `${isScheduled && styles.schedule}`,
                    )}
                    onClick={() => {
                      setSelectDate(currentDays);
                    }}
                  >
                    <span className={styles.day}>{currentDays.format('D')}</span>
                    {isToday ? (
                      <span className={styles.isToday}></span>
                    ) : isSelected ? (
                      <span className={styles.isSelected}></span>
                    ) : isStartDate ? (
                      <span className={styles.isStartDate}></span>
                    ) : null}
                  </div>
                </td>
              );
            })}
        </tr>,
      );
    }
    return calender;
  };

  return (
    <div style={{ width: 400, margin: '0 auto' }}>
      <div style={{ textAlign: 'center' }}>
        <button
          onClick={() => {
            return setViewDate(viewDate.subtract(1, 'month'));
          }}
          style={{ border: 'none', backgroundColor: '#fff', cursor: 'pointer' }}
        >
          <span>{`<`}</span>
        </button>
        <span>{viewDate.format('YYYY')} </span>
        <span className={styles.thisMonth}>{viewDate.format('M')}</span>월
        <button
          onClick={() => {
            return setViewDate(viewDate.add(1, 'month'));
          }}
          style={{ border: 'none', backgroundColor: '#fff', cursor: 'pointer' }}
        >
          <span>{`>`}</span>
        </button>
      </div>
      <StyledBody>
        <thead>
          <tr>
            <th>S</th>
            <th>M</th>
            <th>T</th>
            <th>W</th>
            <th>T</th>
            <th>F</th>
            <th>S</th>
          </tr>
        </thead>
        <tbody>{createCalendar()}</tbody>
      </StyledBody>
    </div>
  );
}
