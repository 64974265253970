import React from 'react';
import { PageHeader } from 'antd';

const CollectionsIndex = (props) => {
  return (
    <div>
      <PageHeader className="site-page-header" title="스타일 관리" />
    </div>
  );
};

export default CollectionsIndex;
