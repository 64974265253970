import { Button, Col, Modal, PageHeader, Row, Switch, Tabs } from 'antd';
import React from 'react';
import styles from './index.module.less';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import { CaretRightOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../../apis';
import { uniq } from 'lodash';
import { ESTIMATE_ITEM_TYPE } from '../../../../../common/define';
import DiffTable from '../../../../../components/DiffTable';
import { useProductFinder, withProductFinder } from '../../../../../components/ProductFinder';
import AnnexTable from '../../../../../components/table/AnnexTable';

const TabsBtn = styled(Tabs)`
  .ant-tabs-tab {
    background-color: #c1c1c1;
    padding-right: 15px;
    padding-left: 15px;
    width: 90px;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 5px;
  }
  .ant-tabs-tab-btn {
    color: #fff;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-nav-operations .ant-tabs-nav-more {
    background-color: #c1c1c1;
    cursor: pointer;
  }
`;

function WriteEstimate() {
  // TODO : 저장 시 상담제안 발송 버튼 변경 작업 필요

  const navigate = useNavigate();
  const { tplCategories, estimateTpls } = useSelector((s) => s.common, shallowEqual);
  const [showByArea, setShowByArea] = React.useState(false);
  const [hideExtra, setHideExtra] = React.useState(false);
  const [tab, setTab] = React.useState('Category_0');
  const [title, setTitle] = React.useState('철거');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const navBtnRef = React.useRef();
  const { products, addPids } = useProductFinder();
  const { id } = useParams();

  const handleSlideChange = (current, next) => {
    console.log({ current, next });
    setCurrentIndex(next);
  };

  const [data, setData] = React.useState();
  const [comSelected, setComSelected] = React.useState();
  React.useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/estimates/${id}`);
      console.log({ data });
      setData(data);
      setComSelected(data?.com_selected || data.selected || []);
      const pids = uniq(
        data.selected
          ?.map((x) => x.products)
          ?.filter((x) => !!x)
          ?.reduce((a, b) => a.concat(b), [])
          ?.map((x) => x.product_id),
      );
      addPids(pids);
    };
    loadData().catch(console.warn);
  }, [id]);

  const areas = React.useMemo(() => {
    const tabItems = Object.keys(data?.calc_dimensions ?? {})
      .reduce((a, key) => {
        const items = data?.calc_dimensions[key];
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          let title = ESTIMATE_ITEM_TYPE[key];
          if (items.length > 1) {
            title += ` ${i + 1}`;
          }
          a.push({
            id: key + '_' + i,
            type: key,
            name: title,
            dimen: item,
          });
        }
        return a;
      }, [])
      .concat([
        {
          id: '_all',
          type: '_all',
          name: '창호/서비스',
          dimen: {
            sash: data?.calc_dimensions?.sash,
            size: data?.area_size,
          },
        },
      ]);
    return tabItems;
  }, [data?.calc_dimensions]);

  const tabs = React.useMemo(() => {
    if (!showByArea) {
      return tplCategories?.map((v) => ({ id: v.id, name: v.name }));
    }
    return areas;
    //data
  }, [showByArea, tplCategories, areas]);

  const tabItems = tabs?.map((tab) => {
    const itemOrigins = !showByArea ? areas : tplCategories?.map((v) => ({ id: v.id, name: v.name }));
    const items = itemOrigins
      ?.map((item) => {
        let extra = { id: undefined, type: undefined, category: undefined };
        if (!showByArea) {
          extra.id = item.id;
          extra.type = item.type;
          extra.category = tab.id;
          const tpls = uniq(
            estimateTpls
              ?.filter((x) => x.main_category?.id === tab.id)
              ?.map((x) => x.element_types)
              ?.reduce((a, b) => a.concat(b), []),
          );
          if (!tpls?.includes(item.type)) return undefined;
        } else {
          extra.id = tab.id;
          extra.type = tab.type;
          extra.category = item.id;
          const tpls = uniq(
            estimateTpls
              ?.filter((x) => x.element_types?.includes(tab.type))
              ?.map((x) => x.main_category?.id)
              ?.reduce((a, b) => a.concat(b), []),
          );
          if (!tpls?.includes(item.id)) return undefined;
        }

        return {
          ...item,
          area_id: extra.id,
          area_type: extra.type,
          main_category_id: extra.category,
          filter: !showByArea ? (x) => x.key === item.id : (x) => x.key === tab.id && x.main_category_id === item.id,
          getDimen: !showByArea ? () => item.dimen : () => tab.dimen,
        };
      })
      .filter((x) => !!x);

    return {
      label: tab.name,
      key: `${tab.id}`,
      children: (
        <DiffTable
          dimensions={data?.calc_dimensions}
          hideExtra={hideExtra}
          usrSelected={data?.selected}
          usrPrices={data?.prices}
          comSelected={comSelected}
          setComSelected={setComSelected}
          items={items}
          filter={showByArea ? () => true : (x) => x.main_category.id == tab.id}
          label={'contract'}
        />
      ),
    };
  });

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
              <PageHeader
                title="제안견적작성"
                extra={[
                  <AnnexTable
                    column={4}
                    items={[
                      { label: '주택명', value: `${data?.apt_name} ${data?.total_price}` },
                      { label: '면적', value: `${data?.area_size}PY` },
                      { label: '현장주소', value: data?.address },
                      { label: '고객정보', value: `${data?.account?.nickname} ${data?.account.phone}` },
                    ]}
                  />,
                ]}
              />
            </div>
            {/* <Descriptions bordered column={4}>
              <Descriptions.Item label={'주택명'}></Descriptions.Item>
              <Descriptions.Item label={'면적'}>{data?.area_size}PY</Descriptions.Item>
              <Descriptions.Item label={'현장주소'}>{data?.address}</Descriptions.Item>
              <Descriptions.Item label={'고객정보'}>
                {data?.account.nickname} / {data?.account.phone}
              </Descriptions.Item>
            </Descriptions> */}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ paddingBottom: 10, flex: 1 }}>
              <span>공종별 보기</span>
              <Switch checked={showByArea} onClick={() => setShowByArea((x) => !x)} style={{ margin: '0px 10px' }} />
              <span>공간별 보기</span>
            </div>
            <div style={{ display: 'flex', gap: 5 }}>
              <Button type={'primary'} style={{ width: 120 }} onClick={() => navigate('/customers/proposal_progress')}>
                나가기
              </Button>

              <Button type={'primary'} style={{ margin: '0 10px', width: 120 }} onClick={() => setIsModalOpen(true)}>
                저장
              </Button>
              <Button
                type={'danger'}
                style={{ width: 120 }}
                onClick={() => {
                  const userSelected = data?.selected;
                  if (!window.confirm('초기화 하시겠습니까?')) return;
                  setComSelected([...userSelected]);
                }}
              >
                제안서 초기화
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Row>
        <Col span={24}>
          <div className={styles.tabNavContainer} />
          <TabsBtn items={tabItems} moreIcon={<CaretRightOutlined />} />
        </Col>
      </Row>

      <Modal
        open={isModalOpen}
        centered
        footer={null}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => setIsModalOpen(false)}
        width={350}
        bodyStyle={{ height: 280 }}
        closable={null}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>저장</h2>
          <button className={styles.closeBtn} onClick={() => setIsModalOpen(false)}>
            <span />
            <span />
          </button>
        </div>
        <div style={{ textAlign: 'center', padding: 50 }}>
          <p style={{ fontSize: 16 }}>견적서가 저장되었습니다.</p>
          <Button
            type="primary"
            style={{ marginTop: 50, width: 120 }}
            onClick={() => {
              setIsModalOpen(false);
              navigate(`/customers/contract/1`);
            }}
          >
            확인
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default withProductFinder(WriteEstimate);
