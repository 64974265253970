import { Button, Modal, PageHeader } from 'antd';
import React from 'react';
import styles from './index.module.less';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { restApi } from '../../../apis';

export default function ProposalApplication() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isModalOpen2, setIsModalOpen2] = React.useState(false);

  const navigate = useNavigate();

  const { applications } = useSelector((s) => s.common, shallowEqual);
  const handleSubmit = (item) => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <PageHeader title="상담신청 접수 리스트" />
      <div className={styles.container}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 30 }}>
          {applications?.map((item) => (
            <div className={styles.listBox}>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: 20 }}>
                <span style={{ fontWeight: 500 }}>상담신청</span>
                <span style={{ fontWeight: 300 }}>{moment(item?.created_at).format('YYYY-MM-DD HH:mm')}</span>
              </div>
              <div style={{ textAlign: 'center' }}>
                <div style={{ position: 'relative', marginBottom: 30 }}>
                  <img
                    alt="customer"
                    width={100}
                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    style={{ borderRadius: 48 }}
                  />
                  <div
                    className={styles.newTagWrapper}
                    style={{ top: 0, right: 110, borderRadius: 5, paddingInline: 5 }}
                  >
                    <span className={styles.newTag}>NEW</span>
                  </div>
                </div>
                <strong style={{ fontSize: 20 }}>{item?.construct?.estimate?.title}</strong>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                  <span style={{ fontWeight: 300 }}>상담수락마감시간 21:45</span>
                  <span style={{ fontWeight: 600 }}>(24H)</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: 15 }}>
                  <Button
                    type="primary"
                    style={{ width: 150 }}
                    onClick={() => navigate(`/customers/details/${item.id}`)}
                  >
                    내용보기
                  </Button>
                  <Button type="primary" style={{ width: 150 }} onClick={() => setIsModalOpen(item)}>
                    상담시작
                  </Button>
                </div>
              </div>
              <button
                className={styles.closeBtn}
                style={{ position: 'absolute', top: '-20px', right: '-20px' }}
                onClick={async () => {
                  if (!window.confirm('삭제하시겠습니까?')) return;
                  await restApi.put(`/partners/proposals/${item.id}`, { state: 'REJECT' });
                  alert('삭제되었습니다.');
                  window.location.reload();
                }}
              >
                <span />
                <span />
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* 결재 모달 */}
      <Modal
        open={!!isModalOpen}
        centered
        footer={null}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={500}
        bodyStyle={{ padding: 40 }}
        closable={null}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>결재</h2>
          <img
            src={require('../../../assets/icon/icon_template_close_sample.png')}
            alt="템플릿 제거 버튼"
            style={{ width: 30, height: 30 }}
            onClick={() => setIsModalOpen(false)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ fontSize: 18, fontWeight: 300 }}>50,000포인트가 결재됩니다.</p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className={styles.pointLine}>
              <strong className={styles.pointTitle}>보유 포인트</strong>
              <span className={styles.textContent}>100,000</span>
            </div>
            <div className={styles.pointLine}>
              <strong className={styles.pointTitle}>차감 포인트</strong>
              <span className={styles.textContent}>5,000</span>
            </div>
            <div className={styles.pointLine}>
              <strong className={styles.pointTitle}>잔여 포인트</strong>
              <span className={styles.textContent}>5,000</span>
            </div>
          </div>
          <div className={styles.imgBox}>
            <img
              src={require('../../../assets/icon/baba_pass.png')}
              style={{ width: '100%', height: 'auto' }}
              alt="baba_pass"
            />
            <div className={styles.pointBox}>
              <span style={{ fontSize: 22, fontWeight: 600 }}>50,000</span>
              <span>포인트</span>
            </div>
          </div>
          <p style={{ fontSize: 16, fontWeight: 600 }}>결재 후 제공 내용</p>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>고객정보</div>
            <span className={styles.infoContent}>상담요청고객정보(성명, 전화번호)</span>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>공사정보</div>
            <span className={styles.infoContent}>고객선정 스타일, 고객작성견적, 적용자재 정보</span>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>주택정보</div>
            <span className={styles.infoContent}>주소, 면적, 사용승인일, 관리사무실 연락처 등</span>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>상담시스템</div>
            <span className={styles.infoContent}>견적서 및 자료를 고객과 공유하는 비대면 상담</span>
          </div>
          <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-evenly' }}>
            <Button type="primary" size="large" style={{ width: 130 }} onClick={() => handleSubmit(isModalOpen)}>
              포인트 결제
            </Button>
            <Button
              type="danger"
              size="large"
              style={{ width: 130 }}
              onClick={() => {
                setIsModalOpen(false);
                navigate(`/points`);
              }}
            >
              포인트 충전
            </Button>
          </div>
        </div>
      </Modal>

      {/* 알림모달 */}
      <Modal
        open={isModalOpen2}
        centered
        footer={null}
        onOk={() => setIsModalOpen2(false)}
        onCancel={() => setIsModalOpen2(false)}
        width={400}
        bodyStyle={{ padding: 15 }}
        closable={null}
      >
        <div style={{ textAlign: 'end' }}>
          <img
            src={require('../../../assets/icon/icon_template_close_sample.png')}
            alt="템플릿 제거 버튼"
            style={{ width: 30, height: 30 }}
            onClick={() => setIsModalOpen2(false)}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <img
            src={require('../../../assets/icon/icon_congratulation.png')}
            alt="congratulation"
            style={{ height: '150px', width: 'auto' }}
          />
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', padding: '0 45px', marginTop: 20 }}>
            <h2>축하합니다.</h2>
            <span className={styles.textContent}>고객이 귀사의 제안서를</span>
            <span className={styles.textContent}>채택하였습니다.</span>
            <span className={styles.textContent}>지금 바로 상담을 시작하여</span>
            <span className={styles.textContent}>귀사의 매출로 연결시켜 보세요.</span>
          </div>
          <div style={{ marginTop: 30, marginBottom: 30, display: 'flex', justifyContent: 'center' }}>
            <Button
              type="primary"
              size="middle"
              style={{ width: 130, display: 'flex', justifyContent: 'center', marginRight: 5 }}
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              결제하고 상담시작
            </Button>
            <Button
              type="danger"
              size="middle"
              style={{ width: 130, marginLeft: 5 }}
              onClick={() => setIsModalOpen(false)}
            >
              나중에
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
