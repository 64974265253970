import { Button, Collapse, Layout, Space, Timeline } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import Calendar from '../../../../components/template/Calendar';
import FeedLayout from '../../../../components/template/FeedLayout';
import classNames from '../../../../utils/classnames';
import styles from './index.module.less';

const { Header, Content } = Layout;
const { Panel } = Collapse;

const headerStyle = {
  padding: '0 15px',
  backgroundColor: '#fff',
  marginBottom: 15,
  lineHeight: '18px',
};

const CONSTRUCT_NAV = [
  { label: 'IM홈', value: '/im/status', image: 'icon_home_nav_sample.png' },
  { label: '일정표작성/수정', value: '/im/status/construct/schedule', image: 'icon_calendar_nav_sample.png' },
  { label: '발주관리', value: '/im/status/construct/order', image: 'icon_cart_nav_sample.png' },
];

const ConstructText = (
  <>
    <div className={classNames(styles.leftRed, styles.leftBlue)} />
    <span style={{ fontWeight: 600, flex: 1 }}>일반철거</span>
  </>
);

const TODO_LIST = [
  {
    type: '발주',
    title: '도배자재 발주',
  },
  {
    type: '정산',
    title: '중도금2차 입금확인 및 세금계산서 발행',
  },
];
const TodoText = ({ type, title }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: 30, fontSize: 12 }}>{type}</span>
      <strong>{title}</strong>
    </div>
  );
};
export default function ManagerConstructIndex() {
  const navigate = useNavigate();

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
      size={[0, 48]}
    >
      <Layout>
        <Layout style={{ marginRight: 15 }}>
          <Header style={headerStyle}>
            <div style={{ display: 'flex', height: 64 }}>
              <span className={styles.headerTitle}>공사현황</span>
              <div className={styles.headerContents}>
                <strong style={{ fontSize: 15 }}>이매촌 진흥아파트</strong>
                <span style={{ fontSize: 12 }}>경기도 성남시 분당구 이매동 111번지</span>
              </div>
              <span className={styles.headerDate}>2023년 01월 23일 수요일 17:00:00</span>
            </div>
          </Header>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Content style={{ marginRight: 15, flex: 0, flexBasis: 100, backgroundColor: '#fff' }}>
              {CONSTRUCT_NAV.map((item, index) => {
                return (
                  <div
                    key={`CONSTRUCT_NAV_ITEM_${index}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingBlock: 5,
                      width: '100%',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate(`${item?.value}`);
                    }}
                  >
                    <img
                      src={require(`../../../../assets/icon/${item.image}`)}
                      alt="네비게이션 버튼 이미지"
                      style={{ width: 60, height: 60, marginBottom: 5 }}
                    />
                    <span style={{ fontSize: 13, fontWeight: '500' }}>{item?.label}</span>
                  </div>
                );
              })}
            </Content>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', flex: 1, gap: 15 }}>
              <Content style={{ padding: 15, backgroundColor: '#fff' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ fontSize: 18 }}>일정</p>
                  <Button type="primary" size="small">
                    일정표보기
                  </Button>
                </div>
                <Calendar />
              </Content>
              <Content style={{ padding: 15, backgroundColor: '#fff' }}>
                <p style={{ fontSize: 18 }}>공사일보</p>
                <Collapse onChange={onChange} bordered={false} className={styles.construct}>
                  <Panel header={ConstructText} key="1">
                    <div style={{ display: 'flex', marginTop: 10, marginBottom: 15 }}>
                      <img
                        src={require(`../../../../assets/icon/icon_user_default.png`)}
                        alt="공사일보"
                        style={{ width: 40, height: 40 }}
                      />
                      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                        <strong>성진철거</strong>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>김환기 사장</span>
                          <span>010-1234-5678</span>
                        </div>
                      </div>
                    </div>
                    <Timeline mode="left">
                      <Timeline.Item>
                        <div style={{ display: 'flex' }}>
                          <span style={{ flex: 1 }}>작업자 출근</span>
                          <div>
                            <div style={{ fontSize: 12 }}>기한 09:00</div>
                            <div style={{ fontSize: 12 }}>확인 17:00</div>
                          </div>
                        </div>
                      </Timeline.Item>
                      <Timeline.Item>
                        <div style={{ display: 'flex' }}>
                          <span style={{ flex: 1 }}>오전작업보고</span>
                          <div>
                            <div style={{ fontSize: 12 }}>기한 09:00</div>
                            <div style={{ fontSize: 12 }}>확인 17:00</div>
                          </div>
                        </div>
                        <img
                          src={require(`../../../../assets/icon/icon_pass_default.png`)}
                          alt="공사일보"
                          style={{ width: '100%' }}
                        />
                      </Timeline.Item>
                      <Timeline.Item>More information</Timeline.Item>
                    </Timeline>
                  </Panel>
                </Collapse>
              </Content>
              <Content style={{ padding: 15, backgroundColor: '#fff' }}>
                <p style={{ fontSize: 18 }}>할일</p>
                {TODO_LIST?.map((todo, index) => (
                  <Collapse onChange={onChange} bordered={false} className={styles.todo} key={`Todo_${index}`}>
                    <Panel header={TodoText(todo)} key="1"></Panel>
                  </Collapse>
                ))}
              </Content>
              <Content style={{ padding: 15, backgroundColor: '#fff' }}>
                <p style={{ fontSize: 18 }}>공사가이드</p>
                <span>철거는 공사를 시작하는 상황에서 좋은공사의 척도가 될 수...</span>
                <span>철거는 공사를 시작하는 상황에서 좋은공사의 척도가 될 수...</span>
              </Content>
            </div>
          </div>
        </Layout>
        <FeedLayout />
      </Layout>
    </Space>
  );
}
