import React from 'react';
import moment from 'moment';

export const processing_columns = [
  {
    title: '',
    key: 'idx',
    dataIndex: 'id',
    width: 50,
    align: 'center',
  },
  {
    title: '기안일',
    key: 'due_date',
    dataIndex: 'due_date',
    align: 'center',
    render: (value) => value && moment(value).format('YYYY.MM.DD'),
    width: 100,
  },
  {
    title: '결재양식',
    dataIndex: 'form',
    key: 'form',
    width: 150,
    align: 'center',
  },
  {
    title: '긴급',
    key: 'emergency',
    dataIndex: 'emergency',
    width: 100,
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '첨부',
    key: 'file',
    dataIndex: 'file',
    width: 150,
    align: 'center',
  },
  {
    title: '결제상태',
    key: 'status',
    dataIndex: 'status',
    width: 150,
    align: 'center',
  },
];

export const complete_columns = [
  {
    title: '',
    key: 'idx',
    dataIndex: 'id',
    width: 50,
    align: 'center',
  },
  {
    title: '기안일',
    key: 'due_date',
    dataIndex: 'due_date',
    align: 'center',
    render: (value) => value && moment(value).format('YYYY.MM.DD'),
    width: 100,
  },
  {
    title: '완료일',
    dataIndex: 'end_at',
    key: 'end_at',
    width: 100,
    align: 'center',
  },
  {
    title: '결제양식',
    key: 'form',
    dataIndex: 'form',
    width: 100,
    align: 'center',
  },
  {
    title: '긴급',
    key: 'emergency',
    dataIndex: 'emergency',
    width: 100,
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '첨부',
    key: 'file',
    dataIndex: 'file',
    width: 150,
    align: 'center',
  },
  {
    title: '결제상태',
    key: 'status',
    dataIndex: 'status',
    width: 150,
    align: 'center',
  },
];

export const received_columns = [
  {
    title: '',
    key: 'idx',
    dataIndex: 'id',
    width: 50,
    align: 'center',
  },
  {
    title: '접수일',
    key: 'due_date',
    dataIndex: 'due_date',
    align: 'center',
    render: (value) => value && moment(value).format('YYYY.MM.DD'),
    width: 100,
  },
  {
    title: '담당자',
    dataIndex: 'end_at',
    key: 'end_at',
    width: 100,
    align: 'center',
  },
  {
    title: '결제양식',
    key: 'form',
    dataIndex: 'form',
    width: 100,
    align: 'center',
  },
  {
    title: '긴급',
    key: 'emergency',
    dataIndex: 'emergency',
    width: 100,
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '첨부',
    key: 'file',
    dataIndex: 'file',
    width: 150,
    align: 'center',
  },
  {
    title: '결제상태',
    key: 'status',
    dataIndex: 'status',
    width: 150,
    align: 'center',
  },
];

const useDocument = () => {
  const [processingDocument, setProcessingDocument] = React.useState();
  const [completeDocument, setCompleteDocument] = React.useState();
  const [receivedDocument, setReceivedDocument] = React.useState();
  return {
    processingDocument,
    completeDocument,
    receivedDocument,
  };
};

export default useDocument;
