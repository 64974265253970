import React from 'react';
import { comma } from '../../../common/utils';
import moment from 'moment';
import { Button } from 'antd';
import useQuery from '../../../hooks/useQuery';

export const columns = [
  {
    title: '번호',
    key: 'id',
    dataIndex: 'id',
    width: 100,
    align: 'center',
  },
  {
    title: '주소',
    key: 'address',
    dataIndex: 'address',
    width: 300,
    align: 'center',
  },
  {
    title: '평수',
    dataIndex: 'area_size',
    key: 'area_size',
    width: 100,
    align: 'center',
    render: (value) => `${value}평`,
  },
  {
    title: '담당자',
    key: 'manager',
    dataIndex: 'manager',
    width: 100,
    align: 'center',
  },
  {
    title: '의뢰 고객',
    dataIndex: 'consumer',
    key: 'consumer',
    width: 200,
    align: 'center',
    render: (value) => `${value?.name} / ${value?.phone}`,
  },
  {
    title: '계약금액',
    key: 'money1',
    dataIndex: 'money1',
    width: 100,
    align: 'center',
    render: (value) => `${comma(value)}만원`,
  },
  {
    title: '실행금액',
    key: 'money2',
    dataIndex: 'money2',
    width: 100,
    align: 'center',
    render: (value) => `${comma(value)}만원`,
  },
  {
    title: '집행금액',
    key: 'money3',
    dataIndex: 'money3',
    width: 100,
    align: 'center',
    render: (value) => `${comma(value)}만원`,
  },
  {
    title: '계약일',
    width: 200,
    dataIndex: 'contract_at',
    key: 'contract_at',
    align: 'center',
    render: (value) => moment(value).format('YYYY.MM.DD'),
  },
  {
    title: '진행상태',
    key: 'status',
    dataIndex: 'status',
    width: 100,
    align: 'center',
  },
  {
    title: '',
    key: 'detail',
    dataIndex: 'id',
    width: 100,
    align: 'center',
  },
];

const MOCK = [
  {
    id: 1,
    address: '서울시 구로구 남부순환로 775',
    area_size: 34,
    manager: '김병욱',
    consumer: {
      name: '홍길동',
      phone: '010-1234-4897',
    },
    money1: 1000,
    money2: 1000,
    money3: 1000,
    created_at: new Date(),
    status: '공사대기',
  },
];

const useList = () => {
  const [data, setData] = React.useState(MOCK);
  const { navigate } = useQuery();

  columns[10].render = (value) => {
    return <Button onClick={() => navigate(`/construction/${value}`)}>상세</Button>;
  };
  return {
    data,
  };
};

export default useList;
