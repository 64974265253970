import React from 'react';
import { Button, Card, Divider, Form, Input, Modal, Space, Table, Typography } from 'antd';
import SearchForm from '../../../../../../components/datalist/SearchForm';
import { CALCULATE_SEARCH_FIELDS } from '../../../../../../constants/searchFields';
import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { comma } from '../../../../../../common/utils';
import styles from './style.module.less';
import useCalculate, { payment_columns, used_columns } from './useCalculate';
import Payment from './Payment';
import Used from './Used';
import { CALCULATE_PAYMENT_DATA, CALCULATE_USED_DATA } from '../../../../../../mocks';

ChartJS.register(ArcElement, Tooltip);

const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3],
      backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)'],
      borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
      borderWidth: 1,
    },
  ],
};

const Calculate = () => {
  const { paymentData, usedData, modal, onSubmit, addPayment, addUsed, closeModal } = useCalculate();
  const { Title, Text } = Typography;
  return (
    <Card className={styles.wrapper}>
      <div className={styles['top-wrapper']}>
        <SearchForm fields={CALCULATE_SEARCH_FIELDS} />
        <div className={styles['chart-wrapper']}>
          <div className={styles.chart}>
            <Pie data={data} style={{ width: 100, height: 100 }} />
          </div>
          <Form className={styles.priceList}>
            <Form.Item label="공사 계약금액" name="">
              <Text>{comma(30000000)}</Text>
            </Form.Item>
            <Form.Item label="실행 예정금액" name="">
              <Input />
            </Form.Item>
            <Form.Item label="집행 된 금액" name="">
              <Input />
            </Form.Item>
            <Form.Item label="미결 금액" name="">
              <Input />
            </Form.Item>
          </Form>
          {/* 데이터를 전송하는 버튼이 견적서에 없음 / 필요 시 위치 확인하고 추가 */}
          {/* name 임시 */}
        </div>
      </div>
      <Divider />
      <Table
        dataSource={CALCULATE_PAYMENT_DATA}
        // paymentData state 있음
        columns={payment_columns}
        title={() => <Title level={5}>전도금 요소</Title>}
        pagination={false}
      />
      <Table
        dataSource={CALCULATE_USED_DATA}
        // usedData state 있음
        columns={used_columns}
        title={() => <Title level={5}>사용된 비용</Title>}
        pagination={false}
      />
      <Space className={styles.footer}>
        <Button type="primary" size="large" onClick={addPayment}>
          전도금 등록
        </Button>
        <Button type="primary" size="large" onClick={addUsed}>
          비용 등록
        </Button>
      </Space>
      <Modal centered open={!!modal} footer={null} onCancel={closeModal}>
        {modal === 'payment' ? <Payment onSubmit={onSubmit} /> : <Used onSubmit={onSubmit} />}
      </Modal>
    </Card>
  );
};

export default Calculate;
