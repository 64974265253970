import React from 'react';
import { Tabs } from 'antd';
import Estimate from './estimate';
import Schedule from './schedule';
import Order from './order';
import DailyReport from './dailyReport';
import Calculate from './calculate';
import Document from './document';
import Subcontract from './subcontract';
import Reference from './reference';

const ConstructionTabs = () => {
  return (
    <Tabs type="card">
      <Tabs.TabPane tab="견적보기" key={'estimate'}>
        <Estimate />
      </Tabs.TabPane>
      <Tabs.TabPane tab="일정관리" key={'schedule'}>
        <Schedule />
      </Tabs.TabPane>
      <Tabs.TabPane tab="발주관리" key={'order'}>
        <Order />
      </Tabs.TabPane>
      <Tabs.TabPane tab="공사일보" key={'dailyReport'}>
        <DailyReport />
      </Tabs.TabPane>
      <Tabs.TabPane tab="정산관리" key={'calculate'}>
        <Calculate />
      </Tabs.TabPane>
      <Tabs.TabPane tab="전자결제" key={'document'}>
        <Document />
      </Tabs.TabPane>
      <Tabs.TabPane tab="하도급" key={'subcontract'}>
        <Subcontract />
      </Tabs.TabPane>
      <Tabs.TabPane tab="자료실" key={'reference'}>
        <Reference />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ConstructionTabs;
