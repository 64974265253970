import React, { useState } from 'react';
import { Button, Card, Descriptions, Divider, Image, Input, Modal, PageHeader, Space, Typography, Upload } from 'antd';
import useCreate from './useCreate';

// Custom
import Estimate from '../../../components/estimate';

// CSS
import styles from './styles.module.less';
import { MOCK_IMAGE_100 } from '../../../mocks';
import { PlusOutlined } from '@ant-design/icons';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EstimateCreate = () => {
  const { data, topButtons, isVisible, closeModal, footer } = useCreate();
  const { Text, Title, Paragraph } = Typography;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },

    {
      uid: '-5',
      name: 'image.png',
      status: 'error',
    },
  ]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div>
      <PageHeader title="견적 작성" extra={topButtons} />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="고객">{`${data.customer.name} (${data.customer.phone})`}</Descriptions.Item>
        <Descriptions.Item label="주소">{`${data.apt_name} (${data.area_size})`}</Descriptions.Item>
        <Descriptions.Item>&nbsp;</Descriptions.Item>
      </Descriptions>
      <Card>
        <div className={styles.subheader}>
          {/* <div className={styles.subheaderStyle}>
          <Title level={5}>고객이 선택한 스타일</Title>
          <ul>
            <li>
              <Image
                width={100}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                alt="고객이 선택한 거실 스타일"
                preview={false}
              />
              <Paragraph className={styles.paragraph}>거실</Paragraph>
            </li>
          </ul>
        </div> */}
          <div className={styles['header-image']}>
            <Typography.Title level={5}>고객이 선택한 스타일</Typography.Title>
            <Space size="large">
              <div className={styles['image-wrapper']}>
                <Image src={MOCK_IMAGE_100} className={styles.image} />
                <Text className={styles['image-title']}>거실</Text>
              </div>
              <div className={styles['image-wrapper']}>
                <Image src={MOCK_IMAGE_100} className={styles.image} />
                <Text className={styles['image-title']}>방1</Text>
              </div>
              <div className={styles['image-wrapper']}>
                <Image src={MOCK_IMAGE_100} className={styles.image} />
                <Text className={styles['image-title']}>방2</Text>
              </div>
              <div className={styles['image-wrapper']}>
                <Image src={MOCK_IMAGE_100} className={styles.image} />
                <Text className={styles['image-title']}>현관</Text>
              </div>
            </Space>
          </div>
          <div className={styles.sum}>
            <div>
              <Text>소비자 합계</Text>
              <Text>21,446,026원</Text>
            </div>
            <div>
              <Text>전체 합계</Text>
              <Text>20,446,026원</Text>
              <Text>(-1,000,000)</Text>
            </div>
          </div>
        </div>
        <Divider />

        <Estimate data={data}>
          <div className={styles.tableheader}>
            <fieldset>
              <label htmlFor="">자재비율</label>
              <Input id="" />
              <label htmlFor="">노무비율</label>
              <Input id="" />

              <Button type="primary">일괄적용</Button>
            </fieldset>
            <div>
              <Text>합계 21,446,026원</Text>
            </div>
          </div>
        </Estimate>
        {/* form같은 경우 나중에 이 페이지 전체를 감싸서 사용할거기때문에 여기선 안써둠 */}

        <Modal title="견적서 보내기" open={isVisible} onCancel={closeModal} footer={footer}>
          <fieldset className={styles.modal_wrapper}>
            <p>고객에게 보낼 파일 혹은 텍스트를 입력할 수 있습니다.</p>
            {/* <Input id="" type="file" /> */}
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
              <img
                alt="example"
                style={{
                  width: '100%',
                }}
                src={previewImage}
              />
            </Modal>
            <Input.TextArea rows={4} />
          </fieldset>
        </Modal>
      </Card>
    </div>
  );
};

export default EstimateCreate;
