import { Button, Pagination, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ESTIMATE_ETC_DATA } from '../../../../../mocks';
import styles from './style.module.less';

const estimate_etc_columns = [
  {
    title: '번호',
    dataIndex: 'id',
    key: 'id',
    width: 100,
    align: 'center',
    sorter: true,
  },

  {
    title: '제목',
    dataIndex: 'title',
    width: 300,
    key: 'title',
    align: 'center',
    sorter: true,
  },
  {
    title: '작성자',
    dataIndex: 'writer',
    key: 'writer',
    width: 300,
    align: 'center',
    sorter: true,
  },
  {
    title: '업로드일시',
    dataIndex: 'upload_date',
    key: 'final_date',
    width: 300,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },

  {
    title: '고객조회일시',
    dataIndex: 'view_day',
    key: 'view_day',
    width: 300,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },
  {
    dataIndex: 'delete',
    key: 'delete',
    width: 300,
    align: 'center',
    render: () => <Button danger>삭제</Button>,
  },
];

export default function Etc() {
  const navigate = useNavigate();
  const { Paragraph, Text } = Typography;
  const [isOpen, setIsOpen] = React.useState(false);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/estimate/etc/${record.id}`);
      },
    };
  };
  return (
    <div>
      <Table columns={estimate_etc_columns} dataSource={ESTIMATE_ETC_DATA} pagination={false} onRow={onRow} />
      <Pagination defaultCurrent={1} total={50} className={styles.page} />
      <div className={styles.endBtn}>
        <Button type="primary">내용작성</Button>
      </div>

      {/* 상담종료 버튼 클릭 시 모달 */}
    </div>
  );
}
