import React from 'react';
import styles from './AttachedForm.module.less';
import { Button, Input, PageHeader } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import SingleUploader from '../uploader/SingleUploader';
import produce from 'immer';
import { PlusOutlined } from '@ant-design/icons';

const buttonStyle = {
  backgroundColor: '#fff',
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const FORM_LABELS = {
  portfolio: { title: '포트폴리오', name1: '제목', name2: '설명' },
  proposal: { title: '심층제안', name1: '제목', name2: '설명' },
  floor: { title: '첨부도면', name1: '도면명', name2: '도면설명' },
};

const ADD_FORMS = {
  portfolio: { title: '', image: null, description: '' },
  proposal: { title: '', image: null, description: '' },
  floor: { title: '', image: null, description: '' },
};

const AttachedForm = ({ type, requestBody, setRequestBody }) => {
  // const handleUpload = (fileList) => {
  //   if (!fileList.length) return;
  //   const reader = new FileReader();
  //   reader.readAsDataURL(fileList[0]);
  //   reader.onload = () => {
  //     setImgFile(reader.result);
  //   };

  //   const formData = new FormData();
  //   formData.append('filedata', fileList[0]);
  //   restApi.put('/files', formData).then(({ data: responseData }) => console.log(responseData.id));
  // };
  console.log({ requestBody });

  return (
    <div className={styles.container}>
      {requestBody?.form?.map((item, index) => {
        return (
          <div key={`Attached_Form_${index}`} className={styles.attachedForm}>
            <PageHeader
              title={`${FORM_LABELS[type]?.title} ${index + 1}`}
              extra={[
                <Button
                  style={{ height: 'fit-content', border: 'none', marginRight: -20 }}
                  children={
                    <img
                      src={require('../../assets/icon/icon_template_add_sample.png')}
                      alt="템플릿 추가 버튼"
                      style={{ width: 30, height: 30 }}
                    />
                  }
                  onClick={() => {
                    setRequestBody(
                      produce((draft) => {
                        draft.form.splice(index + 1, 0, ADD_FORMS[type]);
                      }),
                    );
                  }}
                />,
                <Button
                  disabled={requestBody?.form?.length === 1}
                  style={{ height: 'fit-content', border: 'none' }}
                  children={
                    <img
                      src={require('../../assets/icon/icon_template_close_sample.png')}
                      alt="템플릿 제거 버튼"
                      style={{ width: 30, height: 30 }}
                    />
                  }
                  onClick={() => {
                    setRequestBody(
                      produce((draft) => {
                        draft.form.splice(index, 1);
                      }),
                    );
                  }}
                />,
              ]}
            />
            <span style={{ fontWeight: '700', marginBottom: 5 }}>{FORM_LABELS[type].name1}</span>
            <Input
              value={requestBody?.form?.[index]?.title}
              style={{ marginBottom: 10 }}
              placeholder="제목 입력"
              onChange={(e) => {
                setRequestBody(
                  produce((draft) => {
                    draft.form[index].title = e.target.value;
                  }),
                );
              }}
            />
            <label className={styles.label}>
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={(event) => {
                  const file = event.target.files[0];
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    setRequestBody(
                      produce((draft) => {
                        draft.form[index].image = reader.result;
                      }),
                    );
                  };
                }}
              />
              {!item.image ? (
                <div style={buttonStyle}>
                  <PlusOutlined style={{ fontSize: 100 }} />
                </div>
              ) : (
                <img className={styles.img} src={item.image} alt="도면3" />
              )}
            </label>

            <span style={{ fontWeight: '700', marginBottom: 5, marginTop: 10 }}>{FORM_LABELS[type].name2}</span>
            <TextArea
              value={requestBody?.form?.[index]?.description}
              style={{ height: 200, resize: 'none' }}
              onChange={(e) => {
                setRequestBody(
                  produce((draft) => {
                    draft.form[index].description = e.target.value;
                  }),
                );
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default AttachedForm;
