import React from 'react';
import styles from './GuideModal.module.less';
import { Col, Input, Modal, Radio, Row, Select, Upload } from 'antd';
import classNames from 'classnames';
import SingleUploader from '../uploader/SingleUploader';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';

const StyledUploader = styled(Upload)`
  height: 230px;
  .ant-upload-picture-card-wrapper {
    background-color: #eee;
    width: 100%;
  }
`;
const buttonStyle = {
  backgroundColor: '#fff',
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};
const uploadButton = () => {
  return (
    <div style={buttonStyle}>
      <span>참고 이미지 파일을 추가해 주세요.</span>
    </div>
  );
};

const GuideModal = ({ isModalOpen, setIsModalOpen, tplCategories }) => {
  const [type, setType] = React.useState('철거');
  const [value, setValue] = React.useState(null);

  const worktypeOptions = React.useMemo(() => {
    return tplCategories?.map((tpl) => ({
      key: tpl?.id,
      label: tpl?.name,
      value: tpl?.name,
    }));
  }, [tplCategories]);

  const radioOptions = React.useMemo(() => {
    return tplCategories?.filter((v) => v?.name === type)?.[0]?.children;
  }, [type]);

  console.log({ radioOptions, value });

  return (
    <Modal
      open={isModalOpen?.guide}
      footer={null}
      width={'60%'}
      centered
      onCancel={() =>
        setIsModalOpen((prev) => ({
          ...prev,
          guide: false,
        }))
      }
      bodyStyle={{ backgroundColor: '#eee' }}
    >
      <div className={styles.modalView}>
        <div className={styles.header}>
          <span>공사 가이드 선택</span>
        </div>

        <Row gutter={24} style={{ height: '100%' }}>
          <Col span={12}>
            <div className={styles.section}>
              <span className={styles.sectionTitle}>공사 가이드 선택</span>
              <div className={classNames([styles.form, styles.select])}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 16 }}>
                  <span style={{ marginRight: 20 }}>공종구분</span>
                  <Select style={{ flex: 1 }} value={type} options={worktypeOptions} onChange={(e) => setType(e)} />
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <span className={styles.sectionTitle}>공종별 공사가이드 리스트</span>
              <div className={classNames([styles.form, styles.radio])} style={{ minHeight: 500 }}>
                <Radio.Group
                  onChange={(e) => {
                    setValue(e?.target?.value);
                  }}
                >
                  {radioOptions?.map((option, index) => {
                    return (
                      <div key={`WORKTYPE_DETAIL_${index}`} style={{ marginBottom: 10 }}>
                        <Radio value={option}>{option?.name}</Radio>
                      </div>
                    );
                  })}
                  <div style={{ marginBottom: 10 }}>
                    <Radio value={'직접입력'}>직접입력</Radio>
                  </div>
                </Radio.Group>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.section}>
              <span className={styles.sectionTitle}>공사 가이드 세부내용</span>
              <div className={styles.guideDetail}>
                {value === '직접입력' ? (
                  <div
                    style={{
                      backgroundColor: '#ccc',
                      width: '100%',
                      height: 230,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ color: '#fff' }}>참고 이미지 파일을 추가해 주세요.</span>
                  </div>
                ) : (
                  <img
                    src={require('../../assets/icon/icon_construct_guide_sample.png')}
                    alt="공사 가이드 세부 이미지"
                    style={{ width: '100%', height: 230 }}
                  />
                )}
                {!!value ? (
                  <div className={styles.guideDescription}>
                    <div>
                      <div style={{ marginBottom: 20 }}>
                        {value === '직접입력' ? (
                          <>
                            <span className={styles.name}>제목</span>
                            <Input />
                          </>
                        ) : (
                          <span className={styles.name}>{value?.name}</span>
                        )}
                      </div>
                      <div>
                        {value === '직접입력' ? (
                          <>
                            <span className={styles.name}>내용</span>
                            <TextArea style={{ resize: 'none', height: 160 }} />
                          </>
                        ) : (
                          <span className={styles.description}>{value?.description}</span>
                        )}
                      </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <button>가이드 적용</button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default GuideModal;
