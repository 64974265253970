import React from 'react';
import { Button, Input, List, Segmented, Table, Typography } from 'antd';
import styles from './style.module.less';
// Utils
import { comma } from '../../common/utils';
import { ESTIMATE_AREA_DATA, ESTIMATE_AREA_DETAIL_DATA, ESTIMATE_TYPE_DATA } from '../../mocks';

const type_columns = [
  {
    title: '구분',
    width: 100,
    dataIndex: 'type',
    key: 'type',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '명칭',
    width: 150,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '자재명',
    width: 250,
    dataIndex: 'material_name',
    key: 'material_name',
    fixed: 'left',
    align: 'center',
    render: () => {
      return (
        <>
          <span>무궁화 페인트</span>
          <Button type="primary" style={{ marginLeft: 80 }}>
            변경
          </Button>
        </>
      );
    },
  },
  {
    title: '단위',
    width: 100,
    dataIndex: 'unit',
    key: 'unit',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '수량',
    width: 100,
    dataIndex: 'quantity',
    key: 'quantity',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '표준견적서(소비자)',
    children: [
      {
        title: '단가',
        width: 150,
        dataIndex: 'price',
        key: 'price',
        align: 'center',
        render: (value) => comma(value),
      },
      {
        title: '금액',
        width: 150,
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
        render: (value) => comma(value),
      },
    ],
  },
  {
    title: '실 금액',
    children: [
      {
        title: '단가',
        width: 150,
        dataIndex: 'sale_price',
        key: 'sale_price',
        align: 'center',
        render: (value) => comma(value),
      },
      {
        title: '금액',
        width: 150,
        dataIndex: 'sale_amount',
        key: 'sale_amount',
        align: 'center',
        render: (value) => comma(value),
      },
    ],
  },
  {
    title: '차액',
    width: 150,
    dataIndex: 'diff',
    key: 'diff',
    align: 'center',
    render: (value) => comma(value),
  },
  {
    title: '소비자 제안 금액',
    children: [
      {
        title: '금액',
        width: 150,
        dataIndex: 'deal_amount',
        key: 'deal_amount',
        align: 'center',
        render: () => <Input />,
      },
    ],
  },
  {
    title: '차액',
    width: 150,
    dataIndex: 'deal_diff',
    key: 'deal_diff',
    align: 'center',
    render: (value) => comma(value),
  },
];

const area_columns = [
  {
    title: '구분',
    width: 100,
    dataIndex: 'type',
    key: 'type',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '명칭',
    width: 150,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '자재명',
    width: 250,
    dataIndex: 'material_name',
    key: 'material_name',
    fixed: 'left',
    align: 'center',
    render: () => {
      return (
        <>
          <span>무궁화 페인트</span>
          <Button style={{ marginLeft: 80 }} type="primary">
            발주
          </Button>
          {/*  발주 또는 발주완료 출력 */}
        </>
      );
    },
  },
  {
    title: '단위',
    width: 100,
    dataIndex: 'unit',
    key: 'unit',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '계약견적가',
    children: [
      {
        title: '단가',
        width: 150,
        dataIndex: 'price',
        key: 'price',
        align: 'center',
        render: (value) => comma(value),
      },
      {
        title: '금액',
        width: 150,
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
        render: (value) => comma(value),
      },
    ],
  },
  {
    title: '실행금액',
    children: [
      {
        title: '자재',
        width: 150,
        dataIndex: 'sale_price',
        key: 'sale_price',
        align: 'center',
        render: () => {
          return <Input />;
        },
      },
      {
        title: '노무',
        width: 150,
        dataIndex: 'sale_amount',
        key: 'sale_amount',
        align: 'center',
        render: () => {
          return <Input />;
        },
      },
    ],
  },
  {
    title: '집행금액',
    children: [
      {
        title: '자재',
        width: 150,
        dataIndex: 'deal_amount',
        key: 'deal_amount',
        align: 'center',
        render: () => {
          return <Input />;
        },
      },
    ],
  },
];

const Estimate = ({ children }) => {
  const [tab, setTab] = React.useState('type');
  const [selected, setSelected] = React.useState();

  return (
    <div className={styles.ConstructionJSX}>
      <div className={styles.left}>
        <Segmented
          options={[
            { label: '공종별 보기', value: 'type' },
            { label: '공간별 보기', value: 'area' },
          ]}
          onChange={setTab}
        />
        <List
          className={styles.list}
          dataSource={tab === 'type' ? ESTIMATE_TYPE_DATA : ESTIMATE_AREA_DATA}
          renderItem={(item) => {
            return (
              <List.Item
                onClick={() => setSelected(item)}
                style={selected === item ? { backgroundColor: '#C8C8C8' } : {}}
              >
                <div className={styles['left-list-item']}>
                  <div className={styles.top}>
                    <Typography.Text>{item?.title}</Typography.Text>
                    {tab === 'area' && (
                      <>
                        <Typography.Text>{item?.size ? `${item?.size} ㎡` : '-'}</Typography.Text>
                      </>
                    )}
                  </div>
                  <Typography.Text strong={selected === item} className={styles.price}>
                    {item?.price ? `${comma(item.price)} 원` : '-'}
                  </Typography.Text>
                </div>
              </List.Item>
            );
          }}
        />
      </div>
      <div className={styles.table}>
        {children}
        {tab === 'type' ? (
          <Table columns={area_columns} dataSource={ESTIMATE_AREA_DETAIL_DATA} pagination={false} />
        ) : (
          <Table columns={area_columns} dataSource={ESTIMATE_AREA_DETAIL_DATA} pagination={false} />
        )}
      </div>
    </div>
  );
};

export default Estimate;
