import { Tabs } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

export default function EstimateTabs({ mode }) {
  const { tplCategories } = useSelector((s) => s.common, shallowEqual);
  const tabs = useMemo(() => {
    if (mode === 'tpl') {
      return tplCategories?.map((v) => ({ id: v.id, name: v.name }));
    }
  }, [mode, tplCategories]);
  return (
    <div>
      <Tabs>
        <Tabs.TabPane tab="철거공사" key={'demolitionWork'}>
          철거공사
        </Tabs.TabPane>
        <Tabs.TabPane tab="확장" key={'expansion'}>
          확장
        </Tabs.TabPane>
        <Tabs.TabPane tab="바닥공사" key={'floorConstruction'}>
          바닥공사
          {/* <FloorConstruction /> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="도배/페인트" key={'paint'}>
          도배/페인트
        </Tabs.TabPane>
        <Tabs.TabPane tab="전기/조명" key={'calculate'}>
          전기/조명
        </Tabs.TabPane>
        <Tabs.TabPane tab="문,터닝도어" key={'light'}>
          문,터닝도어
        </Tabs.TabPane>
        <Tabs.TabPane tab="화장실공사" key={'toiletConstruction'}>
          화장실공사
        </Tabs.TabPane>
        <Tabs.TabPane tab="주방/싱크" key={'kitchen'}>
          주방/싱크
        </Tabs.TabPane>
        <Tabs.TabPane tab="현관공사" key={'entranceWork'}>
          현관공사
        </Tabs.TabPane>
        <Tabs.TabPane tab="샤시/창호" key={'window'}>
          샤시/창호
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
