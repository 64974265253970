import React from 'react';
import { Breadcrumb, Button, Card, Input, Select, Space, Typography, Upload } from 'antd';

import styles from './style.module.less';
import useReference from './useReference';
import { FileTextOutlined, FolderOutlined } from '@ant-design/icons';

const Reference = () => {
  const { data } = useReference();
  return (
    <Card className={styles.reference}>
      <div className={styles.top}>
        <Space>
          <Button size="large">+ 새폴더</Button>
          <Upload>
            <Button size="large">업로드</Button>
          </Upload>
          <Button size="large">다운로드</Button>
        </Space>
        <Space>
          <Select defaultValue={'title'} className={styles.keywordType}>
            <Select.Option value="title">제목</Select.Option>
          </Select>
          <Input placeholder="키워드를 입력하세요." className={styles.input} />
          <Button size="large" type="primary">
            검색
          </Button>
          <Button size="large">초기화</Button>
        </Space>
      </div>

      <div className={styles['finder-wrapper']}>
        <div className={styles['finder-top']}>
          <Breadcrumb separator=">" className={styles.breadcrumb}>
            <Breadcrumb.Item>폴더 1</Breadcrumb.Item>
            <Breadcrumb.Item href="">폴더 2</Breadcrumb.Item>
            <Breadcrumb.Item href="">폴더 3</Breadcrumb.Item>
          </Breadcrumb>
          <Space>
            <Select defaultValue={'type'}>
              <Select.Option value={'type'}>확장자</Select.Option>
            </Select>
            <Select defaultValue={'recent'}>
              <Select.Option value={'recent'}>최신순</Select.Option>
            </Select>
          </Space>
        </div>
        <div className={styles.finder}>
          <div className={styles.item}>
            <FolderOutlined className={styles.icon} />
            <Typography.Text className={styles.name}>도면</Typography.Text>
          </div>
          <div className={styles.item}>
            <FolderOutlined className={styles.icon} />
            <Typography.Text className={styles.name}>시공매뉴얼</Typography.Text>
          </div>
          <div className={styles.item}>
            <FileTextOutlined className={styles.icon} />
            <Typography.Text className={styles.name}>OOOOOOO.pptx</Typography.Text>
          </div>
          <div className={styles.item}>
            <FileTextOutlined className={styles.icon} />
            <Typography.Text className={styles.name}>OOsdssdssdsOOOOO.pptx</Typography.Text>
          </div>
          <div className={styles.item}>
            <FileTextOutlined className={styles.icon} />
            <Typography.Text className={styles.name}>OOOOOOO.pptx</Typography.Text>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Reference;
