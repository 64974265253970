import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Divider, Form, Input, Modal, Pagination, Row } from 'antd';
import produce from 'immer';
import { parseCategory, parseImageUri } from '../../common/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { restApi } from '../../apis';
import ProductListItem from './ProductListItem';
import styles from './styles.module.less';
import { uniq } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import ProductItem from './ProductItem';

const ProductFinderContext = React.createContext({});

export const useProductFinder = () => {
  const context = useContext(ProductFinderContext);
  return context;
};

const ProductFinder = ({ children }) => {
  const productCategories = useSelector((s) => s.common?.productCategories, shallowEqual);

  const [values, setValues] = useState([]);

  const [showModal, setShowModal] = useState();
  const [filters, setFilters] = useState([]);
  const [params, setParams] = useState({});
  const showProduct = useCallback((tpl, values, listener) => {
    const codes = tpl.use_code;
    const limit_size = !tpl.use_product_multi ? tpl.use_product_size : 9000;
    setValues(values ?? []);

    setShowModal({
      title: tpl.name,
      codes,
      limit_size,
      listener,
      show: true,
    });
    setFilters([]);
    setParams({});
  }, []);

  const single = showModal?.limit_size === 1;

  const [items, setItems] = useState([]);
  const loadData = useCallback(
    async (page, pageSize) => {
      const { data } = await restApi.get(`/products`, {
        params: {
          page,
          limit: 20,
          categories: ['none', ...showModal?.codes?.filter((v) => filters?.includes(v))],
          ...params,
        },
      });
      setItems(data);
    },
    [showModal?.codes, filters, params],
  );

  useEffect(() => {
    if (showModal?.show) {
      loadData(0, undefined).catch(console.warn);
    }
  }, [loadData, showModal?.show]);

  const [zoomPImg, setZoomPImg] = useState({
    image: 0,
    visible: false,
  });

  const [pids, setPids] = useState([]);
  const [products, setProducts] = useState({});

  useEffect(() => {
    const alreadies = Object.keys(products).map((x) => parseInt(x, 10));
    const targets = pids.filter((x) => !alreadies.includes(x));
    console.log({ pids, targets });
    if (targets.length > 0) {
      const loadData = async () => {
        const { data } = await restApi.get(`/products`, { params: { ids: targets, limit: targets.length } });
        const newProducts = data?.items?.reduce((a, b) => {
          a[b.id] = b;
          return a;
        }, {});
        setProducts((x) => ({ ...x, ...newProducts }));
      };
      loadData().catch(console.warn);
    }
  }, [pids]);

  const addPids = (arr) => {
    setPids((draft) => uniq(draft.concat(arr)));
  };

  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      await showModal?.listener?.(values);
      setShowModal((x) => ({ ...x, show: false }));
    } catch (e) {}
    setLoading(false);
  };

  return (
    <ProductFinderContext.Provider value={{ showProduct, products, addPids }}>
      {children}
      <Modal
        confirmLoading={loading}
        open={!!showModal?.show}
        title={showModal?.title}
        width={1500}
        onCancel={() => setShowModal((x) => ({ ...x, show: false }))}
        onOk={handleSubmit}
        centered
        style={{ height: '90vh' }}
        bodyStyle={{ flex: 1 }}
        className={styles.productModal}
      >
        <div className={styles.scrollBody}>
          {!!zoomPImg?.visible && (
            <div className={styles.zoom_image} onClick={() => setZoomPImg((x) => ({ ...x, visible: false }))}>
              <img src={parseImageUri(zoomPImg.image)} />
            </div>
          )}
          <div className={styles.scroll}>
            <header>
              <Card>
                <Form layout={'horizontal'}>
                  <Row gutter={[20, 20]}>
                    <Col span={24}>
                      <Form.Item label={'카테고리'}>
                        {showModal?.codes?.map((code) => {
                          const checked = filters?.includes(code);
                          return (
                            <Checkbox
                              key={`${code}`}
                              checked={checked}
                              onClick={() => {
                                setFilters(
                                  produce((draft) => {
                                    const ix = draft?.indexOf(code);
                                    if (ix >= 0) {
                                      draft.splice(ix, 1);
                                    } else {
                                      draft.push(code);
                                    }
                                  }),
                                );
                              }}
                            >
                              {parseCategory(productCategories, code, ' > ')}
                            </Checkbox>
                          );
                        })}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label={'검색어'}>
                        <Input
                          value={params.keyword}
                          onChange={(e) => {
                            const value = e.target.value;
                            setParams((x) => ({ ...x, keyword: value }));
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </header>

            <div style={{ flexDirection: 'row', flexWrap: 'wrap', display: 'flex', margin: '30px -10px' }}>
              {items?.items?.map((item) => (
                <div key={`${item?.id}`} style={{ width: '25%', overflow: 'hidden', padding: 10 }}>
                  <ProductListItem
                    item={item}
                    imageFocus={zoomPImg.image === item.image}
                    onImageClick={() => {
                      setZoomPImg({ visible: true, image: item.image });
                    }}
                    textFocus={!!values?.find((v) => v.product_id === item.id)}
                    onTextClick={() => {
                      const checked = !!values?.find((v) => v.product_id === item.id);
                      if (checked) {
                        setValues((y) => y?.filter((x) => x.product_id !== item.id));
                      } else if (!single) {
                        setValues((x) => [...x, { product_id: item.id, count: 1 }]);
                      } else {
                        // 처리
                        setValues((x) => [{ product_id: item.id, count: 1 }]);
                      }

                      const results = single ? [item.id] : [...(values?.map((v) => v.product_id) || []), item.id];
                      addPids(results);
                    }}
                  />
                </div>
              ))}
            </div>

            <div style={{ textAlign: 'center' }}>
              <Pagination
                pageSize={20}
                current={items?.page + 1}
                total={items?.total_elements ?? 0}
                showSizeChanger={false}
                onChange={(p, pageSize) => {
                  console.log(p);
                  loadData(p - 1, pageSize).catch(console.warn);
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.asideItem}>
          <Divider style={{ fontSize: 12 }}>선택된 상품</Divider>
          <div>
            {!!values?.length &&
              values?.map(({ product_id, count }, ix) => {
                const product = products[product_id];
                return (
                  <div
                    key={`${product_id}`}
                    style={{
                      width: '100%',
                      margin: '5px 0px',
                      border: '1px solid #ddd',
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <ProductItem
                        zoomEnabled
                        item={product}
                        count={count}
                        setCount={(value) => {
                          setValues(
                            produce((draft) => {
                              draft[ix].count = value;
                            }),
                          );
                        }}
                      />
                    </div>
                    <Button
                      size={'small'}
                      icon={<CloseOutlined />}
                      onClick={() => {
                        // console.log(data);
                        // setData(
                        //   produce(data, (draft) => {
                        //     draft.products = draft.products?.filter((v) => product_id !== v.product_id);
                        //   }),
                        // );
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    </ProductFinderContext.Provider>
  );
};

export const withProductFinder = (Component) => () => {
  return (
    <ProductFinder>
      <Component />
    </ProductFinder>
  );
};

export default ProductFinder;
