import React from 'react';
import styles from './TimeTable.module.less';
import { Button, Col, Input, Modal, Row, Select } from 'antd';
// import dayjs from 'dayjs';
import produce from 'immer';
import { WORKTYPE_LABELS, WORKTYPE_THEME } from '../../common/define';
import classNames from 'classnames';
import SquadMatchModal from '../modal/SquadMatchModal';
import GuideModal from '../modal/GuideModal';
import TodoModal from '../modal/TodoModal';

const INIT_STATE = {
  name: '',
  team: false,
  guide: true,
  start_at: null,
  close_at: null,
};

const TimeTable = ({ type, dataSource, tplCategories, requestBody, setRequestBody }) => {
  const dayjs = require('dayjs');
  require('dayjs/locale/ko');
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(isBetween);

  const { constructs } = dataSource;
  const [todoIndex, setTodoIndex] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState({
    add: false,
    delete: false,
    squad: false,
    guide: false,
    save: false,
    todo: false,
  });

  // const [requestBody, setRequestBody] = React.useState(INIT_STATE);

  const changedDataSource = React.useMemo(() => {
    const array =
      type === 'all' || type === 'todo' ? Object.keys(constructs) : Object.keys(constructs)?.filter((v) => v === type);

    return [...array];
  }, [type, constructs]);

  console.log({ changedDataSource });
  const date = React.useMemo(() => {
    const start_date = dayjs(dataSource?.start_at);
    const close_date = dayjs(dataSource?.close_at);

    const months = [];

    let currentDate = start_date;
    while (currentDate.isBefore(close_date) || currentDate.isSame(close_date, 'month')) {
      months.push(currentDate.format('YYYY-MM'));
      currentDate = currentDate.add(1, 'month');
    }

    const dates = [];

    for (let i = 0; i <= months?.length - 1; i++) {
      dates[i] = [];
    }

    let currentDate2 = start_date;

    while (currentDate2.isBefore(close_date) || currentDate2.isSame(close_date, 'day')) {
      const month_index = currentDate2.month() + 1 - (start_date.month() + 1);
      console.log({ month_index });

      dates[month_index].push({
        date: currentDate2,
        day: currentDate2.date(),
        week: currentDate2.locale('ko').format('dddd').substr(0, 1),
      });
      currentDate2 = currentDate2.add(1, 'day');
    }

    return { months, dates };
  }, [dataSource]);

  const workTypeOpt = React.useMemo(() => {
    return tplCategories?.filter((v) => v?.name === WORKTYPE_LABELS[type]);
  }, [type, tplCategories]);

  console.log({ dataSource, constructs, type, changedDataSource, tplCategories, workTypeOpt, requestBody });
  return (
    <div>
      <Row>
        <Col span={24} style={{ position: 'relative' }}>
          <div className={styles.container}>
            <div className={styles.header}>
              <span>{`공사일정표(${WORKTYPE_LABELS[type]})`}</span>
              <div className={styles.btnContainer}>
                <button
                  onClick={() =>
                    setIsModalOpen((prev) => ({
                      ...prev,
                      save: true,
                    }))
                  }
                >
                  저장
                </button>
                <button>초기화</button>
                <button>자동완성</button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className={styles.content}>
          <table className={styles.schedule}>
            <colgroup>
              <col />
              {date?.months?.map((m, mIndex) => {
                return <col key={`MONTH_COL_${mIndex}`} />;
              })}
            </colgroup>

            <tr className={styles.monthRow}>
              <th
                style={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor: '#fff',
                  zIndex: 3,
                }}
              >
                <span>&nbsp;</span>
              </th>
              {date?.months?.map((m, mIndex) => {
                return (
                  <th key={`MONTH_TH_${mIndex}`}>
                    <span>{`${m.split('-')?.[0]}년 ${m.split('-')?.[1]}월`}</span>
                  </th>
                );
              })}
            </tr>

            <tr className={styles.dayRow}>
              <th className={styles.alignHorizontal}>
                <td></td>
                <td>작업팀 배치</td>
                <td>공사가이드</td>
                <td style={{ width: 26 }}>행</td>
              </th>

              {date?.months?.map((m, mIndex) => {
                return (
                  <th key={`DAY_TH_${mIndex}`}>
                    {date?.dates?.[mIndex]?.map((d, dIndex) => {
                      return (
                        <td className={styles.dayCell}>
                          <div key={`DAY_TD_CELL`} className={classNames([styles.alignVertical, styles.dayTdCell])}>
                            <span>{d?.day}</span>
                            <span>{d?.week}</span>
                          </div>
                        </td>
                      );
                    })}
                  </th>
                );
              })}
            </tr>
            {changedDataSource?.map((row, index) => {
              console.log({ row });
              return (
                <>
                  <tr className={styles.contentRow}>
                    <th>
                      <td style={{ textAlign: 'left' }}>&nbsp;&nbsp;{WORKTYPE_LABELS[row]}</td>
                      <td></td>
                      <td></td>
                      <td style={{ width: 26 }}>
                        <button
                          name="add"
                          className={styles.addRowBtn}
                          onClick={(e) => {
                            if (row === 'todo') {
                              setRequestBody(
                                produce((draft) => {
                                  draft.constructs[row].push({
                                    time: '',
                                    title: '',
                                    content: '',
                                    pus_note: false,
                                    pre_note: false,
                                    note_date: null,
                                    note_to: null,
                                  });
                                }),
                              );
                              return;
                            }
                            setIsModalOpen((prev) => ({
                              ...prev,
                              add: true,
                            }));
                          }}
                        >
                          +
                        </button>
                      </td>
                    </th>
                    {date?.months?.map((m, mIndex) => {
                      return (
                        <th>
                          {date?.dates?.[mIndex]?.map((d, dIndex) => {
                            // console.log(dataSource?.constructs?.[row], '확인');
                            // console.log({ d }, 'dddddd');
                            return (
                              <td className={styles.contentCell}>
                                <div>
                                  <span>&nbsp;</span>
                                </div>
                              </td>
                            );
                          })}
                        </th>
                      );
                    })}
                  </tr>
                  {constructs?.[row]?.map((detail, index) => {
                    console.log({ detail });
                    return (
                      <tr className={styles.detailRow}>
                        <th>
                          {row === 'todo' ? (
                            <td>
                              <button
                                name="squad"
                                className={styles.addTeamBtn}
                                style={{ width: 80, padding: 0 }}
                                onClick={() => {
                                  setTodoIndex(index);
                                  setIsModalOpen((prev) => ({
                                    ...prev,
                                    todo: true,
                                  }));
                                }}
                              >
                                {constructs?.['todo']?.[index]?.title ? (
                                  <span>{constructs?.['todo']?.[index]?.title}</span>
                                ) : (
                                  <>
                                    <img
                                      src={require('../../assets/icon/icon_delete_red.png')}
                                      alt="작업팀 없음 이미지"
                                      style={{ width: 20, height: 20 }}
                                    />

                                    <span>{'비었음'}</span>
                                  </>
                                )}
                              </button>
                            </td>
                          ) : (
                            <td style={{ textAlign: 'left' }}>&nbsp;&nbsp;{detail?.name}</td>
                          )}
                          {row === 'todo' ? (
                            <td style={{ width: '200%' }}>{constructs?.['todo']?.[index]?.content}</td>
                          ) : (
                            <>
                              <td>
                                <button
                                  name="squad"
                                  className={styles.addTeamBtn}
                                  onClick={() =>
                                    setIsModalOpen((prev) => ({
                                      ...prev,
                                      squad: true,
                                    }))
                                  }
                                >
                                  {!!detail?.team ? (
                                    <img
                                      src={require('../../assets/icon/icon_user_default.png')}
                                      alt="프로필"
                                      style={{ width: 20, height: 20 }}
                                    />
                                  ) : (
                                    <img
                                      src={require('../../assets/icon/icon_delete_red.png')}
                                      alt="작업팀 없음 이미지"
                                      style={{ width: 20, height: 20 }}
                                    />
                                  )}
                                  <span>{!!detail?.team ? 'ㅇㅇㅇ' : '비었음'}</span>
                                </button>
                              </td>
                              <td>
                                <button
                                  className={styles.addTeamBtn}
                                  onClick={() => {
                                    setIsModalOpen((prev) => ({
                                      ...prev,
                                      guide: true,
                                    }));
                                  }}
                                >
                                  {!!detail?.guide ? (
                                    <img
                                      src={require('../../assets/icon/icon_user_default.png')}
                                      alt="프로필"
                                      style={{ width: 20, height: 20 }}
                                    />
                                  ) : (
                                    <img
                                      src={require('../../assets/icon/icon_delete_red.png')}
                                      alt="작업팀 없음 이미지"
                                      style={{ width: 20, height: 20 }}
                                    />
                                  )}
                                  <span>{!!detail?.guide ? 'ㅇㅇㅇ' : '비었음'}</span>
                                </button>
                              </td>
                            </>
                          )}
                          <td style={{ width: 26 }}>
                            <button
                              style={{ width: 'fit-content' }}
                              className={styles.addTeamBtn}
                              onClick={() =>
                                setIsModalOpen((prev) => ({
                                  ...prev,
                                  delete: true,
                                }))
                              }
                            >
                              <img
                                src={require('../../assets/icon/icon_trash.png')}
                                alt="작업팀 없음 이미지"
                                style={{ width: 10, height: 20 }}
                              />
                            </button>
                          </td>
                        </th>
                        {date?.months?.map((m, mIndex) => {
                          return (
                            <th key={`DETAIL_TH_${mIndex}`}>
                              {date?.dates?.[mIndex]?.map((d, dIndex) => {
                                const active = dayjs(d?.date)?.isBetween(
                                  dayjs(detail?.start_at),
                                  dayjs(detail?.close_at),
                                  null,
                                  '[]',
                                );
                                console.log(d?.date, '확인');
                                return (
                                  <td key={`DETAIL_TD_${dIndex}`} className={styles.contentCell}>
                                    <div style={{ backgroundColor: active ? WORKTYPE_THEME[row] : '#fff' }}>&nbsp;</div>
                                  </td>
                                );
                              })}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              );
            })}
          </table>
        </div>
      </Row>
      <Modal
        open={isModalOpen?.add}
        centered
        width={400}
        footer={false}
        onCancel={() =>
          setIsModalOpen((prev) => ({
            ...prev,
            add: false,
          }))
        }
      >
        <div className={styles.modalView}>
          <div className={styles.modalHeader}>
            <span>하위공정을 추가합니다.</span>
          </div>
          <div className={styles.modalContent}>
            <span style={{}}>공정명을 기입해 주세요.</span>
            <Select
              value={requestBody?.name}
              style={{ width: '100%' }}
              options={workTypeOpt?.[0]?.children?.map((opt) => {
                return { key: opt?.id, label: opt?.name, value: opt?.name };
              })}
              onChange={(e) => {
                setRequestBody(
                  produce((draft) => {
                    draft.name = e;
                  }),
                );
              }}
            />
            {/* <Input /> */}
          </div>
          <div className={styles.btnWrapper}>
            <Button
              disabled={!requestBody?.name}
              style={{
                width: 130,
                height: 45,
                backgroundColor: !!requestBody?.name ? '#3d5bc1' : '#fff',
                color: !!requestBody?.name ? '#fff' : '#000',
              }}
            >
              확인
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={isModalOpen?.delete}
        footer={null}
        centered
        onCancel={() =>
          setIsModalOpen((prev) => ({
            ...prev,
            delete: false,
          }))
        }
        width={400}
      >
        <div className={styles.modalView}>
          <div className={styles.modalHeader}>
            <span>하위공정을 삭제합니다.</span>
          </div>
          <div className={styles.modalContent}>
            <span>삭제한 내용은 복구가 불가능합니다.</span>
          </div>
          <div className={styles.btnWrapper}>
            <Button
              style={{
                width: 130,
                height: 45,
                backgroundColor: '#3d5bc1',
                color: '#fff',
              }}
            >
              확인
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={isModalOpen?.save}
        footer={null}
        centered
        width={400}
        onCancel={() =>
          setIsModalOpen((prev) => ({
            ...prev,
            save: false,
          }))
        }
      >
        <div className={styles.modalView}>
          <div className={styles.modalHeader}>
            <span>저장</span>
          </div>
          <div className={styles.modalContent}>
            <span>현재까지의 작업상황이 저장되었습니다.</span>
          </div>
          <div className={styles.btnWrapper}>
            <Button
              style={{
                width: 130,
                height: 45,
                backgroundColor: '#3d5bc1',
                color: '#fff',
              }}
            >
              확인
            </Button>
          </div>
        </div>
      </Modal>
      <SquadMatchModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tplCategories={tplCategories} />
      <GuideModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tplCategories={tplCategories} />
      <TodoModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        tplCategories={tplCategories}
        requestBody={requestBody}
        setRequestBody={setRequestBody}
        todoIndex={todoIndex}
      />

      {/* <button className={classNames([styles.slideBtn, styles.leftBtn])}>{`<`}</button>
      <button className={classNames([styles.slideBtn, styles.rightBtn])}>{`>`}</button> */}
    </div>
  );
};

export default TimeTable;
