import React from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Progress, Row, Space } from 'antd';
import styles from './index.module.less';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import classNames from '../../../utils/classnames';
import FeedLayout from '../../../components/template/FeedLayout';

const { Header, Footer, Content } = Layout;
const headerStyle = {
  padding: '0 15px',
  backgroundColor: '#fff',
};
const contentStyle = {
  padding: 15,
  paddingBottom: 24,
  margin: '15px 0',
  backgroundColor: '#fff',
};
const footerStyle = {
  padding: 15,
  backgroundColor: '#fff',
};
const ProgressStyle = styled(Progress)`
  .ant-progress-inner {
    width: 80px !important;
    height: 80px !important;
  }
`;

export default function ManagerStatusIndex() {
  const [constructData, setconstructData] = React.useState('d');
  const [todaysWork, settodaysWork] = React.useState('d');
  const navigate = useNavigate();
  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
      size={[0, 48]}
    >
      <Layout>
        <Layout style={{ marginRight: 15 }}>
          <Header style={headerStyle}>
            <div style={{ display: 'flex' }}>
              <span style={{ fontSize: 24, fontWeight: 600, flex: 1 }}>IM홈</span>
              <span>2023년 01월 23일 수요일 12:00:00</span>
            </div>
          </Header>
          <Content style={contentStyle}>
            <div style={{ display: 'flex', gap: 15 }}>
              <div className={styles.imgBox}>
                <img
                  src={require('../../../assets/icon/icon_building_bg.png')}
                  alt="아파트"
                  style={{ width: '100%', height: 50, objectFit: 'cover', position: 'absolute' }}
                />
                <div style={{ display: 'flex', alignItems: 'center', height: 50, padding: 10 }}>
                  <span style={{ fontSize: 16, fontWeight: 600, flex: 1, color: '#fff', zIndex: 10 }}>
                    샛별마을 동성아파트
                  </span>
                  <div style={{ paddingRight: 10, display: 'flex', alignItems: 'center' }}>
                    <Button type="primary" size="small">
                      맨앞으로
                    </Button>
                    <SettingOutlined
                      className={styles.setting}
                      style={{ zIndex: 10 }}
                      onClick={() => navigate('/im/status/construct')}
                    />
                  </div>
                </div>
                {!constructData ? (
                  <img
                    src={require('../../../assets/icon/icon_im_empty_sample.png')}
                    alt="empty_img"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                ) : (
                  <div style={{ padding: 10 }}>
                    <p style={{ margin: '8px 0', fontWeight: 600 }}>공사진행율</p>
                    <Row style={{ padding: '0 15px', marginBottom: 40 }}>
                      <Col span={16}>
                        <span>공사 45일차 / 60일중</span>
                        <div>
                          <span>공사시작일</span>
                          <span>2023-01-01</span>
                        </div>
                        <div>
                          <span>완공예쩡일</span>
                          <span>2023-01-01</span>
                        </div>
                      </Col>
                      <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                        <ProgressStyle type="circle" strokeColor="#4c72f1" percent={75} />
                      </Col>
                    </Row>

                    <strong>금일작업진행현황</strong>
                    {!todaysWork ? (
                      <img
                        src={require('../../../assets/icon/icon_im_empty_sample2.png')}
                        alt="empty_img"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    ) : (
                      <div className={styles.bottomBox}>
                        <div className={styles.customBox}>
                          <div className={classNames(styles.leftRed, styles.leftBlue)} />
                          <div className={styles.right}>
                            <p style={{ fontWeight: 600, paddingLeft: 5 }}>주방가구 설치</p>
                            <p style={{ textAlign: 'right', fontWeight: 300, fontSize: 12 }}>오후작업보고 마감</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Content>
          <Footer style={footerStyle}>
            <div>
              <span style={{ fontSize: 18 }}>할일</span>
              <div
                style={{
                  width: '100%',
                  height: 30,
                  backgroundColor: '#e6e6e6',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 10,
                }}
              >
                <span>정산</span>
                <span>이매촌 진흥아파트</span>
                <span>중도금2차 입금확인 및 세금계산서 발행</span>
                <span>3/5</span>
              </div>
            </div>
          </Footer>
        </Layout>
        <FeedLayout />
      </Layout>
    </Space>
  );
}
