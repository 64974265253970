import { Button, Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ESTIMATE_FLOOR_PLAN_DATA } from '../../../../../mocks';
import styles from './style.module.less';

const estimate_floorPlan_columns = [
  {
    title: '번호',
    dataIndex: 'id',
    key: 'id',
    width: 100,
    align: 'center',
    sorter: true,
  },

  {
    title: '파일명',
    dataIndex: 'file_name',
    width: 300,
    key: 'file_name',
    align: 'center',
    sorter: true,
  },
  {
    title: '업로드일시',
    dataIndex: 'upload_date',
    key: 'final_date',
    width: 300,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },

  {
    title: '고객조회일시',
    dataIndex: 'view_day',
    key: 'view_day',
    width: 300,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },
  {
    dataIndex: 'delete',
    key: 'delete',
    width: 300,
    align: 'center',
    render: () => <Button danger>삭제</Button>,
  },
];

export default function FloorPlan() {
  const navigate = useNavigate();

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/estimate/floorplan/${record.id}`);
      },
    };
  };
  return (
    <div>
      <Table
        columns={estimate_floorPlan_columns}
        dataSource={ESTIMATE_FLOOR_PLAN_DATA}
        pagination={false}
        onRow={onRow}
      />
      <Pagination defaultCurrent={1} total={50} className={styles.page} />
      <div className={styles.endBtn}>
        <Button type="primary">도면저장</Button>
      </div>
    </div>
  );
}
