import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import styles from './Aside.module.less';
import JoinForm from '../join/JoinForm';

const AuthAside = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        className={styles.joinForm}
        open={!!showModal}
        width={700}
        footer={null}
        maskClosable={false}
        title={'파트너스 신청'}
        onCancel={() => setShowModal(false)}
        destroyOnClose={true}
        keyboard={false}
      >
        <JoinForm onExit={() => setShowModal(false)} />
      </Modal>
      <h2 className={styles.h2}>바바파트너스는?</h2>
      <p className={styles.p}>
        오직 당신을 위한 바바 플랫폼, 그 첫걸음
        <br />
        고객들과의 소통과 견적관리를 도와줍니다.
        <br />
        지금 고객을 위한 새로운 채널, 바바로 시작하세요!
      </p>
      <Button size={'large'} style={{ borderRadius: 3 }} onClick={() => setShowModal(true)}>
        파트너스 가입신청
      </Button>
    </>
  );
};

export default AuthAside;
