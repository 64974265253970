import { Button, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.less';
import { useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import PageTitleWithProposal from '../../../../components/PageTitleWithProposal';
import { restApi } from '../../../../apis';
import { calcPriceWithOption, comma } from '../../../../common/utils';
import { shallowEqual, useSelector } from 'react-redux';

export default function ProposalContractDetails() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [secondaryProposal, setSecondaryProposal] = React.useState(false);

  const { estimateOptions } = useSelector((s) => s.common, shallowEqual);
  const { id } = useParams();

  const [data, setData] = useState();

  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/partners/proposals/${id}`);
    setData(data);
  }, [id]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const handleSubmit = async () => {
    await restApi.put(`/partners/proposals/${id}`, {
      state: 'ACTIVE',
    });
    alert('제안이 발송되었습니다.');
    navigate(`/customers/progress`);
  };

  // TODO : 2차 상담제안 상태관리 변경 필요
  return (
    <div>
      <div style={{ marginBottom: 30, paddingBottom: 100 }}>
        <PageTitleWithProposal title="상담신청 접수현황" construct={data?.construct}>
          {['TEMP', 'APP_ING'].includes(data?.state) && (
            <>
              <Button size="large" style={{ margin: '0 10px', width: 120 }} onClick={handleSubmit} disabled={data?.items?.filter(x => !x.total_price).length > 0}>
                상담제안 발송
              </Button>
              {data?.state === 'APP_ING' && (
                <Button type={'danger'} size="large" style={{ width: 120 }}>
                  상담종료
                </Button>
              )}
            </>
          )}
        </PageTitleWithProposal>
      </div>

      {!!data && (
        <Swiper
          initialSlide={data?.items?.length - 1}
          grabCursor={true}
          centeredSlides
          mousewheel={true}
          modules={[Pagination]}
          pagination={{
            el: '.custom-pagination',
            clickable: true,
          }}
          slidesPerView={'auto'}
        >
          {data?.items?.map((item, ix) => (
            <SwiperSlide style={{ width: 990 }}>
              <div className={styles.container}>
                <div className={styles.imgBox}>
                  {ix === 0 ? (
                    <div className={styles.expertTitle}>
                      <span style={{ fontSize: 28, fontWeight: 600 }}>전문가</span>
                      <span style={{ fontSize: 28, fontWeight: 300 }}>제안서</span>
                    </div>
                  ) : (
                    <div>
                      <span style={{ fontSize: 48, fontWeight: 600 }}>{ix}</span>
                      <span style={{ fontSize: 28, fontWeight: 600 }}>차 </span>
                      <span style={{ fontSize: 28, fontWeight: 300 }}>상담 제안</span>
                    </div>
                  )}

                  <div style={{ display: 'flex', gap: 10 }}>
                    <div style={{ display: 'flex', gap: 10, paddingLeft: 10 }}>
                      <div
                        style={{ position: 'relative', cursor: 'pointer', flex: 1, height: 380 }}
                        onClick={() => navigate(`/customers/details/${id}/estimates/${item.id}`)}
                      >
                        <img
                          alt="견적서"
                          className={styles.img}
                          src={require('../../../../assets/icon/icon_estimate.png')}
                        />
                        <div className={styles.topText}>
                          <span style={{ fontSize: 21, fontWeight: 600 }}>견적서</span>
                          <span>(필수)</span>
                        </div>
                        <span className={styles.bottomText}>
                          {comma(calcPriceWithOption(item.total_price, estimateOptions))}원
                        </span>
                      </div>
                      {ix === 0 && (
                        <div
                          style={{ position: 'relative', cursor: 'pointer', width: 210, height: 380 }}
                          onClick={() => navigate('/customers/contract/5414/introduction')}
                        >
                          <img
                            alt="회사소개서"
                            className={styles.img}
                            src={require('../../../../assets/icon/icon_introduction_bg.png')}
                          />
                          <div className={styles.topText}>
                            <div>
                              <p style={{ fontSize: 21, fontWeight: 600, marginBottom: 0 }}>회사소개서 </p>
                              <span style={{ fontSize: 21, fontWeight: 600 }}>기타자료</span>
                              <span>(선택)</span>
                            </div>
                          </div>
                          <span className={styles.bottomText}>미첨부</span>
                        </div>
                      )}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, paddingRight: 10 }}>
                      <div
                        style={{ position: 'relative', cursor: 'pointer', width: 490, height: 150 }}
                        onClick={() => navigate('/customers/contract/5414/floor')}
                      >
                        <img
                          alt="도면"
                          className={styles.img}
                          src={require('../../../../assets/icon/icon_floorplan.png')}
                        />
                        <div className={styles.topText}>
                          <span style={{ fontSize: 21, fontWeight: 600 }}>도면</span>
                          <span>(선택)</span>
                        </div>
                        <span className={styles.bottomText}>미첨부</span>
                      </div>

                      <div style={{ display: 'flex', gap: 10 }}>
                        <div
                          style={{ position: 'relative', cursor: 'pointer', width: 240, height: 220 }}
                          onClick={() => {
                            navigate('/customers/contract/5414/portfolio');
                          }}
                        >
                          <img
                            alt="3D 포트폴리오"
                            className={styles.img}
                            src={require('../../../../assets/icon/icon_floorplan.png')}
                          />
                          <div className={styles.topText}>
                            <span style={{ fontSize: 21, fontWeight: 600 }}>포트폴리오</span>
                            <span>(선택)</span>
                          </div>
                          <span className={styles.bottomText}>미첨부</span>
                        </div>
                        <div
                          style={{ position: 'relative', cursor: 'pointer', width: 240, height: 220 }}
                          onClick={() => navigate('/customers/contract/5414/depth_proposal')}
                          className={styles.test}
                        >
                          <img
                            alt="심층제안서"
                            className={styles.img}
                            src={require('../../../../assets/icon/icon_partial_proposal.png')}
                          />
                          <div className={styles.topText}>
                            <span style={{ fontSize: 21, fontWeight: 600 }}>심층제안서</span>
                            <span>(선택)</span>
                          </div>
                          <span className={styles.bottomText}>미첨부</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          {/*<SwiperSlide style={{ width: 990 }}>*/}
          {/*  <div>*/}
          {/*    <div className={styles.imgBox}>*/}
          {/*      <div className={styles.firstProposalTitle}>*/}

          {/*        {secondaryProposal && (*/}
          {/*          <div style={{ paddingRight: 10 }}>*/}
          {/*            <div style={{ display: 'flex', justifyContent: 'space-between' }}>*/}
          {/*              <strong>발송일시</strong>*/}
          {/*              <span style={{ marginLeft: 50 }}>2023-01-25 12:00</span>*/}
          {/*            </div>*/}
          {/*            <div style={{ display: 'flex', justifyContent: 'space-between' }}>*/}
          {/*              <strong>고객확인일시</strong>*/}
          {/*              <span style={{ marginLeft: 50 }}>2023-01-25 12:00</span>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        )}*/}
          {/*      </div>*/}

          {/*      <div style={{ display: 'flex', gap: 10 }}>*/}
          {/*        <div style={{ display: 'flex', gap: 10, paddingLeft: 10 }}>*/}
          {/*          <div*/}
          {/*            style={{ position: 'relative', cursor: 'pointer', width: 430, height: 380 }}*/}
          {/*            onClick={() => navigate('/customers/contract/5414/estimate')}*/}
          {/*          >*/}
          {/*            <img*/}
          {/*              src={require('../../../../assets/icon/icon_estimate.png')}*/}
          {/*              alt="견적서"*/}
          {/*              className={styles.img}*/}
          {/*            />*/}
          {/*            <div className={styles.topText}>*/}
          {/*              <span style={{ fontSize: 21, fontWeight: 600 }}>견적서</span>*/}
          {/*              <span>(필수)</span>*/}
          {/*            </div>*/}
          {/*            <span className={styles.bottomText}>0원</span>*/}
          {/*          </div>*/}
          {/*        </div>*/}

          {/*        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, paddingRight: 10 }}>*/}
          {/*          <div*/}
          {/*            style={{ position: 'relative', cursor: 'pointer', width: 490, height: 150 }}*/}
          {/*            onClick={() => navigate('/customers/contract/5414/floor')}*/}
          {/*          >*/}
          {/*            <img*/}
          {/*              src={require('../../../../assets/icon/icon_floorplan.png')}*/}
          {/*              alt="도면"*/}
          {/*              className={styles.img}*/}
          {/*            />*/}
          {/*            <div className={styles.topText}>*/}
          {/*              <span style={{ fontSize: 21, fontWeight: 600 }}>도면</span>*/}
          {/*              <span>(선택)</span>*/}
          {/*            </div>*/}
          {/*            <span className={styles.bottomText}>비었음</span>*/}
          {/*          </div>*/}

          {/*          <div style={{ display: 'flex', gap: 10 }}>*/}
          {/*            <div*/}
          {/*              style={{ position: 'relative', cursor: 'pointer', width: 240, height: 220 }}*/}
          {/*              onClick={() => {*/}
          {/*                navigate('/customers/contract/5414/portfolio');*/}
          {/*              }}*/}
          {/*            >*/}
          {/*              <img*/}
          {/*                src={require('../../../../assets/icon/icon_floorplan.png')}*/}
          {/*                alt="3D 포트폴리오"*/}
          {/*                className={styles.img}*/}
          {/*              />*/}
          {/*              <div className={styles.topText}>*/}
          {/*                <span style={{ fontSize: 21, fontWeight: 600 }}>3D 포트폴리오</span>*/}
          {/*                <span>(선택)</span>*/}
          {/*              </div>*/}
          {/*              <span className={styles.bottomText}>비었음</span>*/}
          {/*            </div>*/}
          {/*            <div*/}
          {/*              style={{ position: 'relative', cursor: 'pointer', width: 240, height: 220 }}*/}
          {/*              className={styles.test}*/}
          {/*            >*/}
          {/*              <img*/}
          {/*                src={require('../../../../assets/icon/icon_partial_proposal.png')}*/}
          {/*                alt="공간별 제안"*/}
          {/*                className={styles.img}*/}
          {/*              />*/}
          {/*              <div className={styles.topText}>*/}
          {/*                <span style={{ fontSize: 21, fontWeight: 600 }}>공간별 제안</span>*/}
          {/*                <span>(선택)</span>*/}
          {/*              </div>*/}
          {/*              <span className={styles.bottomText}>비었음</span>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</SwiperSlide>*/}
        </Swiper>
      )}
      <div className="custom-pagination" style={{ maxWidth: 1200, margin: '0 auto', paddingLeft: 500 }} />

      <Modal
        open={isModalOpen}
        centered
        footer={null}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={350}
        bodyStyle={{ height: 280 }}
        closable={null}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontSize: 24, fontWeight: 600, paddingTop: 20 }}>제안서 발송</span>
          <button className={styles.closeBtn} onClick={() => setIsModalOpen(false)}>
            <span />
            <span />
          </button>
        </div>
        <div style={{ paddingTop: 30, display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontWeight: 300 }}>고객에게 1차 제안서를 발송합니다.</span>
          <span style={{ fontWeight: 300 }}>발송 후에는 수정할 수 없습니다.</span>
          <div style={{ marginTop: 50, display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              type="primary"
              size="large"
              style={{ width: 120 }}
              onClick={() => {
                setIsModalOpen(false);
                setSecondaryProposal(true);
              }}
            >
              확인
            </Button>
            <Button type="danger" size="large" style={{ width: 120 }} onClick={() => setIsModalOpen(false)}>
              취소
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
