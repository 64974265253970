import React, { useState } from 'react';
import { Button, Calendar, Card, Checkbox, Form, Input, List, Modal, Table, Typography } from 'antd';
import moment from 'moment';
import 'moment/locale/ko';
import styles from './style.module.less';
import { SCHEDULE_DATA } from '../../../../../../mocks';

const month_end_date = moment().endOf('month').format('DD');

const columns = [
  {
    title: 'NO',
    key: 'id',
    dataIndex: 'id',
    width: 50,
    align: 'center',
    fixed: 'left',
  },
  {
    title: '공종',
    key: 'type',
    dataIndex: 'type',
    width: 100,
    align: 'center',
    fixed: 'left',
  },
  {
    title: '업체',
    key: 'company',
    dataIndex: 'company',
    width: 100,
    align: 'center',
    fixed: 'left',
  },
  {
    title: '소분류',
    key: 'category',
    dataIndex: 'category',
    width: 100,
    align: 'center',
    fixed: 'left',
  },
  {
    title: '공사내용',
    key: 'construction',
    dataIndex: 'construction',
    align: 'center',
    width: 200,
    fixed: 'left',
  },
  {
    title: moment().format('YY년 MM월'),
    children: [
      ...Array.from({ length: month_end_date }).map((day, index) => ({
        title: index + 1,
        children: [
          {
            title: moment().startOf('month').add(index, 'day').format('dd'),
            dataIndex: moment().startOf('month').add(index, 'day').format('DD'),
            key: moment().startOf('month').add(index, 'day').format('DD'),
            width: 50,
            render: (value, row) => {
              return {
                props: {
                  style: { background: row.schedule[index + 1]?.color },
                },
              };
            },
          },
        ],
      })),
    ],
  },
];
const topDataSource = [
  {
    key: '1',
    name: '홍길동',
    rank: '반장',
    phoneNumber: '',
  },
  {
    key: '2',
    name: '김아무',
    rank: '기사',
    phoneNumber: '',
  },
  {
    key: '3',
    name: '최아무',
    rank: '조공',
    phoneNumber: '',
  },
];

const modalTopColumns = [
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '직급',
    dataIndex: 'rank',
    key: 'rank',
  },
  {
    title: '전화번호',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: '비고',
    dataIndex: 'note',
    key: 'note',
    render: () => {
      return <Checkbox />;
    },
  },
];
const bottomDataSource = [
  {
    key: '1',
    name: '가구철거',
    rank: '기타몰딩 철거',
    phoneNumber: '',
  },
  {
    key: '2',
    name: '가구철거',
    rank: '싱크대 철거',
    phoneNumber: '',
  },
  {
    key: '3',
    name: '가구철거',
    rank: '신발장 철거',
    phoneNumber: '',
  },
];

const modalBottomColumns = [
  {
    title: '소분류',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '업무',
    dataIndex: 'rank',
    key: 'rank',
  },
  {
    title: '',
    dataIndex: '',
    key: '',
  },
];

const Schedule = () => {
  const { Title, Paragraph, Text } = Typography;
  const [selectedDate, setSelectedData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isListPopupOpen, setIsListPopupOpen] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);

  const handleDateSelect = (date) => {
    setSelectedData(date.format('YYYY-MM-DD'));
  };
  const handleOk = () => {
    console.log('OK');
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  const handleListPopupCancel = () => {
    setIsListPopupOpen(false);
  };
  const handleAddCancel = () => {
    setIsAddPopupOpen(false);
  };
  return (
    <Card>
      <div className={styles.calendarContainer}>
        <Button
          type="primary"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          달력으로 보기(팝업)
        </Button>
        <Modal open={isOpen} onOk={handleOk} onCancel={handleCancel} width={1400} footer={null}>
          <div className={styles.flex}>
            <div className={styles[`width-60`]}>
              <Paragraph>공사 일정표</Paragraph>
              <div className={styles.constructionName}>
                <Paragraph>공사명</Paragraph>
                <Title>샛별동아 아파트</Title>
              </div>
              <Paragraph>날짜를 선택하시면 자세한 공사 진행 상황을 확인하실 수 있습니다.</Paragraph>
              <Calendar onSelect={handleDateSelect} value={selectedDate} />
            </div>
            <div className={styles.dateDetailContainer}>
              <div>
                <Title level={3}>
                  <Text>공사명</Text>샛별동아 아파트
                </Title>
                <Paragraph>8월19일 공사 진행 현황</Paragraph>
              </div>

              <div className={styles.btnWrap}>
                <Button>
                  <Title level={4}>철거공사</Title>
                  <Text>작업내용 확인</Text>
                </Button>
                <Button>
                  <Title level={4}>샷시공사</Title>
                  <Text>작업내용 확인</Text>
                </Button>
                <Button>
                  <Title level={4}>확장공사</Title>
                  <Text>작업내용 확인</Text>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <Table
        columns={columns}
        scroll={{ x: 1920 }}
        dataSource={SCHEDULE_DATA}
        pagination={false}
        onRow={() => ({
          onClick: () => {
            setIsListPopupOpen(true);
          },
        })}
      />
      {/* 가운데 클릭 시 나오는 팝업 */}
      <Modal
        open={isListPopupOpen}
        onOk={handleOk}
        onCancel={handleListPopupCancel}
        title="작업예정 하도급 목록"
        footer={[
          <Button key="back">전일 작업자 불러오기</Button>,
          <Button key="submit" type="primary">
            저장
          </Button>,
          <Button
            key="create"
            type="primary"
            onClick={() => {
              setIsAddPopupOpen(true);
            }}
          >
            추가
          </Button>,
        ]}
      >
        <List>
          <List.Item>일자 : 22-05-01</List.Item>
          <List.Item>공사내용 : 걸레받이 철거, 기타몰딩 철거, 문 철거, 문틀</List.Item>
          <List.Item>철거 : 중문 철거</List.Item>
          <List.Item>구분 : 일반철거</List.Item>
        </List>
        <div>
          <Table columns={modalTopColumns} dataSource={topDataSource} pagination={false} />
          <Table
            style={{ marginTop: 10 }}
            columns={modalBottomColumns}
            dataSource={bottomDataSource}
            pagination={false}
          />
        </div>
        <Modal
          open={isAddPopupOpen}
          title="하도급 추가"
          onOk={handleOk}
          onCancel={handleAddCancel}
          style={styles.addModal}
          footer={[
            <Button key="back" onClick={handleAddCancel}>
              취소
            </Button>,
            <Button key="submit" type="primary">
              저장
            </Button>,
          ]}
        >
          <Paragraph>선택하신 공사에 대해 하도급 담당자를 추가합니다.</Paragraph>
          <div className={styles.loadBtnContainer}>
            <Button className={styles.loadStyle}>불러오기</Button>
          </div>

          <Form
            className={styles.itemContainer}
            name="basic"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 12,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <Form.Item
              label="이름"
              name="이름"
              rules={[
                {
                  required: true,
                  message: '이름을 입력해주세요!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="직급"
              name="직급"
              rules={[
                {
                  required: true,
                  message: '직급을 입력해주세요!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="지역"
              name="지역"
              rules={[
                {
                  required: true,
                  message: '지역을 입력해주세요!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="전화번호"
              name="전화번호"
              rules={[
                {
                  required: true,
                  message: '전화번호를 입력해주세요!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="비고" name="비고">
              <Form className={styles.possibleWork}>
                <Checkbox>바닥철거</Checkbox>
                <Checkbox>일반철거</Checkbox>
                <Checkbox>설비</Checkbox>
              </Form>
            </Form.Item>
          </Form>
        </Modal>
      </Modal>

      {/* 추가버튼 눌렀을 때 나오는 팝업 */}
    </Card>
  );
};

export default Schedule;
