import React from 'react';
import styles from './UserStyleTemplate.module.less';
import classNames from '../../utils/classnames';
import MaterialSwiper from '../swiper/MaterialSwiper';
import { comma } from '../../common/utils';
import { Carousel, Image, Modal } from 'antd';
// import Slider from 'react-slick/lib/slider';
import styled from 'styled-components';

const MaterialCarousel = styled(Carousel)`
  .slick-slide > div {
    display: flex;
  }
  .slick-slide > div > button {
    margin-inline: 5px;
  }
`;

const LABELS = {
  living_room: '거실',
  kitchen: '주방',
  bedroom: '안방',
  bedroom1: '침실1',
  bedroom2: '침실2',
  bedroom3: '침실3',
  bedroom4: '침실4',
  bedroom5: '침실5',
  entrance: '현관',
  restroom1: '거실화장실',
  restroom2: '안방화장실',
};

const SUMMARY_BADGES = [
  { label: '공사등록 수', value: 3 },
  { label: '공사기간', value: 'L' },
  { label: '난이도', value: 5 },
  { label: '비용', value: 1 },
];

const WORKTYPE_BUTTONS = [
  { label: '철거', value: 'demolish' },
  { label: '확장', value: 'expansion' },
  { label: '도배/페인트', value: 'paint' },
  { label: '바닥', value: 'floor' },
  { label: '문, 터닝도어', value: 'door' },
  { label: '전기/조명', value: 'electronic' },
  { label: '목공', value: 'wood' },
  { label: '주방/싱크', value: 'kitchen' },
  { label: '화장실', value: 'restroom' },
  { label: '현관', value: 'entrance' },
  { label: '발코니', value: 'valcony' },
  { label: '샤시/창호', value: 'window' },
  { label: '가구', value: 'furniture' },
  { label: '홈 서비스', value: 'service' },
  { label: '홈퍼니싱', value: 'furnish' },
];

const MATERIAL_ITEMS = [
  {
    id: 1,
    type: '도배',
    name: '로하스',
    price: 7500,
    image: 'icon_material_sample1',
  },
  {
    id: 2,
    type: '강마루',
    name: '메이플5911',
    price: 7500,
    image: 'icon_material_sample2',
  },
  {
    id: 3,
    type: '강마루',
    name: 'LED3인치 원등 매입등',
    price: 7500,
    image: 'icon_material_sample3',
  },
  {
    id: 4,
    type: '도배',
    name: '로하스',
    price: 7500,
    image: 'icon_material_sample1',
  },
  {
    id: 5,
    type: '강마루',
    name: '메이플5911',
    price: 7500,
    image: 'icon_material_sample2',
  },
  {
    id: 6,
    type: '강마루',
    name: 'LED3인치 원등 매입등',
    price: 7500,
    image: 'icon_material_sample3',
  },
  {
    id: 7,
    type: '도배',
    name: '로하스',
    price: 7500,
    image: 'icon_material_sample1',
  },
  {
    id: 8,
    type: '강마루',
    name: '메이플5911',
    price: 7500,
    image: 'icon_material_sample2',
  },
  {
    id: 9,
    type: '강마루',
    name: 'LED3인치 원등 매입등',
    price: 7500,
    image: 'icon_material_sample3',
  },
];

const UserStyleTemplate = ({ tab }) => {
  const slideRef = React.useRef();
  const slideWidthRef = React.useRef();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [slideWidth, setSlideWidth] = React.useState(0);

  React.useEffect(() => {
    if (slideWidthRef.current.offsetWidth > 0) {
      setSlideWidth(slideWidthRef.current.offsetWidth * 0.95);
    }
  }, []);

  return (
    <div className={styles.container}>
      <span className={styles.label}>
        {LABELS[tab]}
        <span className={styles.subLabel}>OVERALL</span>
      </span>
      <div className={styles.row} style={{ height: 500 }}>
        <div className={classNames([styles.summary, styles.box])}>
          <img src={require('../../assets/icon/style_bg_sample.png')} alt="스타일 이미지" />
          <div className={styles.summaryContent}>
            <div className={styles.summaryText}>
              <p className={styles.sectionTitle}>
                베이직 스타일
                <br />
                <p style={{ fontSize: 14, fontWeight: '400' }}>바바가 제안하는 베이직 거실 스타일</p>
              </p>
              <p className={styles.date}>2033-01-30</p>
            </div>
            <div className={styles.summaryBadge}>
              {SUMMARY_BADGES.map((badge, index) => {
                return (
                  <div key={`SUMMARY_BADGE_${index}`} className={styles.badge}>
                    <div style={{ width: 20, height: 20 }} />
                    <span className={styles.badgeValue}>{badge?.value}</span>
                    <span className={styles.badgeLabel}>{badge?.label}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classNames([styles.info, styles.box])} ref={slideWidthRef}>
          <p className={styles.sectionTitle}>스타일</p>
          <div className={styles.infoContent}>
            <div className={styles.imageWrapper}>
              <Image
                src={require('../../assets/icon/style_bg2_sample.png')}
                alt="스타일 미리보기"
                style={{ width: '100%', height: 452 }}
              />
            </div>
            <div className={styles.description}>
              <p className={styles.sectionTitle}>커튼앞 스카이라인조명이 더하는 거실</p>
              <span>
                미니멀한 화이트톤 벽체에 쉐브론 패턴의 받가재를 매치시켜 세련되면서도 고급스러운느낌의 거실을
                완성하였습니다. 순수예술 오브제를 더해 마치 갤러리에 온듯한 느낌을 연출했습니다.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.row} style={{ height: 300 }}>
        <div className={classNames([styles.workType, styles.box])}>
          <p className={styles.sectionTitle} style={{ marginLeft: 10 }}>
            적용공종
          </p>
          <div className={styles.workTypeContent}>
            {WORKTYPE_BUTTONS.map((btn, index) => {
              return (
                <button key={`WORKTYPE_BTN_${index}`} className={styles.btn} onClick={() => setIsModalOpen(true)}>
                  <div className={styles.iconWrapper}>
                    <img
                      src={require(`../../assets/tpl_category/icon_tpl_${btn?.value}.png`)}
                      style={{ width: 24, height: 24 }}
                      alt="적용공종 이미지"
                    />
                  </div>
                  <span className={styles.btnText}>{btn?.label}</span>
                </button>
              );
            })}
          </div>
        </div>
        <div className={classNames([styles.material, styles.box])}>
          <p className={styles.sectionTitle} style={{ alignSelf: 'flex-start' }}>
            적용자재
          </p>

          <div className={styles.materialContent} style={{ width: slideWidth }}>
            <MaterialCarousel
              slidesToShow={7}
              slidesToScroll={1}
              ref={slideRef}
              style={{ marginInline: 10, padding: 5 }}
              dots={false}
            >
              {MATERIAL_ITEMS?.map((material, index) => {
                return (
                  <div key={`MATERIAL_ITEM_${index}`} className={styles.materialItem}>
                    <img
                      src={require(`../../assets/icon/${material?.image}.png`)}
                      alt="적용자재 이미지"
                      style={{ width: '100%', height: 120 }}
                    />
                    <div className={styles.materialInfo}>
                      <div>
                        <p className={styles.materialType}>{material?.type}</p>
                        <p className={styles.materialName}>{material?.name}</p>
                      </div>
                      <div style={{ alignSelf: 'flex-end' }}>
                        <span className={styles.materialPrice}>
                          {comma(material?.price)}
                          <span className={styles.materialUnit}> 원/m</span>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </MaterialCarousel>
            {/* <div
              className={styles.slideBtn}
              style={{ left: -28 }}
              onClick={() => {
                slideRef.current.prev();
              }}
            >
              <span>{`<`}</span>
            </div> */}
            <div
              className={styles.slideBtn}
              style={{ right: -15 }}
              onClick={() => {
                slideRef.current.next();
              }}
            >
              <span>{`>`}</span>
            </div>
          </div>
        </div>
      </div>
      <Modal open={isModalOpen} closable={false} footer={null} width={320} bodyStyle={{ height: 300 }} centered>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ fontSize: 24, fontWeight: '700' }}>인력서비스</p>
              <img
                src={require('../../assets/icon/icon_close.png')}
                alt="모달 닫기 버튼"
                style={{ width: 40, height: 40, cursor: 'pointer' }}
                onClick={() => setIsModalOpen(false)}
              />
            </div>
            <span>도움받을 인재가 보여집니다.</span>
          </div>
          <span>준비중 입니다.</span>
        </div>
      </Modal>
    </div>
  );
};

export default UserStyleTemplate;
