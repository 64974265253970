import React from 'react';
import { PageHeader } from 'antd';
import styles from './index.module.less';
import UserStyleTemplate from '../../../../../components/template/UserStyleTemplate';
import AnnexTable from '../../../../../components/table/AnnexTable';

const DUMMY_OPTIONS = [
  { label: '거실', value: 'living_room', image: 'icon_livingroom.png' },
  { label: '주방', value: 'kitchen', image: 'icon_kitchen.png' },
  { label: '안방', value: 'bedroom', image: 'icon_bedroom.png' },
  { label: '침실1', value: 'bedroom1', image: 'icon_bedroom1.png' },
  { label: '침실2', value: 'bedroom2', image: 'icon_bedroom2.png' },
  { label: '침실3', value: 'bedroom3', image: 'icon_bedroom3.png' },
  { label: '침실4', value: 'bedroom4', image: 'icon_bedroom4.png' },
  { label: '침실5', value: 'bedroom5', image: 'icon_bedroom5.png' },
  { label: '현관', value: 'entrance', image: 'icon_entrance.png' },
  { label: '화장실', value: 'restroom1', image: 'icon_restroom1.png' },
  { label: '화장실', value: 'restroom2', image: 'icon_restroom2.png' },
  { label: '거실화장실', value: 'living_restroom', image: 'icon_living_restroom.png' },
  { label: '안방화장실', value: 'bed_restroom', image: 'icon_bed_restroom.png' },
];

export default function ProposalExpertStyleIndex() {
  const [tab, setTab] = React.useState('living_room');

  console.log({ tab });

  return (
    <div>
      <PageHeader
        title="고객 스타일 보기"
        extra={[
          // <table className={styles.headerTable}>
          //   <tr>
          //     <th>주택명</th>
          //     <td>개봉푸르지오</td>
          //     <th>현장주소</th>
          //     <td>서울시 구로구 남부순환로 775</td>
          //     <th>면적</th>
          //     <td>34PY</td>
          //     <th>방/화장실</th>
          //     <td>4/3</td>
          //   </tr>
          //   <tr>
          //     <th>공사시작일</th>
          //     <td>2023-01-25</td>
          //     <th>관리시연락처</th>
          //     <td>02-2354-1235</td>
          //   </tr>
          // </table>,
          <AnnexTable
            column={4}
            items={[
              { label: '주택명', value: '개봉푸르지오' },
              { label: '현장주소', value: '서울시 구로구 남부순환로 775' },
              { label: '면적', value: 'PY' },
              { label: '방/화장실', value: '4/3' },
              { label: '공사시작일', value: '2023-01-25' },
              { label: '관리실연락처', value: '02-2354-1235' },
            ]}
          />,
        ]}
      />
      <div className={styles.contentContainer}>
        <div className={styles.styleNav}>
          {DUMMY_OPTIONS.map((option, index) => {
            const active = option?.value === tab;
            console.log(option.value, 'tab', active);
            return (
              <button
                key={`STYLE_OPTION_${index}`}
                className={styles.styleNavBtn}
                style={{ backgroundColor: active ? '#4c72f1' : '#eee' }}
                onClick={() => setTab(option.value)}
              >
                <img
                  src={require(`../../../../../assets/icon/${option.image}`)}
                  alt="스타일 필터 이미지"
                  style={{ width: 30, height: 30 }}
                />
                <p>{option.label}</p>
              </button>
            );
          })}
        </div>
        <UserStyleTemplate tab={tab} />
      </div>
    </div>
  );
}
