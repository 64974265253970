import React from 'react';
import { Button, DatePicker, Input, Typography } from 'antd';

import styles from './style.module.less';

const Payment = ({ onSubmit }) => {
  return (
    <div className={styles.payment}>
      <div className={styles['form-wrapper']}>
        <div className={styles.item}>
          <Typography.Text className={styles.title}>신청일자</Typography.Text>
          <DatePicker showTime className={styles.input} />
          {/* <TimePicker /> */}
        </div>
        <div className={styles.item}>
          <Typography.Text className={styles.title}>담당자</Typography.Text>
          <Input className={styles.input} />
        </div>
        <div className={styles.item}>
          <Typography.Text className={styles.title}>금액</Typography.Text>
          <Input className={styles.input} />
        </div>
      </div>
      <Button type="primary" onClick={onSubmit} size="large">
        전도금 등록
      </Button>
    </div>
  );
};

export default Payment;
