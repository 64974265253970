import React from 'react';
import { comma } from '../../../../../../common/utils';
import { Checkbox } from 'antd';

export const columns = [
  {
    title: '구분',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 100,
  },
  {
    title: '명칭',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 150,
  },
  {
    title: () => <Checkbox />,
    dataIndex: 'check',
    key: 'check',
    align: 'center',
    width: 50,
  },
  {
    title: '자재명',
    dataIndex: 'product_name',
    key: 'product_name',
    align: 'center',
  },
  {
    title: '유통사',
    dataIndex: 'company_name',
    key: 'company_name',
    align: 'center',
    width: 200,
  },
  {
    title: '단위',
    width: 100,
    dataIndex: 'unit',
    key: 'unit',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '수량',
    width: 100,
    dataIndex: 'count',
    key: 'count',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '실 금액',
    children: [
      {
        title: '단가',
        width: 100,
        dataIndex: 'sale_price',
        key: 'sale_price',
        align: 'center',
        render: (value) => comma(value),
      },
      {
        title: '금액',
        width: 100,
        dataIndex: 'sale_amount',
        key: 'sale_amount',
        align: 'center',
        render: (value) => comma(value),
      },
    ],
  },
  {
    title: '배송예정일자',
    width: 100,
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '주문가능일자',
    width: 100,
    dataIndex: 'order_date',
    key: 'order_date',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '남은일자',
    width: 100,
    dataIndex: 'id',
    key: 'detail',
    align: 'center',
  },
];

const useDailyReport = () => {
  const [data, setData] = React.useState();
  return {};
};

export default useDailyReport;
