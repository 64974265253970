import React, { useState } from 'react';
import { Button, Divider, Modal, PageHeader, Pagination, Table, Typography } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { CONSTRUCTION_SEARCH_FIELDS } from '../../../constants/searchFields';
import useContract, { estimate_application_columns } from './useApplication';
import styles from './style.module.less';
import { comma } from '../../../common/utils';

// import { Pagination } from 'antd';
const ESTIMATEApplication = () => {
  const { data } = useContract();
  const { Paragraph, Text } = Typography;
  const [isOpen, setIsOpen] = useState(false);
  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <PageHeader title="상담 신청" />
      <SearchForm fields={CONSTRUCTION_SEARCH_FIELDS} />
      <Divider />

      <Table columns={estimate_application_columns} dataSource={data} pagination={false} />
      {/* 상담신청 견적 - 상태 / 요청접수 시 모달 */}

      <Pagination defaultCurrent={1} total={50} className={styles.page} />

      {/* t 신청 접수 시 모달 */}
      <Modal
        open={isOpen}
        width={400}
        onCancel={handleCancel}
        title="IM 서비스"
        footer={[
          <div style={{ textAlign: 'center' }}>
            <Button type="primary">결재</Button>
            <Button type="primary" onClick={handleCancel}>
              취소
            </Button>
          </div>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <Text>IM서비스 신청이 접수되었습니다.</Text>
          <Paragraph>결재 후 서비스를 이용할 수 있습니다.</Paragraph>
          <Paragraph>서비스 이용료 안내</Paragraph>
          <div>
            <Paragraph>공사계약금액의 0.5%</Paragraph>
            <Text>
              예{`)`} 계약금액 1천만원 시 X 0.5% = {comma(50000)}(VAT별도)
            </Text>
            <Paragraph>최종결재금액 VAT 포함 {comma(55000)}원</Paragraph>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ESTIMATEApplication;
