import React from 'react';
import styles from './index.module.less';
import { shallowEqual, useSelector } from 'react-redux';
import { Button } from 'antd';
import TimeTable from '../../../../../components/table/TimeTable';
import { useNavigate } from 'react-router';

const WORKTYPE = {
  0: 'demolish',
  1: 'expansion',
  2: 'paint',
  3: 'floor',
  4: 'door',
  5: 'electronic',
  6: 'wood',
  7: 'kitchen',
  8: 'restroom',
  9: 'entrance',
  10: 'valcony',
  11: 'window',
  12: 'furniture',
  13: 'service',
  14: 'furnish',
};

const dummy = {
  id: 1,
  start_at: '2023-04-02',
  close_at: '2023-08-01',
  constructs: {
    all: [],
    todo: [],
    demolish: [
      {
        name: '바닥재 철거',
        team: true,
        guide: true,
        start_at: '2023-04-25',
        close_at: '2023-04-28',
      },
      {
        name: '비내력벽 철거',
        team: false,
        guide: false,
        start_at: '2023-04-26',
        close_at: '2023-06-30',
      },
    ],
    expansion: [
      {
        name: '확장공간 마감',
        team: true,
        guide: true,
        start_at: '2023-04-25',
        close_at: '2023-04-28',
      },
      {
        name: '확장공간 마감',
        team: true,
        guide: true,
        start_at: '2023-04-26',
        close_at: '2023-04-29',
      },
    ],
    paint: [],
    floor: [],
    door: [],
    electronic: [],
    wood: [],
    kitchen: [],
    restroom: [],
    entrance: [],
    valcony: [],
    window: [],
    furniture: [],
    service: [],
    furnish: [],
  },
};

export default function ManagerConstructScheduleIndex() {
  const navigate = useNavigate();
  const { tplCategories } = useSelector((s) => s.common, shallowEqual);
  const [type, setType] = React.useState('all');
  const [requestBody, setRequestBody] = React.useState(dummy);
  const tableRef = React.useRef();

  const typeOptions = React.useMemo(() => {
    const options = tplCategories?.map((a) => ({ ...a, type: WORKTYPE[a?.order_index] }));

    // return [{ name: '공사현황' }, { name: '전체공종보기', type: 'all' }, { name: '할일', type: 'todo' }, ...options];
    return [{ name: '공사현황' }, { name: '전체공종보기', type: 'all' }, { name: '할일', type: 'todo' }, ...options];
  }, [tplCategories]);

  console.log({ tplCategories, requestBody });
  return (
    <div>
      {/* 헤더 */}
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <span>일정표 작성 / 수정</span>
          <div>
            <span>샛별마을 동성아파트</span>
            <span>경기도 성남시 분당구 이매동 111번지</span>
          </div>
        </div>
        <div className={styles.headerRight}>
          <Button
            style={{
              backgroundColor: '#ff3f3f',
              color: '#fff',
              width: 150,
              height: 25,
              fontWeight: '500',
              marginBottom: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
            }}
          >
            일정표 등록
          </Button>
          <span>2023년 01월 23일 수요일 17:45:12</span>
        </div>
      </div>

      {/* 본문 */}

      <div className={styles.content}>
        <div className={styles.contentLeft}>
          {typeOptions?.map((option, index) => {
            const active = option?.name === type;

            return (
              <div
                key={`NAVIGATION_OPTION_${index}`}
                className={styles.navOptions}
                style={{ backgroundColor: active ? '#a5b3e2' : '#fff' }}
                onClick={() => {
                  if (option?.name === '공사현황') {
                    navigate('/im/status/construct');
                    return;
                  }
                  setType(option?.type);
                }}
              >
                <img
                  src={require('../../../../../assets/icon/icon_kitchen.png')}
                  alt="네이게이션 이미지"
                  style={{ width: 70, height: 70 }}
                  draggable={false}
                />
                <span>{option?.name}</span>
              </div>
            );
          })}
        </div>

        <div className={styles.contentRight} style={{ overflow: 'scroll', padding: '10px 10px 10px 0' }}>
          <TimeTable
            ref={tableRef}
            type={type}
            dataSource={requestBody}
            tplCategories={tplCategories}
            requestBody={requestBody}
            setRequestBody={setRequestBody}
          />
        </div>
      </div>
    </div>
  );
}
