import { Button, Modal, Pagination, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { comma } from '../../../../../common/utils';
import { ESTIMATE_DATA } from '../../../../../mocks';
import styles from './style.module.less';

const estimate_contract_columns = [
  {
    title: '번호',
    dataIndex: 'id',
    key: 'id',
    width: 100,
    align: 'center',
    sorter: true,
  },

  {
    title: '제안견적가',
    dataIndex: 'proposal_price',
    width: 300,
    key: 'proposal_price',
    align: 'center',
    render: (value) => comma(value),
    sorter: true,
  },
  {
    title: '최종제안서 제출일',
    dataIndex: 'final_date',
    key: 'final_date',
    width: 300,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },

  {
    title: '고객조회일시',
    dataIndex: 'view_day',
    key: 'view_day',
    width: 300,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },
  {
    title: '견적서 수정',
    dataIndex: 'modify',
    key: 'modify',
    width: 300,
    align: 'center',
    render: () => <Button type="primary">견적서 수정</Button>,
    sorter: true,
  },
];

export default function Estimate() {
  const { Paragraph } = Typography;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/estimate/contract/${record.id}`);
      },
    };
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <Table columns={estimate_contract_columns} dataSource={ESTIMATE_DATA} pagination={false} onRow={onRow} />
      <Pagination defaultCurrent={1} total={50} className={styles.page} />
      <div className={styles.endBtn}>
        <Button onClick={handleOpen} danger>
          상담종료
        </Button>
      </div>
      <Modal
        open={isOpen}
        onCancel={handleCancel}
        title="상담종료"
        footer={[
          <div style={{ textAlign: 'center' }}>
            <Button key="send" danger style={{ backgroundColor: 'red', color: 'white' }}>
              상담종료
            </Button>
            <Button key="cancel" type="primary" onClick={handleCancel}>
              취소
            </Button>
          </div>,
        ]}
      >
        <div className={styles.txtContainer}>
          <Paragraph>상담 종료를 선택하였습니다.</Paragraph>
          <Paragraph>상담을 종료하시면 더 이상 고객과 상담을 진행할 수 없습니다.</Paragraph>
          <Paragraph>상담을 종료하시겠습니까?</Paragraph>
        </div>
      </Modal>
    </div>
  );
}
