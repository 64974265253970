import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { restApi } from '../../../apis';
import { Button, Descriptions, Divider, Modal, PageHeader, Space, Switch, Tabs, Typography } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { useProductFinder, withProductFinder } from '../../../components/ProductFinder';
import { uniq } from 'lodash';
import { ESTIMATE_ITEM_TYPE } from '../../../common/define';
import { cleanSelected, comma, getPriceItems, getRealSelected } from '../../../common/utils';
import DiffTable from '../../../components/DiffTable';
import styles from '../../estimate/request/[id]/style.module.less';

const { Text } = Typography;

const CustomerEstimateDetails = (props) => {
  const { id } = useParams();
  const { tplCategories, estimateTpls } = useSelector((s) => s.common, shallowEqual);

  const [showByArea, setShowByArea] = useState(false);
  const { products, addPids } = useProductFinder();

  const [data, setData] = useState();
  const [comSelected, setComSelected] = useState();

  const readData = useCallback(async (id) => {
    await restApi.put(`/partners/constructs/${id}/read`);
  }, []);

  useEffect(() => {
    readData(id).catch(console.warn);
  }, [id]);

  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/estimates/${id}`);
      setData(data);
      setComSelected(data?.com_selected || data.selected || []);
      const pids = uniq(
        data.selected
          ?.map((x) => x.products)
          ?.filter((x) => !!x)
          ?.reduce((a, b) => a.concat(b), [])
          ?.map((x) => x.product_id),
      );
      console.log({ pids });
      addPids(pids);
    };
    loadData().catch(console.warn);
  }, [id]);

  const areas = useMemo(() => {
    const tabItems = Object.keys(data?.calc_dimensions ?? {})
      .reduce((a, key) => {
        const items = data?.calc_dimensions[key];
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          let title = ESTIMATE_ITEM_TYPE[key];
          if (items.length > 1) {
            title += ` ${i + 1}`;
          }
          a.push({
            id: key + '_' + i,
            type: key,
            name: title,
            dimen: item,
          });
        }
        return a;
      }, [])
      .concat([
        {
          id: '_all',
          type: '_all',
          name: '창호/서비스',
          dimen: {
            sash: data?.calc_dimensions?.sash,
            size: data?.area_size,
          },
        },
      ]);
    return tabItems;
  }, [data?.calc_dimensions]);

  const tabs = useMemo(() => {
    if (!showByArea) {
      return tplCategories?.map((v) => ({ id: v.id, name: v.name }));
    }
    return areas;
    //data
  }, [showByArea, tplCategories, areas]);

  const [isOpen, setIsOpen] = useState({
    temporary: false,
    send: false,
  });
  const handleCancel = () => {
    setIsOpen({
      temporary: false,
      send: false,
    });
  };

  const handleRealSubmit = async () => {
    const comRealSelected = getRealSelected(estimateTpls, cleanSelected(estimateTpls, comSelected), tplCategories);
    const prices = getPriceItems({
      estimateTpls,
      dimensions: data?.calc_dimensions,
      selected: comSelected,
      realSelected: comRealSelected,
      products,
    });

    await restApi.post(`/partners/constructs`, {
      estimate_id: id,
      state: 'ACTIVE',
      selected: comSelected,
      prices,
      total_price: Object.values(prices).reduce((x, y) => x + y, 0),
    });

    alert('발송되었습니다.');
    handleCancel();
  };

  const tabItems = tabs?.map((tab) => {
    const itemOrigins = !showByArea ? areas : tplCategories?.map((v) => ({ id: v.id, name: v.name }));
    const items = itemOrigins
      ?.map((item) => {
        let extra = { id: undefined, type: undefined, category: undefined };
        if (!showByArea) {
          extra.id = item.id;
          extra.type = item.type;
          extra.category = tab.id;
          const tpls = uniq(
            estimateTpls
              ?.filter((x) => x.main_category?.id === tab.id)
              ?.map((x) => x.element_types)
              ?.reduce((a, b) => a.concat(b), []),
          );
          if (!tpls?.includes(item.type)) return undefined;
        } else {
          extra.id = tab.id;
          extra.type = tab.type;
          extra.category = item.id;
          const tpls = uniq(
            estimateTpls
              ?.filter((x) => x.element_types?.includes(tab.type))
              ?.map((x) => x.main_category?.id)
              ?.reduce((a, b) => a.concat(b), []),
          );
          if (!tpls?.includes(item.id)) return undefined;
        }

        return {
          ...item,
          area_id: extra.id,
          area_type: extra.type,
          main_category_id: extra.category,
          filter: !showByArea ? (x) => x.key === item.id : (x) => x.key === tab.id && x.main_category_id === item.id,
          getDimen: !showByArea ? () => item.dimen : () => tab.dimen,
        };
      })
      .filter((x) => !!x);

    return {
      label: tab.name,
      key: `${tab.id}`,
      children: (
        <DiffTable
          dimensions={data?.calc_dimensions}
          usrSelected={data?.selected}
          usrPrices={data?.prices}
          items={items}
          filter={showByArea ? () => true : (x) => x.main_category.id == tab.id}
        />
      ),
    };
  });

  return (
    <>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <PageHeader title="공사도움받기 신청" subTitle="제안서 작성" />
              </div>
              <Descriptions bordered column={3}>
                <Descriptions.Item label={'주택명'}>
                  {data?.apt_name} {data?.total_price}
                </Descriptions.Item>
                <Descriptions.Item label={'면적'}>{data?.area_size}PY</Descriptions.Item>
                <Descriptions.Item label={'현장주소'}>{data?.address}</Descriptions.Item>
              </Descriptions>
            </div>
            <div style={{ paddingBottom: 10 }}>
              <span>공종별 보기</span>
              <Switch checked={showByArea} onClick={() => setShowByArea((x) => !x)} style={{ margin: '0px 10px' }} />
              <span>공간별 보기</span>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Button type={'primary'} onClick={() => window.history.back()}>
              나가기
            </Button>
          </div>
        </div>

        {/*
       <Space className={styles.btnContainer}>
          <Button

            type="primary"
          >
            임시저장
          </Button>
          <Button
            onClick={() => {
              setIsOpen({
                send: true,
              });
            }}
            type="primary"
          >
            제안서 발송
          </Button>
        </Space>
      */}

        <Divider className={styles[`m-0`]} />
        <Tabs items={tabItems} />

        {/* 제안서 발송 모달 */}
        <Modal
          open={isOpen.send}
          title="제안서 발송"
          onCancel={handleCancel}
          footer={[
            <div style={{ textAlign: 'center' }}>
              <Button key="send" type="primary" onClick={handleRealSubmit}>
                제안서 발송하기
              </Button>
              <Button key="cancel" type="primary" onClick={handleCancel}>
                취소
              </Button>
            </div>,
          ]}
        >
          <Space size="large" className={styles.dflexBtw}>
            {/* <Space className={styles.price}>
            <Text>바닥공사소계</Text>
            <Text>
              <strong>{comma(2120000)}</strong>원
            </Text>
          </Space> */}
            <Space size="large" className={styles.price}>
              <Text>소비자 견적금액</Text>
              <Text>
                <strong>{comma(data?.total_price)}</strong>원
              </Text>
            </Space>
          </Space>

          <Space size="large" className={styles.price}>
            <Text>제안한 견적금액</Text>
            <Text>
              <strong>{comma(isOpen?.sendComPrice)}</strong>원
            </Text>
          </Space>

          {/*<div style={{ marginBottom: 20 }}>*/}
          {/*  <Paragraph>첨부파일</Paragraph>*/}
          {/*  <Upload*/}
          {/*    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"*/}
          {/*    listType="picture-card"*/}
          {/*    fileList={fileList}*/}
          {/*    onPreview={handlePreview}*/}
          {/*    onChange={handleChange}*/}
          {/*  >*/}
          {/*    {fileList.length >= 8 ? null : uploadButton}*/}
          {/*  </Upload>*/}
          {/*</div>*/}
          {/*<Modal open={previewOpen} title={previewTitle} footer={null} onCancel={closeModal}>*/}
          {/*  <img*/}
          {/*    alt="example"*/}
          {/*    style={{*/}
          {/*      width: '100%',*/}
          {/*    }}*/}
          {/*    src={previewImage}*/}
          {/*  />*/}
          {/*</Modal>*/}
          {/*<Space size="large" className={styles.dflex}>*/}
          {/*  <Paragraph>제안한 스타일 :</Paragraph>*/}
          {/*  <Space>*/}
          {/*    <Paragraph>예쁜 주방 디자인</Paragraph>*/}
          {/*    <Paragraph>스타일명</Paragraph>*/}
          {/*  </Space>*/}
          {/*</Space>*/}
        </Modal>

        {/* 임시저장 모달 */}
        <Modal
          width={600}
          open={isOpen.temporary}
          title="임시저장"
          onCancel={handleCancel}
          footer={[
            <div style={{ textAlign: 'center' }}>
              <Button key="back" type="primary" onClick={handleCancel}>
                확인
              </Button>
            </div>,
          ]}
        >
          <Text>
            제안서를 임시저장하였습니다. 임시저장한 제안서는 제안한 견적에 보관(제안대기중)됩니다. <br /> 제안 대기중
            항목을 클릭하여 제안서 작성을 진행할 수 있습니다.
          </Text>
        </Modal>
      </div>
    </>
  );
};

export default withProductFinder(CustomerEstimateDetails);
