import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Image,
  Input,
  List,
  Modal,
  Space,
  Table,
  Typography,
} from 'antd';
import { comma } from '../../../../../../../common/utils';
import styles from './style.module.less';
import useNewOrder, { columns } from './useNewOrder';
import { ESTIMATE_ORDER_LIST_DATA } from '../../../../../../../mocks';

const data = [
  {
    title: 'Ant Design Title 1',
  },
  {
    title: 'Ant Design Title 2',
  },
  {
    title: 'Ant Design Title 3',
  },
  {
    title: 'Ant Design Title 4',
  },
];

const NewOrder = ({ onSubmit }) => {
  const { data } = useNewOrder();
  const { Text, Paragraph, Title } = Typography;
  const [open, setIsOpen] = useState(false);

  return (
    <Card>
      <div className={styles.addBtn}>
        <Button
          type="primary"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          상품추가
        </Button>
      </div>
      <Table dataSource={ESTIMATE_ORDER_LIST_DATA} columns={columns} pagination={false} />
      <Divider />
      <Modal
        title="상품선택하기"
        open={open}
        onCancel={() => {
          setIsOpen(false);
        }}
        width={1500}
        footer={[
          <Button key="submit" className={styles.btnResizing}>
            선택하기
          </Button>,
        ]}
      >
        <Form
          className={styles.formCotainer}
          name="basic"
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className={styles.flex}>
            <div className={styles['width-70']}>
              <div>
                <Form.Item
                  className={styles.inputwrap}
                  label="카테고리"
                  name="category"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please input your username!',
                  //   },
                  // ]}
                >
                  <div className={styles.flex}>
                    <Checkbox />
                    <Paragraph className={styles.categoryBox}>
                      <span>철물</span> {`>`} <span>경첩</span>
                    </Paragraph>
                  </div>
                </Form.Item>
                <div className={styles.flex}>
                  <Form.Item
                    className={styles['width-50']}
                    label="유통사"
                    name="distributor"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Please input your password!',
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className={styles['width-50']}
                    label="검색어"
                    name="keyword"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Please input your password!',
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>

              <div className={styles.flex}>
                <List>
                  <List.Item className={styles['fiex-column-align-start']}>
                    <div>
                      <Image
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPNYaeEOduco9L83GVB-dNHSjn2thHsxk0QQ&usqp=CAU"
                        alt=""
                        width={200}
                      />
                    </div>
                    <div>
                      <Title className={styles['item-title-font']} level={2}>
                        이지경첩(골드)
                      </Title>
                      <Paragraph>하나금속철물</Paragraph>
                      <Paragraph>힐림 방문경첩</Paragraph>
                      <Paragraph>
                        업체가: {comma(4400)}원 / 소비자: {comma(6000)}원
                      </Paragraph>
                    </div>
                  </List.Item>
                </List>
              </div>
            </div>
            <div className={styles['width-30']}>
              <Text>기존 상품</Text>
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={`https://joesch.moe/api/v1/random?key=${index}`} />}
                      title={<a href="https://ant.design">{item.title}</a>}
                      description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                  </List.Item>
                )}
              />
              <Text>선택한 상품</Text>
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={`https://joesch.moe/api/v1/random?key=${index}`} />}
                      title={<a href="https://ant.design">{item.title}</a>}
                      description={
                        'Ant Design, a design language for background applications, is refined by Ant UED Team '
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Form>
      </Modal>
      <div className={styles.footer}>
        <div>
          <Space className={styles['button-wrapper']}>
            <Button type="primary">현장</Button>
            <Button>사무실</Button>
          </Space>
          <div className={styles['shipping-info-wrapper']}>
            <div className={styles['shipping-info-title']}>
              <Text>배송장소 :&nbsp;</Text>
              <Text>배송메모 :&nbsp;</Text>
            </div>
            <div>
              <Text>서울 강서구 현대아파트 102동 202호</Text>
              <Input.TextArea />
            </div>
          </div>
        </div>
        <div className={styles.extra}>
          <div className={styles['price-wrapper']}>
            <Text>발주금액 합계</Text>
            <strong>{comma(23000000)}</strong>
          </div>
          <Button type="primary" className={styles.order} size="large" onClick={onSubmit}>
            발주요청
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default NewOrder;
