import React from 'react';
import Wrapper from '../components/Wrapper';
import { Card, Col, Row } from 'antd';

const HomeIndex = (props) => {
  return (
    <Wrapper>
      <Row gutter={[10, 10]}>
        <Col xs={24} lg={15}>
          <Card title={'오늘의 통계'} style={{ minHeight: 300 }}></Card>
        </Col>
        <Col xs={24} lg={9}>
          <Card title={'공지사항'} style={{ minHeight: 300 }}></Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card title={'조회'} style={{ minHeight: 300 }}></Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card title={'보관함'} style={{ minHeight: 300 }}></Card>
        </Col>
        <Col xs={24} lg={24}>
          <Card title={'신규 발주'} style={{ minHeight: 500 }}></Card>
        </Col>
      </Row>
    </Wrapper>
  );
  // const dataSource = [
  //   {
  //     key: '1',
  //     name: 'Mike',
  //     age: 32,
  //     address: '10 Downing Street',
  //   },
  //   {
  //     key: '2',
  //     name: 'John',
  //     age: 42,
  //     address: '10 Downing Street',
  //   },
  // ];
  //
  // const columns = [
  //   {
  //     title: 'Name',
  //     dataIndex: 'name',
  //     key: 'name',
  //   },
  //   {
  //     title: 'Age',
  //     dataIndex: 'age',
  //     key: 'age',
  //   },
  //   {
  //     title: 'Address',
  //     dataIndex: 'address',
  //     key: 'address',
  //   },
  // ];
  //
  // const searchField = [
  //   {
  //     submit: true,
  //     width: 0.5,
  //     label: '일반형 라디오버튼',
  //     type: 'radio',
  //     key: 'arg1',
  //     items: [
  //       { label: '선택값 1', value: '1' },
  //       { label: '선택값 2', value: '2' },
  //       { label: '선택값 3', value: '3' },
  //     ],
  //   },
  //   {
  //     width: 0.5,
  //     label: '박스형 라디오버튼',
  //     type: 'radiobutton',
  //     key: 'arg2',
  //     items: [
  //       { label: '선택값 1', value: '1' },
  //       { label: '선택값 2', value: '2' },
  //       { label: '선택값 3', value: '3' },
  //     ],
  //   },
  //   {
  //     width: 0.5,
  //     label: '체크박스',
  //     type: 'checkbox',
  //     key: 'arg3',
  //     items: [
  //       { label: '선택값 1', value: '1' },
  //       { label: '선택값 2', value: '2' },
  //       { label: '선택값 3', value: '3' },
  //     ],
  //   },
  //   {
  //     width: 0.5,
  //     label: '날짜',
  //     type: 'datepicker',
  //     key: 'arg4',
  //   },
  //   {
  //     width: 0.5,
  //     label: '기간 설정',
  //     type: 'daterange',
  //     key: 'arg5',
  //     keys: ['arg5', 'arg6'],
  //   },
  //   {
  //     width: 0.5,
  //     label: '셀렉박스',
  //     type: 'select',
  //     key: 'arg7',
  //     items: [
  //       { label: '선택값 1', value: '1' },
  //       { label: '선택값 2', value: '2' },
  //       { label: '선택값 3', value: '3' },
  //     ],
  //   },
  //   {
  //     width: 0.5,
  //     label: '일반검색',
  //     type: 'input',
  //     key: 'arg8',
  //   },
  // ];
  //
  // return (
  //   <div>
  //     <PageHeader className="site-page-header" title="Title" subTitle="This is a subtitle" />
  //     <SearchForm fields={searchField} />
  //     <Table dataSource={dataSource} columns={columns} />
  //   </div>
  // );
};

export default HomeIndex;
