import React from 'react';
import styles from '../../pages/construction/list/detail/style.module.less';
import { Button, Card, Descriptions, Space } from 'antd';

const Info = () => {
  return (
    <Card className={styles.info}>
      <Descriptions bordered column={2} className={styles.descriptions}>
        <Descriptions.Item label={'공사명'}>공사 1</Descriptions.Item>
        <Descriptions.Item label={'고객 정보'}>김아무개 / 010-1234-4897</Descriptions.Item>
        <Descriptions.Item label={'공사 의뢰일'}>
          <Space size="large">
            <span>2022-01-03</span>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label={'최종계약금액'}>30,000,000</Descriptions.Item>
        <Descriptions.Item label={'주소'}>서울 강서부 남부순환로11길 32</Descriptions.Item>
        <Descriptions.Item label={'실행 금액'}>
          <Space size="large">
            <span>20,000,000</span>
            <Button>설정하기</Button>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label={'의뢰/시작일'}>2022-01-03 / 2022-05-01</Descriptions.Item>
        <Descriptions.Item label={'집행된 금액'}>10,000,000</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default Info;
