import React from 'react';
import { Button, Card, Form, Modal, Select, Table, Tree, Typography } from 'antd';
import useDocument, { complete_columns, processing_columns, received_columns } from './useDocument';
import styles from './style.module.less';
import Search from 'antd/lib/input/Search';

const Document = () => {
  const { processingDocument, completeDocument, receivedDocument } = useDocument();
  const { Options } = Select;
  const { Text, Paragraph } = Typography;
  const [isOpen, setIsOpen] = React.useState(false);
  const handleCancel = () => {
    setIsOpen(false);
  };
  const { DirectoryTree } = Tree;
  const treeData = [
    {
      title: 'parent 0',
      key: '0-0',
      children: [
        {
          title: 'leaf 0-0',
          key: '0-0-0',
          isLeaf: true,
        },
        {
          title: 'leaf 0-1',
          key: '0-0-1',
          isLeaf: true,
        },
      ],
    },
    {
      title: 'parent 1',
      key: '0-1',
      children: [
        {
          title: 'leaf 1-0',
          key: '0-1-0',
          isLeaf: true,
        },
        {
          title: 'leaf 1-1',
          key: '0-1-1',
          isLeaf: true,
        },
      ],
    },
    {
      title: 'parent 1',
      key: '0-1',
      children: [
        {
          title: 'leaf 1-0',
          key: '0-1-0',
          isLeaf: true,
        },
        {
          title: 'leaf 1-1',
          key: '0-1-1',
          isLeaf: true,
        },
      ],
    },
    {
      title: 'parent 1',
      key: '0-1',
      children: [
        {
          title: 'leaf 1-0',
          key: '0-1-0',
          isLeaf: true,
        },
        {
          title: 'leaf 1-1',
          key: '0-1-1',
          isLeaf: true,
        },
      ],
    },
    {
      title: 'parent 1',
      key: '0-1',
      children: [
        {
          title: 'leaf 1-0',
          key: '0-1-0',
          isLeaf: true,
        },
        {
          title: 'leaf 1-1',
          key: '0-1-1',
          isLeaf: true,
        },
      ],
    },
  ];
  const onSelect = (keys, info) => {
    console.log('Trigger Select', keys, info);
  };
  const onExpand = (keys, info) => {
    console.log('Trigger Expand', keys, info);
  };
  return (
    <Card>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        type="primary"
        style={{ float: 'right' }}
        size="large"
      >
        새 결재 진행
      </Button>

      <Modal
        className={styles['w-700']}
        open={isOpen}
        onCancel={handleCancel}
        title="결재양식 선택"
        footer={[
          <Button key="check" type="primary">
            확인
          </Button>,
          <Button
            key="cancel"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            취소
          </Button>,
        ]}
      >
        <Paragraph className={styles['text-end']}>자주 쓰는 양식으로 추가</Paragraph>
        <div className={styles.flex}>
          <Card className={styles['width-40-mR-10']}>
            <Search
              placeholder="양식제목"
              // onSearch={onSearch}
            />

            <DirectoryTree
              multiple
              defaultExpandAll
              onSelect={onSelect}
              onExpand={onExpand}
              treeData={treeData}
              height={260}
              style={{ marginTop: 10 }}
              treeNodeHeight={30}
            />
          </Card>
          <div className={styles['width-60']}>
            <Card title="상세정보">
              <Form className={styles['form-item-margin-bottom']}>
                <Form.Item label="제목" name="">
                  <Text>비품 구매신청</Text>
                </Form.Item>
                <Form.Item label="전사문서함" name="">
                  <Text>지원</Text>
                </Form.Item>
                <Form.Item label="보존연한" name="">
                  <Text>5년</Text>
                </Form.Item>
                <Form.Item label="기안부서" name="draft">
                  <Select
                    options={[
                      {
                        value: 'lucy',
                        label: '이사회',
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item label="부서문서함" name="department">
                  <Select
                    options={[
                      {
                        value: 'unpointed',
                        label: '미지정',
                      },
                    ]}
                  />
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      </Modal>
      <Table
        columns={processing_columns}
        dataSource={processingDocument}
        title={() => <Typography.Title level={5}>기안 진행문서</Typography.Title>}
      />
      <Table
        dataSource={completeDocument}
        columns={complete_columns}
        title={() => <Typography.Title level={5}>완료문서</Typography.Title>}
      />
      <Table
        columns={received_columns}
        dataSource={receivedDocument}
        title={() => <Typography.Title level={5}>결재 수신문서</Typography.Title>}
      />
    </Card>
  );
};

export default Document;
