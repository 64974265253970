import React from 'react';
import { Button, DatePicker, Input, Select, Typography, Upload } from 'antd';

import styles from './style.module.less';
import { PlusOutlined } from '@ant-design/icons';

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const Used = ({ onSubmit }) => {
  return (
    <div className={styles.used}>
      <div className={styles['form-wrapper']}>
        <div className={styles.item}>
          <Typography.Text className={styles.title}>신청일자</Typography.Text>
          <DatePicker
            showTime
            className={styles.input}
            onChange={(date, dateString) => {
              onChange(date, dateString);
            }}
          />
          {/* <TimePicker /> */}
        </div>
        <div className={styles.item}>
          <Typography.Text className={styles.title}>담당자</Typography.Text>
          <Input className={styles.input} />
        </div>
        <div className={styles.item}>
          <Typography.Text className={styles.title}>금액</Typography.Text>
          <Input className={styles.input} />
        </div>
        <div className={styles.item}>
          <Typography.Text className={styles.title}>계정과목</Typography.Text>
          <Select className={styles.input} />
        </div>
        <div className={styles.item}>
          <Typography.Text className={styles.title}>사진</Typography.Text>
          <Upload listType="picture-card" className={styles.input}>
            {uploadButton}
          </Upload>
        </div>
        <div className={styles.item}>
          <Typography.Text className={styles.title}>결제상태</Typography.Text>
          <Select className={styles.input} />
        </div>
        <div className={styles.item}>
          <Typography.Text className={styles.title}>전도여부</Typography.Text>
          <Select className={styles.input} />
        </div>
      </div>
      <Button type="primary" onClick={onSubmit} size="large">
        비용 등록
      </Button>
    </div>
  );
};

export default Used;
