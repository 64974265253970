import React from 'react';
import { PageHeader } from 'antd';

const SettingsAccountsIndex = (props) => {
  return (
    <div>
      <PageHeader className="site-page-header" title="직원 관리" subTitle="설정" />
    </div>
  );
};

export default SettingsAccountsIndex;
