import { CameraOutlined } from '@ant-design/icons';
import { Button, Input, Modal, PageHeader, Upload } from 'antd';
import React from 'react';
import styles from './index.module.less';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    // message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    // message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export default function ManagerApplicationDirectIndex() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState();
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  return (
    <div>
      <PageHeader title="IM 시작하기 > 직접시작하기" />
      <div
        style={{ padding: 20, width: 700, height: 650, backgroundColor: '#fff', maxWidth: '1200px', margin: '0 auto' }}
      >
        <div style={{ textAlign: 'end' }}>
          <img
            src={require('../../../../assets/icon/icon_template_close_sample.png')}
            alt="템플릿 제거 버튼"
            style={{ width: 30, height: 30, cursor: 'pointer' }}
            onClick={() => {}}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 60 }}>
          <Upload
            name="avatar"
            listType="picture-circle"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: '100%',
                }}
              />
            ) : (
              <div
                style={{
                  background: '#e8e8e8',
                  width: 120,
                  height: 120,
                  borderRadius: 60,
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <CameraOutlined className={styles.cameraImg} />
                <div style={{ width: 120, height: 30, background: '#c9c6c6c0', position: 'absolute', bottom: 0 }} />
              </div>
            )}
          </Upload>
        </div>

        <div style={{ display: 'flex', padding: '0 80px', marginBottom: 5 }}>
          <div style={{ width: 200 }}>
            <strong>고객명</strong>
            <span>(선택)</span>
          </div>
          <Input style={{ flex: 1 }} />
        </div>
        <div style={{ display: 'flex', padding: '0 80px', marginBottom: 5 }}>
          <div style={{ width: 200 }}>
            <strong>연락처</strong>
            <span>(선택)</span>
          </div>
          <Input style={{ flex: 1 }} />
        </div>
        <div style={{ display: 'flex', padding: '0 80px', marginBottom: 5 }}>
          <div style={{ width: 200 }}>
            <strong>주소</strong>
            <span>(필수)</span>
          </div>
          <Input style={{ flex: 1 }} />
        </div>
        <div style={{ display: 'flex', padding: '0 80px', marginBottom: 5 }}>
          <div style={{ width: 200 }}>
            <strong>타입(면적)</strong>
            <span>(필수)</span>
          </div>
          <Input style={{ flex: 1 }} />
        </div>
        <div style={{ display: 'flex', padding: '0 80px', marginBottom: 5 }}>
          <div style={{ width: 200 }}>
            <strong>공사시작일</strong>
            <span>(필수)</span>
          </div>
          <Input style={{ flex: 1 }} />
        </div>
        <div style={{ display: 'flex', padding: '0 80px', marginBottom: 5 }}>
          <div style={{ width: 200 }}>
            <strong>입주예정일</strong>
            <span>(필수)</span>
          </div>
          <Input style={{ flex: 1 }} />
        </div>
        <div style={{ display: 'flex', padding: '0 80px', marginBottom: 5 }}>
          <div style={{ width: 200 }}>
            <strong>견적서</strong>
            <span>(필수)</span>
          </div>
          <Button disabled type="primary" style={{ flex: 1 }}>
            작성
          </Button>
        </div>

        <div style={{ textAlign: 'center', marginTop: 80 }}>
          <Button disabled type="primary" onClick={() => setIsModalOpen(true)}>
            IM서비스 시작
          </Button>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        centered
        footer={null}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={500}
        bodyStyle={{ padding: 40 }}
        closable={null}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>결재</h2>
          <img
            src={require('../../../../assets/icon/icon_template_close_sample.png')}
            alt="템플릿 제거 버튼"
            style={{ width: 30, height: 30 }}
            onClick={() => setIsModalOpen(false)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ fontSize: 18, fontWeight: 300 }}>50,000포인트가 결재됩니다.</p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className={styles.pointLine}>
              <strong className={styles.pointTitle}>보유 포인트</strong>
              <span className={styles.textContent}>100,000</span>
            </div>
            <div className={styles.pointLine}>
              <strong className={styles.pointTitle}>차감 포인트</strong>
              <span className={styles.textContent}>5,000</span>
            </div>
            <div className={styles.pointLine}>
              <strong className={styles.pointTitle}>잔여 포인트</strong>
              <span className={styles.textContent}>5,000</span>
            </div>
          </div>
          <div className={styles.imgBox}>
            <img
              src={require('../../../../assets/icon/baba_pass.png')}
              style={{ width: '100%', height: 'auto' }}
              alt="baba_pass"
            />
            <div className={styles.pointBox}>
              <span style={{ fontSize: 22, fontWeight: 600 }}>300,000</span>
              <span>포인트</span>
            </div>
          </div>
          <p style={{ fontSize: 16, fontWeight: 600 }}>결재 후 제공 내용</p>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>현장관리지원</div>
            <span className={styles.infoContent}>공사일보, 작업자출퇴근관리, 작업현황관리</span>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>업무지원</div>
            <span className={styles.infoContent}>공사스케줄관리시스템, 발주관리시스템 등</span>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>고객서비스</div>
            <span className={styles.infoContent}>작업상황공유, 스케줄공유</span>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>기타서비스</div>
            <span className={styles.infoContent}>발주업무관리 서비스, 인력알선서비스(준비중)</span>
          </div>
          <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              type="primary"
              size="large"
              style={{ width: 130 }}
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              포인트 결제
            </Button>
            <Button type="danger" size="large" style={{ width: 130 }} onClick={() => setIsModalOpen(false)}>
              포인트 충전
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
