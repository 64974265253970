export const ESTIMATE_ITEM_TYPE = {
  living_rooms: '거실',
  kitchens: '주방',
  master_rooms: '안방',
  rooms: '방',
  entrance: '현관',
  living_room_cr: '화장실',
  master_room_cr: '안방 화장실',
  room_cr: '방 화장실',
};

export const WORKTYPE_LABELS = {
  all: '전체공종',
  todo: '할일',
  demolish: '철거',
  expansion: '확장',
  paint: '도배/페인트',
  floor: '바닥',
  door: '문, 터닝도어',
  electronic: '전기/조명',
  wood: '목공',
  kitchen: '주방/싱크',
  restroom: '화장실',
  entrance: '현관',
  valcony: '발코니',
  window: '샤시/창호',
  furniture: '가구',
  service: '홈 서비스',
  furnish: '홈퍼니싱',
};

export const WORKTYPE_THEME = {
  demolish: '#d5b4fa',
  expansion: '#f9b3b3',
  window: '#8cdd8c',
};
