import { PageHeader } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.less';
import { restApi } from '../../../apis';
import moment from 'moment';

export default function ProposalContract() {
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/partners/proposals`, { params: { state: 'APP_ING' } });
    setItems(data);
  }, []);
  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  return (
    <div>
      <PageHeader title="상담진행 리스트" />
      <div className={styles.container} style={{ display: 'flex', flexWrap: 'wrap', gap: 30 }}>
        {items?.map((item) => (
          <div className={styles.listBox} onClick={() => navigate(`/customers/details/${item?.id}`)}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 20 }}>
              <span style={{ fontWeight: 500 }}>상담진행</span>
              <span style={{ fontWeight: 300 }}>
                최종제출일시 :{' '}
                {!item?.last_send_at ? '전송안함' : moment(item?.last_send_at).format('YYYY-MM-DD HH:mm')}
              </span>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ position: 'relative' }}>
                <img
                  alt="customer"
                  width={100}
                  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  style={{ borderRadius: 48 }}
                />
              </div>

              <p style={{ fontWeight: 500, marginTop: 8 }}>{item?.construct?.account?.nickname}</p>
              <strong style={{ fontSize: 20 }}>{item.estimate?.title}</strong>
              <p style={{ fontWeight: 300 }}>
                고객확인일시 {!item?.last_view_at ? '확인안함' : moment(item?.last_view_at).format('YYYY-MM-DD HH:mm')}
              </p>
              <div>
                <span style={{ fontWeight: 300 }}>상담회차</span>
                <strong style={{ fontSize: 20 }}>{!item?.index ? '신규' : item?.index + 1 + '회차'}</strong>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
