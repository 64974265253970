import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Input, InputNumber, PageHeader, Row, Space, Table, TreeSelect, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { getAPIHost, restApi } from '../../apis';
import fileDownload from 'js-file-download';
import produce from 'immer';
import { shallowEqual, useSelector } from 'react-redux';

const ProductImage = ({ value, onChange }) => {
  const [loading, setLoading] = useState();
  const uploadButton = (
    <div style={{ fontSize: 12 }}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 0, fontSize: 11 }}>Upload</div>
    </div>
  );
  const [fileList, setFileList] = useState([]);
  return (
    <div className={'small-uploader'}>
      <Upload
        listType="picture-card"
        className="avatar-uploader"
        action={`${getAPIHost()}/files`}
        method={'PUT'}
        name={'filedata'}
        onPreview={(e) => {
          console.log(e);
          alert('onPreview');
        }}
        onChange={({ file, fileList }) => {
          setFileList(fileList);
          if (!fileList?.[0]) {
            onChange(undefined);
          } else {
            if (file.status === 'done') {
              onChange(file.response.id);
              setFileList([]);
            }
          }
        }}
        fileList={
          !value
            ? fileList
            : [
                {
                  uid: value,
                  name: value + '.jpg',
                  status: 'done',
                  url: getAPIHost() + '/files/' + value,
                  thumbUrl: getAPIHost() + '/files/' + value + '?w=72&h=60',
                },
              ]
        }
      >
        {value ? null : uploadButton}
      </Upload>
    </div>
  );
};

const ProductsCreate = () => {
  const [items, setItems] = useState();
  const [file, setFile] = useState();

  const { productCategories } = useSelector((s) => s.common, shallowEqual);

  const treeData = useMemo(() => {
    const parseItem = (d) => {
      return {
        value: d.code,
        label: d.label,
        children: !d.children?.length ? undefined : d.children?.map((v) => parseItem(v)),
      };
    };

    return productCategories.map((v) => parseItem(v));
  }, [productCategories]);

  const columns = useMemo(() => {
    return [
      {
        title: '고유코드',
        dataIndex: 'serial',
        key: 'serial',
        width: 150,
        fixed: 'left',
        render: (d, r, i) => (
          <Input
            value={d}
            onChange={(e) => {
              const value = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].serial = value;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '카테고리',
        dataIndex: 'category',
        key: 'category',
        width: 200,
        render: (value, r, i) => {
          const codes = [];
          if (value) {
            for (let i = 3; i <= value?.length; i += 3) {
              codes.push(value?.substring(0, i));
            }
          }

          return (
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              value={value}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Please select"
              allowClear
              treeDefaultExpandedKeys={codes}
              treeData={treeData}
              onChange={(value) => {
                setItems(
                  produce((draft) => {
                    draft[i].category = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '제조사',
        dataIndex: 'maker_name',
        key: 'maker_name',
        width: 150,
        render: (d, r, i) => (
          <Input
            value={d}
            onChange={(e) => {
              const value = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].maker_name = value;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '브랜드',
        dataIndex: 'brand_name',
        key: 'brand_name',
        width: 150,
        render: (d, r, i) => (
          <Input
            value={d}
            onChange={(e) => {
              const value = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].brand_name = value;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '컬렉션',
        dataIndex: 'collection_name',
        key: 'collection_name',
        width: 150,
        render: (d, r, i) => (
          <Input
            value={d}
            onChange={(e) => {
              const value = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].collection_name = value;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '제품명',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        render: (d, r, i) => (
          <Input
            value={d}
            onChange={(e) => {
              const value = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].name = value;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '이미지',
        dataIndex: 'image',
        key: 'image',
        width: 90,
        align: 'center',
        render: (d, r, i) => {
          return (
            <ProductImage
              value={d}
              onChange={(value) => {
                setItems(
                  produce((draft) => {
                    draft[i].image = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '구매,소개링크',
        dataIndex: 'link',
        key: 'link',
        width: 200,
        render: (d, r, i) => (
          <Input
            value={d}
            onChange={(e) => {
              const value = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].link = value;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '바바 납품가',
        dataIndex: 'origin_price',
        key: 'origin_price',
        width: 130,
        render: (value, r, i) => {
          return (
            <InputNumber
              keyboard={false}
              controls={false}
              style={{ width: '100%' }}
              value={value}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(value) => {
                setItems(
                  produce((draft) => {
                    draft[i].origin_price = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '최소 소비자 표출가',
        dataIndex: 'min_sale_price',
        key: 'min_sale_price',
        width: 130,
        render: (value, r, i) => {
          return (
            <InputNumber
              keyboard={false}
              controls={false}
              style={{ width: '100%' }}
              value={value}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(value) => {
                setItems(
                  produce((draft) => {
                    draft[i].min_sale_price = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '기반 사이즈',
        dataIndex: 'base_size',
        key: 'base_size',
        width: 200,
        render: (d, r, i) => (
          <Input
            value={d}
            onChange={(e) => {
              const value = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].base_size = value;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '소개글',
        dataIndex: 'contents',
        key: 'contents',
        width: 300,
        render: (d, r, i) => (
          <Input.TextArea
            value={d}
            onChange={(e) => {
              const value = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].contents = value;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '설정',
        dataIndex: 'serial',
        key: 'serial2',
        width: 70,
        align: 'center',
        fixed: 'right',
        render: (d, r, i) => (
          <Button
            size={'small'}
            danger
            onClick={() => {
              setItems(
                produce((draft) => {
                  draft.splice(i, 1);
                }),
              );
            }}
          >
            삭제
          </Button>
        ),
      },
    ];
  }, [file]);

  const handleDownload = async () => {
    const { data } = await restApi.get(`/products/xlsx`, { responseType: 'blob' });
    fileDownload(data, 'baba_product_example.xlsx');
  };

  const [loading, setLoading] = useState(false);
  const processUpload = async (file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('filedata', file);
      const { data } = await restApi.post(`/products/xlsx`, formData);
      setItems(data);
    } catch (e) {
      console.error(e);
      alert(e?.response?.data?.message || e?.message || '서버와 연결이 올바르지 않습니다.');
    }
    setLoading(false);
  };

  const mainTable = useRef();

  const [bodyHeight, setBodyHeight] = useState(10);

  useEffect(() => {
    const sizeChange = () => {
      const { top } = mainTable.current?.getBoundingClientRect?.();
      const height = document.body.clientHeight - top - 80;
      setBodyHeight(height);
    };
    sizeChange();
  }, [file]);

  const handleSubmit = async () => {
    setLoading(true);
    const inputs = [...items];
    let ix = 0;
    while (true) {
      const item = inputs?.[ix];
      if (!item) break;
      try {
        await restApi.post(`/products`, item);
        inputs.splice(0, 1);
      } catch (e) {
        ix++;
      }
    }
    setItems(inputs);
    if (inputs.length === 0) {
      alert('성공적으로 업로드가 완료되었습니다.');
      setFile(undefined);
    } else {
      alert('처리 완료.\n실패한 항목은 데이터 수정 후 다시 시도해주세요.');
    }
    setLoading(false);
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="제품 일괄등록" subTitle="제품 관리" />
      <div style={{ marginBottom: 20 }}>
        <Row>
          <Col flex={'1'}>
            <Space>
              <Button onClick={handleDownload}>업로드 템플릿 다운로드</Button>
              <Upload
                fileList={[]}
                beforeUpload={(f) => {
                  setFile(f);
                  processUpload(f).catch(console.warn);
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>업로드 처리</Button>
              </Upload>
            </Space>
          </Col>
          {!!items?.length && (
            <Col>
              <Button loading={loading} onClick={handleSubmit} type={'primary'}>
                일괄 저장
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <div className={'input-table'} ref={mainTable}>
        {file && (
          <Table
            loading={loading}
            scroll={{ x: columns?.reduce((a, b) => a + b.width, 0), y: bodyHeight }}
            dataSource={items}
            columns={columns}
            pagination={false}
            rowKey={(r) => `${items.indexOf(r)}`}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsCreate;
