import React from 'react';
import { Button, Divider, Radio, Select, Space } from 'antd';
import styles from './style.module.less';
import Info from '../../../../components/info';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { MEMOS } from '../../../../mocks';
import Memo from '../../../../components/memo';
import useDetail from './useDetail';
import ConstructionTabs from './tab';

const ConstructionDetail = () => {
  const { isMemoVisible, handleClose } = useDetail();
  return (
    <div className={`${styles.ConstructionDetailJSX} ${isMemoVisible ? '' : styles.side_close}`}>
      <div className={styles.header}>
        <Space size="large">
          <b className={styles.headerTitle}>공사선택</b>
          <Select defaultValue={1} className={styles.headerSelector}>
            <Select.Option value={1}>공사 1</Select.Option>
          </Select>
          <Button type="primary">목록</Button>
        </Space>
      </div>

      <Info />

      <Divider />

      <ConstructionTabs />

      <aside>
        <div className={styles.aside_header}>
          <div className={styles.title}>
            {isMemoVisible ? (
              <RightOutlined size={18} onClick={handleClose} />
            ) : (
              <LeftOutlined size={18} onClick={handleClose} />
            )}
            <b>메모</b>
          </div>
          <Radio.Group defaultValue={'all'}>
            <Radio.Button value={'all'}>전체</Radio.Button>
            <Radio.Button value={'selected'}>해당견적만</Radio.Button>
          </Radio.Group>
        </div>
        <div className={styles.aside_body}>
          {MEMOS.map((memo, index) => (
            <Memo key={index} data={memo} />
          ))}
        </div>
      </aside>
    </div>
  );
};

export default ConstructionDetail;
