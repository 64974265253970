import React from 'react';

const useOrder = () => {
  const [data, setData] = React.useState();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [isNewOrder, setIsNewOrder] = React.useState(false);

  const goToNewOrder = React.useCallback(() => {
    setIsNewOrder(true);
  }, []);

  const submitNewOrder = React.useCallback(() => {
    setIsNewOrder(false);
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return {
    data,
    rowSelection,
    goToNewOrder,
    isNewOrder,
    submitNewOrder,
  };
};

export default useOrder;
