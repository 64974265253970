import { Button, Pagination, Table, Tabs } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ESTIMATE_SPACE_DATA } from '../../../../../mocks';
import styles from './style.module.less';

const estimate_space_columns = [
  {
    title: '번호',
    dataIndex: 'id',
    key: 'id',
    width: 100,
    align: 'center',
    sorter: true,
  },

  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    width: 300,
    align: 'center',
    sorter: true,
  },
  {
    title: '작성자',
    dataIndex: 'writer',
    key: 'writer',
    width: 300,
    align: 'center',
    sorter: true,
  },
  {
    title: '업로드일시',
    dataIndex: 'upload_date',
    key: 'upload_date',
    width: 300,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },
  {
    title: '고객조회일시',
    dataIndex: 'view_day',
    key: 'view_day',
    width: 300,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },

  {
    dataIndex: 'delete',
    key: 'delete',
    width: 300,
    align: 'center',
    render: () => <Button danger>삭제</Button>,
  },
];

export default function SpaceTab() {
  const navigate = useNavigate();

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/estimate/space/${record.id}`);
      },
    };
  };
  return (
    <div>
      <Tabs>
        <Tabs.TabPane tab="거실" key={'livingRoom'}></Tabs.TabPane>
        <Tabs.TabPane tab="주방" key={'kitchen'}></Tabs.TabPane>
        <Tabs.TabPane tab="안방" key={'mainRoom'}>
          <Table columns={estimate_space_columns} dataSource={ESTIMATE_SPACE_DATA} pagination={false} onRow={onRow} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="방" key={'room'}></Tabs.TabPane>
        <Tabs.TabPane tab="화장실" key={'washroom'}></Tabs.TabPane>
        <Tabs.TabPane tab="안방화장실" key={'mainWashroom'}></Tabs.TabPane>
        <Tabs.TabPane tab="현관" key={'frontDoor'}></Tabs.TabPane>
      </Tabs>
      <Pagination defaultCurrent={1} total={50} className={styles.page} />
      <div className={styles.endBtn}>
        <Button type="primary">내용작성</Button>
      </div>
    </div>
  );
}
