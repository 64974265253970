import React from 'react';
import { PageHeader } from 'antd';
import AttachedForm from '../../../../../components/template/AttchedForm';
import styles from './index.module.less';
import produce from 'immer';
import AnnexTable from '../../../../../components/table/AnnexTable';
import dayjs from 'dayjs';

const INIT_STATE = {
  form: [],
};

export default function WriteFloorPlan() {
  const [requestBody, setRequestBody] = React.useState(INIT_STATE);

  React.useEffect(() => {
    if (requestBody?.form?.length === 0) {
      setRequestBody(
        produce((draft) => {
          draft.form.push({ title: '', image: null, description: '' });
        }),
      );
    }
  }, []);

  return (
    <div>
      <PageHeader
        title="도면 첨부"
        extra={[
          <AnnexTable
            column={4}
            items={[
              { label: '주택명', value: '개봉 푸르지오' },
              { label: '면적', value: '34PY' },
              { label: '현장주소', value: '서울시 구로구 남부순환로 775' },
              {
                label: '제안마감일',
                value: (
                  <span>
                    2023-04-27
                    {dayjs().diff('2023-04-27', 'days') > 0 && (
                      <span style={{ marginLeft: 15, fontWeight: '700' }}>{`${dayjs().diff(
                        '2023-04-27',
                        'days',
                      )}일전`}</span>
                    )}
                  </span>
                ),
              },
            ]}
          />,
        ]}
      />

      <div className={styles.btnContainer}>
        <button className={styles.btn}>나가기</button>
        <button className={styles.btn}>저장</button>
      </div>
      {/* <table className={styles.summaryTable}>
        <tr>
          <th>주택명</th>
          <td>개봉 푸르지오</td>
          <th>면적</th>
          <td>34PY</td>
          <th>현장주소</th>
          <td>서울시 구로구 남부순환로 775</td>
          <th>제안마감일</th>
          <td>2023-01-23 12:00</td>
          <th>마감 3일전</th>
        </tr>
      </table> */}
      <hr style={{ borderWidth: 1, borderStyle: 'solid', marginBlock: 20 }} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={styles.cautionContainer}>
          <span>고객에게 어필할 수 있는 도면을 첨부해 주세요</span>
          <br />

          <span>귀사가 생각하고 있는 멋진 공간을 고객이 이해하기 쉽게 도면으로 표현해보세요.</span>
          <br />
          <span>도면은 이미지 파일(JPG, PNGm, BMP 등)로 저장해 주세요.</span>
        </div>

        <div className={styles.btnContainer}>
          <button className={styles.btn}>보관함 가져오기</button>
          <button className={styles.btn}>예시보기</button>
        </div>
      </div>
      <div className={styles.templateContainer}>
        <AttachedForm type="floor" requestBody={requestBody} setRequestBody={setRequestBody} />
      </div>
    </div>
  );
}
