import React from 'react';
import styles from './TodoModal.module.less';
import { Input, Modal, Radio, Select, Switch } from 'antd';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import produce from 'immer';

const CustomModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
`;

const TodoModal = ({ isModalOpen, setIsModalOpen, tplCategories, requestBody, setRequestBody, todoIndex }) => {
  const [form, setForm] = React.useState({
    title: '',
    content: '',
    push_note: false,
    pre_note: false,
    note_to: null,
  });

  const handleSave = () => {
    setRequestBody(
      produce((draft) => {
        draft.constructs['todo'][todoIndex] = form;
      }),
    );
    setIsModalOpen((prev) => ({
      ...prev,
      todo: false,
    }));
    setForm({
      title: '',
      content: '',
      push_note: false,
      pre_note: false,
      note_to: null,
    });
  };

  return (
    <CustomModal
      open={isModalOpen?.todo}
      centered
      footer={null}
      bodyStyle={{ padding: 0 }}
      closeIcon={null}
      onCancel={() =>
        setIsModalOpen((prev) => ({
          ...prev,
          todo: false,
        }))
      }
    >
      <div className={styles.modalView}>
        {/* header */}
        <div className={styles.header}>
          <img
            src={require('../../assets/bg_login_adv.jpg')}
            alt="모달 헤더 배경"
            style={{ width: '100%', height: 100 }}
          />
          <div className={styles.headerLabel}>
            <span>할일 입력</span>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsModalOpen((prev) => ({
                  ...prev,
                  todo: false,
                }));
              }}
            >
              <img
                src={require('../../assets/icon/icon_close.png')}
                alt="모달 닫기 이미지"
                style={{ width: 25, height: 25 }}
              />
            </button>
          </div>
        </div>

        {/* form */}
        <div className={styles.content}>
          {/* 시간 */}
          <div className={styles.section}>
            <span className={styles.sectionTitle}>시간</span>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <Select
                  style={{ width: 70 }}
                  options={[
                    { key: 'AM', label: '오전', value: 'am' },
                    { key: 'PM', label: '오후', value: 'pm' },
                  ]}
                />
                <Select style={{ width: 70, marginLeft: 10 }} />
                <span style={{ marginRight: 10 }}> 시</span>
                <Select style={{ width: 70 }} /> 분
              </div>
              <Radio>하루종일</Radio>
            </div>
          </div>
          {/* 세부공종 */}
          <div className={styles.section} style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.sectionTitle}>세부공종</span>
            <Select />
          </div>
          {/* 제목 */}
          <div className={styles.section}>
            <span className={styles.sectionTitle}>제목</span>
            <Input
              value={form?.title}
              onChange={(e) => {
                setForm(
                  produce((draft) => {
                    draft.title = e.target.value;
                  }),
                );
              }}
            />
          </div>
          {/* 내용 */}
          <div className={styles.section}>
            <span className={styles.sectionTitle}>내용</span>
            <TextArea
              value={form?.content}
              style={{ resize: 'none', height: 120 }}
              onChange={(e) => {
                setForm(
                  produce((draft) => {
                    draft.content = e.target.value;
                  }),
                );
              }}
            />
          </div>
          {/* 알람설정 */}
          <div className={styles.section}>
            <span className={styles.sectionTitle}>알람설정</span>
            <div className={styles.switchContainer}>
              <span>푸시알람</span>
              <div>
                <span>ON </span>
                <Switch />
              </div>
            </div>
            <div className={styles.switchContainer}>
              <span>사전알람</span>

              <div>
                <span>ON </span>
                <Switch />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
              <Input style={{ width: 130 }} />

              <div>
                <Select style={{ width: 130 }} />
                <span> 전</span>
              </div>
            </div>
            <div style={{ marginBottom: 35 }}>
              <Radio.Group style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Radio>나에게 알람</Radio>
                <Radio>고객에게 알람</Radio>
                <Radio>작업팀에 알람</Radio>
              </Radio.Group>
            </div>
            <div style={{ textAlign: 'center' }}>
              <button className={styles.saveBtn} onClick={handleSave}>
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default TodoModal;
