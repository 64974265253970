import React from 'react';
import { Button, DatePicker, Descriptions, Divider, Modal, Space, Table, Typography } from 'antd';
import moment from 'moment';
import { comma } from '../../../../common/utils';

const COLUMNS = [
  {
    title: '',
    width: 200,
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (value) => '고객요청',
  },
  {
    title: '예상견적가',
    width: 200,
    dataIndex: 'total_price',
    key: 'total_price',
    align: 'center',
    render: (value) => <b>{`${comma(value)}만원`}</b>,
  },
  {
    title: '등록일',
    width: 200,
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center',
    render: (value) => moment().format('YYYY.MM.DD'),
  },
  {
    title: '',
    width: 200,
    dataIndex: 'detail',
    key: 'detail',
    align: 'center',
    render: (value) => (
      <Button
        onClick={() => {
          Modal.info({
            title: '요청 견적',
            content: '예상 견적가 0000만원',
          });
        }}
      >
        상세
      </Button>
    ),
  },
];

const Info = ({ data }) => {
  if (!data) return <React.Fragment />;
  return (
    <>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="고객">{data?.customer?.name}</Descriptions.Item>
        <Descriptions.Item label="주소">{data?.address}</Descriptions.Item>
        <Descriptions.Item label="공사 예정일">
          <Space align="center" size="large">
            <DatePicker defaultValue={moment()} style={{ width: 200 }} />
            <Typography.Text strong>{`D - 1`}</Typography.Text>
          </Space>
        </Descriptions.Item>
      </Descriptions>

      <Table columns={COLUMNS} dataSource={[data]} pagination={false} style={{ width: 600, marginTop: 20 }} />

      <Divider />
    </>
  );
};

export default Info;
