import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledSingleUploader = styled(Upload)`
  height: 100%;
  .ant-upload-picture-card-wrapper {
    width: 100%;
  }

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;

const SingleUploader = ({ buttonStyle }) => {
  const uploadButton = (
    <div style={buttonStyle}>
      <PlusOutlined listType="picture-card" style={{ fontSize: 100 }} />
    </div>
  );

  return (
    <StyledSingleUploader
      // listType="picture-card"
      showUploadList={false}
    >
      {uploadButton}
    </StyledSingleUploader>
  );
};

export default SingleUploader;
