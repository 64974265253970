import React from 'react';
import { Avatar, Button, Checkbox, Form, Image, Input, List, Modal, Segmented, Table, Typography } from 'antd';
import styles from './style.module.less';
// Utils
import { comma } from '../../common/utils';
import { ESTIMATE_AREA_DATA, ESTIMATE_TYPE_DATA, ESTIMATE_TYPE_DETAIL_DATA } from '../../mocks';

// const area_columns = [
//   {
//     title: '구분',
//     width: 100,
//     dataIndex: 'type',
//     key: 'type',
//     fixed: 'left',
//     align: 'center',
//   },
//   {
//     title: '명칭',
//     width: 150,
//     dataIndex: 'name',
//     key: 'name',
//     fixed: 'left',
//     align: 'center',
//   },
//   {
//     title: '자재명',
//     width: 250,
//     dataIndex: 'material_name',
//     key: 'material_name',
//     fixed: 'left',
//     align: 'center',
//     render: () => {
//       return (
//         <>
//           <span>무궁화 페인트</span>
//           <Button style={{ marginLeft: 80 }}>변경</Button>
//         </>
//       );
//     },
//   },
//   {
//     title: '단위',
//     width: 100,
//     dataIndex: 'unit',
//     key: 'unit',
//     fixed: 'left',
//     align: 'center',
//   },
//   {
//     title: '계약견적가',
//     children: [
//       {
//         title: '단가',
//         width: 150,
//         dataIndex: 'price',
//         key: 'price',
//         align: 'center',
//         render: (value) => comma(value),
//       },
//       {
//         title: '금액',
//         width: 150,
//         dataIndex: 'amount',
//         key: 'amount',
//         align: 'center',
//         render: (value) => comma(value),
//       },
//     ],
//   },
//   {
//     title: '실행금액',
//     children: [
//       {
//         title: '단가',
//         width: 150,
//         dataIndex: 'sale_price',
//         key: 'sale_price',
//         align: 'center',
//         render: (value) => comma(value),
//       },
//       {
//         title: '금액',
//         width: 150,
//         dataIndex: 'sale_amount',
//         key: 'sale_amount',
//         align: 'center',
//         render: (value) => comma(value),
//       },
//     ],
//   },
//   {
//     title: '집행금액',
//     children: [
//       {
//         title: '자재',
//         width: 150,
//         dataIndex: 'deal_amount',
//         key: 'deal_amount',
//         align: 'center',
//         render: (value) => comma(value),
//       },
//     ],
//   },
// ];

const Estimate = ({ children }) => {
  const [tab, setTab] = React.useState('type');
  const [selected, setSelected] = React.useState();
  const [isopen, setIsOpen] = React.useState();
  const { Paragraph, Title, Text } = Typography;
  const type_columns = [
    {
      title: '구분',
      width: 100,
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '명칭',
      width: 150,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '자재명',
      width: 250,
      dataIndex: 'material_name',
      key: 'material_name',
      fixed: 'left',
      align: 'center',
      render: () => {
        return (
          <>
            <span>무궁화 페인트</span>
            <Button
              type="primary"
              onClick={() => {
                setIsOpen(true);
              }}
              style={{ marginLeft: 80 }}
            >
              변경
            </Button>
          </>
        );
      },
    },
    {
      title: '단위',
      width: 100,
      dataIndex: 'unit',
      key: 'unit',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '수량',
      width: 100,
      dataIndex: 'quantity',
      key: 'quantity',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '표준견적서(소비자)',
      children: [
        {
          title: '단가',
          width: 150,
          dataIndex: 'price',
          key: 'price',
          align: 'center',
          render: (value) => comma(value),
        },
        {
          title: '금액',
          width: 150,
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
          render: (value) => comma(value),
        },
      ],
    },
    {
      title: '실 금액',
      children: [
        {
          title: '단가',
          width: 150,
          dataIndex: 'sale_price',
          key: 'sale_price',
          align: 'center',
          render: (value) => comma(value),
        },
        {
          title: '금액',
          width: 150,
          dataIndex: 'sale_amount',
          key: 'sale_amount',
          align: 'center',
          render: (value) => comma(value),
        },
      ],
    },
    {
      title: '차액',
      width: 150,
      dataIndex: 'diff',
      key: 'diff',
      align: 'center',
      render: (value) => comma(value),
    },
    {
      title: '소비자 제안 금액',
      children: [
        {
          title: '금액',
          width: 150,
          dataIndex: 'deal_amount',
          key: 'deal_amount',
          align: 'center',
          render: () => <Input />,
        },
      ],
    },
    {
      title: '차액',
      width: 150,
      dataIndex: 'deal_diff',
      key: 'deal_diff',
      align: 'center',
      render: (value) => comma(value),
    },
  ];
  const area_columns = [
    {
      title: '구분',
      width: 100,
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '명칭',
      width: 150,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '자재명',
      width: 250,
      dataIndex: 'material_name',
      key: 'material_name',
      fixed: 'left',
      align: 'center',
      render: () => {
        return (
          <>
            <span>무궁화 페인트</span>
            <Button
              type="primary"
              onClick={() => {
                setIsOpen(true);
              }}
              style={{ marginLeft: 80 }}
            >
              변경
            </Button>
          </>
        );
      },
    },
    {
      title: '단위',
      width: 100,
      dataIndex: 'unit',
      key: 'unit',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '수량',
      width: 100,
      dataIndex: 'quantity',
      key: 'quantity',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '표준견적서(소비자)',
      children: [
        {
          title: '단가',
          width: 150,
          dataIndex: 'price',
          key: 'price',
          align: 'center',
          render: (value) => comma(value),
        },
        {
          title: '금액',
          width: 150,
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
          render: (value) => comma(value),
        },
      ],
    },
    {
      title: '실 금액',
      children: [
        {
          title: '단가',
          width: 150,
          dataIndex: 'sale_price',
          key: 'sale_price',
          align: 'center',
          render: (value) => comma(value),
        },
        {
          title: '금액',
          width: 150,
          dataIndex: 'sale_amount',
          key: 'sale_amount',
          align: 'center',
          render: (value) => comma(value),
        },
      ],
    },
    {
      title: '차액',
      width: 150,
      dataIndex: 'diff',
      key: 'diff',
      align: 'center',
      render: (value) => comma(value),
    },
    {
      title: '소비자 제안 금액',
      children: [
        {
          title: '금액',
          width: 150,
          dataIndex: 'deal_amount',
          key: 'deal_amount',
          align: 'center',
          render: () => <Input />,
        },
      ],
    },
    {
      title: '차액',
      width: 150,
      dataIndex: 'deal_diff',
      key: 'deal_diff',
      align: 'center',
      render: (value) => comma(value),
    },
  ];
  return (
    <div className={styles.EstimateJSX}>
      <div className={styles.left}>
        <Segmented
          options={[
            { label: '공종별 보기', value: 'type' },
            { label: '공간별 보기', value: 'area' },
          ]}
          onChange={setTab}
        />
        <List
          className={styles.list}
          dataSource={tab === 'type' ? ESTIMATE_TYPE_DATA : ESTIMATE_AREA_DATA}
          renderItem={(item) => {
            return (
              <List.Item
                onClick={() => setSelected(item)}
                style={selected === item ? { backgroundColor: '#C8C8C8' } : {}}
              >
                <div className={styles['left-list-item']}>
                  <div className={styles.top}>
                    <Typography.Text>{item?.title}</Typography.Text>
                    {tab === 'area' && (
                      <>
                        <Typography.Text>{item?.size ? `${item?.size} ㎡` : '-'}</Typography.Text>
                      </>
                    )}
                  </div>
                  <Typography.Text strong={selected === item} className={styles.price}>
                    {item?.price ? `${comma(item.price)} 원` : '-'}
                  </Typography.Text>
                </div>
              </List.Item>
            );
          }}
        />
      </div>
      <Modal
        title="상품선택하기"
        open={isopen}
        onCancel={() => {
          setIsOpen(false);
        }}
        width={1500}
        footer={[
          <Button key="submit" className={styles.btnResizing}>
            선택하기
          </Button>,
        ]}
      >
        <Form
          className={styles.formCotainer}
          name="basic"
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className={styles.flex}>
            <div className={styles['width-70']}>
              <div>
                <Form.Item
                  className={styles.inputwrap}
                  label="카테고리"
                  name="category"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please input your username!',
                  //   },
                  // ]}
                >
                  <div className={styles.flex}>
                    <Checkbox />
                    <Paragraph className={styles.categoryBox}>
                      <span>철물</span> {`>`} <span>경첩</span>
                    </Paragraph>
                  </div>
                </Form.Item>
                <div className={styles.flex}>
                  <Form.Item
                    className={styles['width-50']}
                    label="유통사"
                    name="distributor"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Please input your password!',
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className={styles['width-50']}
                    label="검색어"
                    name="keyword"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Please input your password!',
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>

              <div className={styles.flex}>
                <List>
                  <List.Item className={styles['fiex-column-align-start']}>
                    <div>
                      <Image
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPNYaeEOduco9L83GVB-dNHSjn2thHsxk0QQ&usqp=CAU"
                        alt=""
                        width={200}
                      />
                    </div>
                    <div>
                      <Title className={styles['item-title-font']} level={2}>
                        이지경첩(골드)
                      </Title>
                      <Paragraph>하나금속철물</Paragraph>
                      <Paragraph>힐림 방문경첩</Paragraph>
                      <Paragraph>
                        업체가: {comma(4400)}원 / 소비자: {comma(6000)}원
                      </Paragraph>
                    </div>
                  </List.Item>
                </List>
              </div>
            </div>
            <div className={styles['width-30']}>
              <Text>기존 상품</Text>
              <List
                itemLayout="horizontal"
                // dataSource={data}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={`https://joesch.moe/api/v1/random?key=${index}`} />}
                      title={<a href="https://ant.design">{item.title}</a>}
                      description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                  </List.Item>
                )}
              />
              <Text>선택한 상품</Text>
              <List
                itemLayout="horizontal"
                // dataSource={data}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={`https://joesch.moe/api/v1/random?key=${index}`} />}
                      title={<a href="https://ant.design">{item.title}</a>}
                      description={
                        'Ant Design, a design language for background applications, is refined by Ant UED Team '
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Form>
      </Modal>
      <div className={styles.table}>
        {children}
        {tab === 'type' ? (
          <Table columns={type_columns} dataSource={ESTIMATE_TYPE_DETAIL_DATA} pagination={false} />
        ) : (
          <Table columns={area_columns} dataSource={ESTIMATE_TYPE_DETAIL_DATA} pagination={false} />
        )}
      </div>
    </div>
  );
};

export default Estimate;
