import React from 'react';
import { Button, Card, Divider, Input, Modal, PageHeader, Pagination, Select, Table, Typography } from 'antd';
import SearchForm from '../../../../../../components/datalist/SearchForm';
import { ORDER_SEARCH_FIELDS } from '../../../../../../constants/searchFields';
import useOrder from './useOrder';
import NewOrder from './new';
import styles from './style.module.less';
import moment from 'moment';
import Meta from 'antd/lib/card/Meta';
import { comma } from '../../../../../../common/utils';

const Order = () => {
  const { rowSelection, goToNewOrder, isNewOrder, submitNewOrder } = useOrder();
  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  const [isReturnModalOpen, setIsReturnModalOpen] = React.useState(false);

  const handleAddModalCencel = () => {
    setIsAddModalOpen(false);
  };
  const handleReturnModalCencel = () => {
    setIsReturnModalOpen(false);
  };

  const CONSTRUCTION_ORDER_DATA = [
    {
      order_num: '220520-0001',
      order_date: '2022.02.12',
      order_status: '결제필요',
      company_status: '수락(수동)',
      product_name: '대리석 타일',
      company_name: '유통사 A',
      count: 5,
      price: 10000,
      sum: 50000,
      status: '자채배송',
      due_date: '2022.02.13',
    },
    {
      order_num: '',
      order_date: '2022.02.12',
      order_status: '결제필요',
      company_status: '배송완료',
      product_name: '',
      company_name: '유통사 A',
      count: 3,
      price: 10000,
      sum: '',
      status: '',
      due_date: '',
    },
  ];

  const columns = [
    {
      title: '발주 번호',
      key: 'order_num',
      dataIndex: 'order_num',
      width: 100,
      align: 'center',
      fixed: 'left',
    },
    {
      title: '발주 일자',
      key: 'order_date',
      dataIndex: 'order_date',
      align: 'center',
      render: (value) => value && moment(value).format('YYYY.MM.DD'),
      width: 150,
      fixed: 'left',
    },
    {
      title: '발주 상태',
      dataIndex: 'order_status',
      key: 'order_status',
      width: 100,
      align: 'center',
      fixed: 'left',
    },
    {
      title: '업체상태',
      key: 'company_status',
      dataIndex: 'company_status',
      width: 100,
      align: 'center',
    },
    {
      title: '제품명',
      dataIndex: 'product_name',
      key: 'product_name',
      align: 'center',
    },
    {
      title: '업체명',
      key: 'company_name',
      dataIndex: 'company_name',
      width: 100,
      align: 'center',
    },
    {
      title: '수량',
      key: 'count',
      dataIndex: 'count',
      width: 50,
      align: 'center',
    },
    {
      title: '단가(VAT별도)',
      key: 'price',
      dataIndex: 'price',
      width: 100,
      align: 'center',
      render: (value) => comma(value),
    },
    {
      title: '합계(VAT별도)',
      width: 100,
      dataIndex: 'sum',
      key: 'sum',
      align: 'center',
      render: (value) => comma(value),
    },
    {
      title: '배송정보',
      key: 'status',
      dataIndex: 'status',
      width: 100,
      align: 'center',
    },
    {
      title: '배송요청일',
      key: 'due_date',
      dataIndex: 'due_date',
      width: 150,
      align: 'center',
      render: (value) => value && moment(value).format('YYYY.MM.DD'),
    },
    {
      title: '',
      key: 'detail',
      dataIndex: 'id',
      width: 200,
      align: 'center',
      render: () => {
        return (
          <>
            <Button onClick={() => setIsAddModalOpen(true)} type="primary">
              추가주문
            </Button>
            <Button onClick={() => setIsReturnModalOpen(true)} style={{ marginLeft: 10 }} type="primary">
              반품
            </Button>
          </>
        );
      },
    },
  ];

  const { Text } = Typography;
  return (
    <Card>
      <PageHeader title="발주신청 내역" />
      {/* <div className={styles.orderBtnWrap}>
        <Button type="primary" className={styles.orderBtn}>
          견적 불러온 뒤 상품 발주 신청
        </Button>
      </div> */}

      {isNewOrder ? (
        <NewOrder onSubmit={submitNewOrder} />
      ) : (
        <>
          <SearchForm fields={ORDER_SEARCH_FIELDS} />
          <Divider />
          <div className={styles.btnWrap}>
            <Button>선택항목 엑셀다운</Button>
            <Button>전체 엑셀다운</Button>
            <Select
              defaultValue="10개"
              // onChange={handleChange}
              options={[
                {
                  value: '10개',
                  label: '10개',
                },
                {
                  value: '30개',
                  label: '30개',
                },
                {
                  value: '50개',
                  label: '50개',
                },
              ]}
            />
            <Select
              defaultValue="발주일자 최신순"
              // onChange={handleChange}
              options={[
                {
                  value: '배송요청일 최신순',
                  label: '배송요청일 최신순',
                },
                {
                  value: '발주일자 최신순',
                  label: '발주일자 최신순',
                },
              ]}
            />
          </div>
          <Table
            dataSource={CONSTRUCTION_ORDER_DATA}
            columns={columns}
            rowSelection={rowSelection}
            pagination={false}
          />
          <Modal
            open={isAddModalOpen}
            onCancel={handleAddModalCencel}
            title={'추가주문'}
            footer={<Button type="primary">발주요청</Button>}
          >
            <Card>
              <div className={styles.cardItemBox}>
                <div>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnPyNQ9wZbKy5oF533NX5wbLnX2Stcni6ZyA&usqp=CAU"
                    alt="페인트"
                  />
                </div>
                <div>
                  <Meta title="제비표 페인트" description={`단가 : ${comma(10000)}`} />
                </div>
              </div>
            </Card>
            <div className={styles.addOrderBox}>
              <p>추가 주문수량</p>
              <Input />
              <p>개</p>
            </div>
            <div className={styles.payment}>
              <p>결제금액</p>
              <p>{comma(100000)} 원</p>
            </div>
            <Card className={styles.deliveryInfo}>
              <div>
                <Button type="primary">현장</Button>
                <Button>사무실</Button>
              </div>
              <div>
                <Text>배송장소 : 서울 강서구 현대아파트 102동 202호</Text>
                <div>
                  <Text>배송메모 :</Text>
                  <Input.TextArea />
                </div>
              </div>
            </Card>
          </Modal>
          <Modal
            open={isReturnModalOpen}
            onCancel={handleReturnModalCencel}
            title={'반품'}
            footer={<Button type="primary">반품요청</Button>}
          >
            <Card>
              <div className={styles.cardItemBox}>
                <div>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnPyNQ9wZbKy5oF533NX5wbLnX2Stcni6ZyA&usqp=CAU"
                    alt="페인트"
                  />
                </div>
                <div>
                  <Meta title="제비표 페인트" description={`단가 : ${comma(10000)}`} />
                </div>
              </div>
            </Card>
            <div className={styles.addOrderBox}>
              <p>반품할 수량</p>
              <Input />
              <p>개</p>
            </div>
          </Modal>
          <div className={styles.footer}>
            <div className={styles.pagecetner}>
              <Pagination defaultCurrent={1} total={50} />
            </div>
            <div className={styles.buttonwrap}>
              <Button type="primary" size="large">
                선택결제
              </Button>
              <Button type="primary" size="large" onClick={goToNewOrder}>
                추가발주
              </Button>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default Order;
