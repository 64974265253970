import React, { useMemo, useState } from 'react';
import styles from './style.module.less';
import { Button, Input, Modal, Radio } from 'antd';
import {
  calcPriceWithOption,
  cleanSelected,
  comma,
  getPriceItems,
  getRealSelected,
  parsePrintField,
} from '../../common/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import produce from 'immer';
import { isEqual } from 'lodash';
import { useProductFinder } from '../ProductFinder';

const DiffTable = ({
  usrSelected,
  usrPrices,
  comSelected,
  setComSelected,
  items,
  hideExtra,
  filter,
  dimensions,
  label,
}) => {
  const { estimateTpls, estimateOptions, tplCategories } = useSelector((s) => s.common, shallowEqual);

  const { showProduct, products } = useProductFinder();

  const comRealSelected = useMemo(() => {
    return getRealSelected(estimateTpls, cleanSelected(estimateTpls, comSelected), tplCategories);
  }, [estimateTpls, comSelected]);

  const usrRealSelected = useMemo(() => {
    const selected = cleanSelected(estimateTpls, usrSelected);
    const addBlanks = comSelected
      ?.filter((x) => x.custom)
      ?.map((v) => ({
        key: v.key,
        main_category_id: v.main_category_id,
        side_category_id: v.side_category_id,
        tid: v.tid,
        custom: true,
        dummy: true,
      }));

    return getRealSelected(estimateTpls, (selected ?? []).concat(addBlanks), tplCategories);
  }, [comRealSelected, usrSelected]);

  const usrTotalPrice = useMemo(() => {
    return Object.values(usrPrices ?? {}).reduce((x, y) => x + y, 0);
  }, [usrPrices]);

  const [comPrices, comTotalPrice] = useMemo(() => {
    const p = getPriceItems({
      estimateTpls,
      dimensions,
      selected: comSelected,
      realSelected: comRealSelected,
      products,
    });
    return [p, Object.values(p).reduce((x, y) => x + y, 0)];
  }, [comSelected, comRealSelected, estimateTpls, dimensions, products]);

  const handleRemove = (item) => {
    const ix = comSelected?.findIndex((x) => x.tid === item.tid && x.key === item.key);
    if (ix >= 0) {
      setComSelected(
        produce(comSelected, (draft) => {
          if (draft[ix]?.custom) {
            draft.splice(ix, 1);
          } else {
            draft[ix].disabled = true;
          }
        }),
      );
    }
  };

  const [addModal, setAddModal] = useState({});
  const [txtModal, setTxtModal] = useState({});

  const handleReset = () => {
    if (!window.confirm('초기화 하시겠습니까?')) return;
    setComSelected([...usrSelected]);
  };

  const handleAddRow = (item, allows, allowTpls) => {
    setAddModal({
      item,
      allows,
      allowTpls,
      show: true,
      tid: -1,
      side_category_id: 0,
    });
  };

  const handleModalSubmit = () => {
    if (!addModal.tid) {
      alert('카테고리를 선택해주세요.');
      return;
    }

    const { area_id, main_category_id } = addModal?.item;
    let newComSelected = [...comSelected];

    const tpl = estimateTpls?.find((x) => x.id === addModal.tid);
    if (tpl?.select_option?.excludes) {
      const ids = tpl?.select_option?.excludes?.reduce((a, b) => a.concat(b.ids), [])?.filter((x) => !!x);

      const targets = comSelected?.filter((x) => x.key === area_id && ids.includes(x.tid));

      const targetTpls = targets
        ?.map((x) => '- ' + estimateTpls?.find((v) => v.id === x.tid)?.name)
        ?.filter((x) => !!x);
      if (targetTpls.length > 0) {
        const msg =
          `이미 선택된 항목중 해당 요소가 삭제될 수 있습니다.\n진행하시겠습니까?\n\n` + targetTpls?.join('\n');
        if (!window.confirm(msg)) return;

        newComSelected = produce(comSelected, (draft) => {
          const ixs = targets?.map((x) => comSelected.indexOf(x));
          for (const ix of ixs) {
            if (comSelected[ix].custom) {
              draft[ix] = undefined;
            } else {
              draft[ix].disabled = true;
            }
          }
        })?.filter((x) => !!x);
      }
    }

    newComSelected = produce(newComSelected, (draft) => {
      const alreadys = newComSelected?.findIndex((x) => x.key === area_id && x.tid === addModal?.tid && x.disabled);
      if (alreadys >= 0) {
        draft[alreadys].disabled = false;
      } else {
        draft.push({
          extra_add_price: 0,
          extra_price: 0,
          key: area_id,
          main_category_id,
          price: 0,
          print_field: '1',
          side_category_id: addModal.side_category_id ?? -1,
          tid: addModal.tid,
          custom: true,
        });
      }
    });
    setComSelected(newComSelected);
    setAddModal((x) => ({ ...x, show: false }));
  };

  const allowTpls = addModal?.allowTpls?.filter((x) => x.side_category?.id === addModal?.side_category_id);
  return (
    <div style={{}}>
      <Modal
        title={'비고'}
        open={!!txtModal?.show}
        onCancel={() => setTxtModal((x) => ({ ...x, show: false }))}
        onOk={() => {
          setComSelected(
            produce(comSelected, (draft) => {
              draft[txtModal.ix].memo = txtModal.text;
            }),
          );
          setTxtModal({});
        }}
      >
        <Input.TextArea
          rows={15}
          value={txtModal.text}
          onChange={(e) => {
            const value = e.target.value;
            setTxtModal((x) => ({ ...x, text: value }));
          }}
        />
      </Modal>
      <Modal
        title={'중분류 추가'}
        open={addModal?.show}
        onCancel={() => setAddModal((x) => ({ ...x, show: false }))}
        onOk={handleModalSubmit}
      >
        <div style={{ paddingBottom: 10 }}>
          <Radio
            onClick={() => setAddModal((x) => ({ ...x, tid: -1, side_category_id: undefined }))}
            checked={addModal?.tid < 0}
          >
            임의 자재추가
          </Radio>
        </div>
        {addModal?.allows?.map((item) => {
          return (
            <div style={{ paddingBottom: 10 }} key={`category_${item.id}`}>
              <div>
                <Radio
                  checked={addModal?.side_category_id == item.id}
                  onClick={() => setAddModal((x) => ({ ...x, side_category_id: item.id, tid: 0 }))}
                >
                  {item.name}
                </Radio>
              </div>
              {addModal?.side_category_id === item.id && (
                <>
                  {allowTpls?.map((tpl) => (
                    <div key={`tid_${tpl.id}`} style={{ paddingLeft: 20 }}>
                      <Radio
                        onClick={() => setAddModal((x) => ({ ...x, tid: tpl?.id }))}
                        checked={addModal?.tid === tpl.id}
                      >
                        {tpl.name}
                      </Radio>
                    </div>
                  ))}
                </>
              )}
            </div>
          );
        })}
      </Modal>
      <div style={{ overflowX: 'scroll', height: 'calc(100vh - 300px)' }}>
        <table className={styles.table} style={comSelected !== undefined ? {} : { minWidth: '100px' }}>
          <colgroup>
            <col width={70} />
            <col />
            <col width={5} />
            {comSelected !== undefined && (
              <>
                <col />
                <col width={90} />
                <col width={70} />
              </>
            )}
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>소비자견적</th>
              <th>&nbsp;</th>
              {comSelected !== undefined && (
                <>
                  <th>제안견적</th>
                  <th>차액</th>
                  <th>비고</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {items?.map((item, ix) => {
              const tids = estimateTpls?.filter(filter)?.map((x) => x.id);
              const { area_id, area_type, main_category_id } = item;

              const urs = usrRealSelected
                ?.filter((x) => !!x)
                ?.filter(item?.filter || (() => true))
                ?.filter((x) => tids.includes(x.tid) || (x.custom && x.main_category_id === main_category_id));
              const crs = comRealSelected
                ?.filter((x) => !!x)
                ?.filter(item?.filter || (() => true))
                ?.filter((x) => tids.includes(x.tid) || (x.tid < 0 && x.main_category_id === main_category_id));

              const crsTids = crs?.filter((x) => !x.disabled)?.map((x) => x.tid);

              let totalPrice = 0;
              let totalPrice2 = 0;

              const categories = tplCategories?.find((x) => x.id === main_category_id).children;

              const cids = categories?.map((x) => x.id);
              const addAllowTpls = estimateTpls?.filter(
                (x) =>
                  cids.includes(x.side_category?.id) &&
                  x.selectable &&
                  x.element_types?.includes(area_type) &&
                  !crsTids.includes(x.id),
              );

              const sideCategoryIds = addAllowTpls?.map((x) => x.side_category?.id);
              const addAllowCategories = categories?.filter((x) => sideCategoryIds.includes(x.id));

              return (
                <React.Fragment key={`${item.id}`}>
                  <tr>
                    <td style={{ position: 'relative', borderBottom: '1px solid #333' }} rowSpan={2}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                          position: 'absolute',
                          top: 0,
                        }}
                      >
                        <table>
                          {ix === 0 && (
                            <thead>
                              <tr>
                                <th style={{ position: 'relative', borderBottom: '1px solid #333' }}></th>
                              </tr>
                            </thead>
                          )}
                        </table>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {item.name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <table>
                        <colgroup>
                          <col width={100} />
                          <col />
                          <col />
                          <col width={70} />
                          {!hideExtra && (
                            <>
                              <col width={60} />
                              <col width={60} />
                              <col width={70} />
                            </>
                          )}
                          <col width={80} />
                        </colgroup>
                        {ix === 0 && (
                          <thead>
                            <tr>
                              <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>중분류</th>
                              <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>소분류</th>
                              <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>자재명</th>
                              <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>계정구분</th>
                              {!hideExtra && (
                                <>
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>단위</th>
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>수량</th>
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>단가</th>
                                </>
                              )}
                              <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>금액</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {urs?.length === 0 && (
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}
                          {urs?.map((sitem, ix) => {
                            const dimensions = item.getDimen();
                            const tpl = estimateTpls?.find((x) => x.id === sitem.tid);

                            let basePrice = tpl?.price;
                            let productInfos = [];
                            let field = 1;
                            const nowPrice =
                              usrPrices?.[
                                `${sitem.key}.${sitem.main_category_id}.${sitem.side_category_id}.${sitem.tid}`
                              ] ?? 0;
                            if (tpl) {
                              field = parsePrintField(tpl.print_field, dimensions);
                              if (tpl.use_product) {
                                const { products: ups } = sitem;
                                productInfos = ups?.map((x) => products[x.product_id])?.filter((x) => !!x);
                                basePrice = productInfos?.map((x) => x.user_price).reduce((a, b) => a + b, 0);
                                field = isNaN(field)
                                  ? ups?.reduce((a, b) => a + b.count, 0)
                                  : Math.max(
                                      ups?.reduce((a, b) => a + b.count, 0),
                                      field,
                                    );
                              } else {
                                field = parsePrintField(tpl.print_field, dimensions);
                              }

                              totalPrice += nowPrice;
                            }

                            const isBeforeEq =
                              ix === 0 ? false : urs?.[ix - 1]?.side_category_id == sitem.side_category_id;

                            let rowSpan = 1;
                            if (!isBeforeEq) {
                              for (let i = ix + 1; i < urs.length; i++) {
                                if (urs?.[i]?.side_category_id !== sitem.side_category_id) break;
                                rowSpan++;
                              }
                            }

                            if (sitem.custom) {
                              return (
                                <tr key={`lrow_${ix}`}>
                                  {!isBeforeEq && <th rowSpan={rowSpan}></th>}
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  {!hideExtra && (
                                    <>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </>
                                  )}
                                  <td></td>
                                </tr>
                              );
                            }
                            return (
                              <tr key={`lrow_${ix}`}>
                                {!isBeforeEq && <th rowSpan={rowSpan}>{tpl.side_category?.name}</th>}
                                <td>{tpl.name}</td>
                                {tpl?.use_product && productInfos?.length > 0 ? (
                                  <td className={styles.tc}>{productInfos?.map((x) => x.name)?.join(',')}</td>
                                ) : (
                                  <td className={styles.tc}>-</td>
                                )}
                                <td className={styles.tc}>{tpl?.use_product ? '자재비' : '인건비'}</td>
                                {!hideExtra && (
                                  <>
                                    <td className={styles.tc}>{tpl.print_mode}</td>
                                    <td className={styles.tr}>{field?.toFixed?.(2)}</td>
                                    <td className={styles.tr}>{comma(Math.round(nowPrice / field ?? 0))}</td>
                                  </>
                                )}
                                <td className={styles.tr}>{comma(nowPrice)}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table>
                        {ix === 0 && (
                          <thead>
                            <tr>
                              <th style={{ position: 'relative', borderBottom: '1px solid #333' }}></th>
                            </tr>
                          </thead>
                        )}
                      </table>
                    </td>
                    {comSelected !== undefined && (
                      <>
                        <td style={{ borderLeft: '1px solid #ccc' }}>
                          <table>
                            <colgroup>
                              <col width={100} />
                              <col />
                              <col />
                              <col width={70} />
                              {!hideExtra && (
                                <>
                                  <col width={60} />
                                  <col width={60} />
                                  <col width={70} />
                                </>
                              )}
                              <col width={80} />
                              <col width={70} />
                            </colgroup>
                            {ix === 0 && (
                              <thead>
                                <tr>
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>중분류</th>
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>소분류</th>
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>자재명</th>
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>계정구분</th>
                                  {!hideExtra && (
                                    <>
                                      <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>단위</th>
                                      <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>수량</th>
                                      <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>단가</th>
                                    </>
                                  )}
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>금액</th>
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}>중분류추가</th>
                                </tr>
                              </thead>
                            )}
                            <tbody>
                              {crs?.length === 0 && (
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className={styles.tc}>
                                    <Button.Group>
                                      <Button
                                        size={'small'}
                                        onClick={() =>
                                          handleAddRow(
                                            {
                                              area_id,
                                              area_type,
                                              main_category_id,
                                            },
                                            addAllowCategories,
                                            addAllowTpls,
                                          )
                                        }
                                      >
                                        <PlusOutlined size={5} />
                                      </Button>
                                    </Button.Group>
                                  </td>
                                </tr>
                              )}

                              {crs?.map((sitem, ix) => {
                                const dimensions = item.getDimen();
                                if (sitem.tid < 0) {
                                  totalPrice2 += sitem.price;
                                  return (
                                    <tr key={`rrow_${ix}`}>
                                      <th>임의추가</th>
                                      <td>
                                        <Input
                                          size={'small'}
                                          defaultValue={sitem.title}
                                          onBlur={(e) => {
                                            const value = e.target.value;
                                            const ix1 = comSelected.indexOf(crs[ix]);
                                            setComSelected(
                                              produce(comSelected, (draft) => {
                                                draft[ix1].title = value;
                                              }),
                                            );
                                          }}
                                        />
                                      </td>
                                      <td></td>
                                      <td>
                                        <select
                                          value={sitem.use_product ? '1' : '2'}
                                          onChange={(e) => {
                                            const value = e.target.value === '1';
                                            const ix1 = comSelected.indexOf(crs[ix]);
                                            setComSelected(
                                              produce(comSelected, (draft) => {
                                                draft[ix1].use_product = value;
                                              }),
                                            );
                                          }}
                                        >
                                          <option value={'1'}>자재비</option>
                                          <option value={'2'}>인건비</option>
                                        </select>
                                      </td>
                                      {!hideExtra && (
                                        <>
                                          <td className={styles.tc}>LOT</td>
                                          <td className={styles.tc}>1</td>
                                          <td className={styles.tr}>{comma(sitem.price)}</td>
                                        </>
                                      )}
                                      <td>
                                        <Input
                                          size={'small'}
                                          style={{ textAlign: 'right' }}
                                          defaultValue={sitem.price}
                                          onBlur={(e) => {
                                            const value = parseInt(
                                              e.target.value?.replace(/[^\-0-9]+/g, '') || '0',
                                              10,
                                            );
                                            const ix1 = comSelected.indexOf(crs[ix]);
                                            setComSelected(
                                              produce(comSelected, (draft) => {
                                                draft[ix1].price = value;
                                              }),
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className={styles.tc}>
                                        <Button.Group>
                                          <Button
                                            size={'small'}
                                            onClick={() =>
                                              handleAddRow(
                                                {
                                                  area_id,
                                                  area_type,
                                                  main_category_id,
                                                },
                                                addAllowCategories,
                                                addAllowTpls,
                                              )
                                            }
                                          >
                                            <PlusOutlined size={5} />
                                          </Button>
                                          <Button size={'small'} onClick={() => handleRemove(sitem)}>
                                            <MinusOutlined size={5} />
                                          </Button>
                                        </Button.Group>
                                      </td>
                                    </tr>
                                  );
                                }

                                const tpl = estimateTpls?.find((x) => x.id === sitem.tid);

                                let basePrice = tpl.price;
                                let productInfos = [];
                                let field = 1;

                                const nowPrice =
                                  comPrices[
                                    `${sitem.key}.${sitem.main_category_id}.${sitem.side_category_id}.${sitem.tid}`
                                  ] ?? 0;

                                if (tpl.use_product) {
                                  const { products: ups } = sitem;
                                  productInfos = ups?.map((x) => products[x.product_id])?.filter((x) => !!x);
                                  basePrice = productInfos?.map((x) => x.user_price).reduce((a, b) => a + b, 0);

                                  field = isNaN(field)
                                    ? ups?.reduce((a, b) => a + b.count, 0)
                                    : Math.max(
                                        ups?.reduce((a, b) => a + b.count, 0),
                                        field,
                                      );
                                } else {
                                  field = parsePrintField(tpl.print_field, dimensions);
                                }

                                console.log(sitem, nowPrice);

                                if (!sitem?.disabled) {
                                  totalPrice2 += nowPrice ?? basePrice;
                                }

                                const isBeforeEq =
                                  ix === 0 ? false : crs?.[ix - 1]?.side_category_id == sitem.side_category_id;

                                let rowSpan = 1;
                                if (!isBeforeEq) {
                                  for (let i = ix + 1; i < crs.length; i++) {
                                    if (crs?.[i]?.side_category_id !== sitem.side_category_id) break;
                                    rowSpan++;
                                  }
                                }

                                const changed = !isEqual(urs[ix], sitem);

                                return (
                                  <tr key={`rrow_${ix}`} className={changed ? styles.changeRow : ''}>
                                    {!isBeforeEq && <th rowSpan={rowSpan}>{tpl.side_category?.name}</th>}
                                    {sitem?.disabled ? (
                                      <>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {!hideExtra && (
                                          <>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </>
                                        )}
                                        <td></td>
                                        <td className={styles.tc}>
                                          {!tpl.selectable ? undefined : (
                                            <Button.Group>
                                              <Button
                                                size={'small'}
                                                onClick={() =>
                                                  handleAddRow(
                                                    {
                                                      area_id,
                                                      area_type,
                                                      main_category_id,
                                                    },
                                                    addAllowCategories,
                                                    addAllowTpls,
                                                  )
                                                }
                                              >
                                                <PlusOutlined size={5} />
                                              </Button>
                                            </Button.Group>
                                          )}
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td>{tpl.name}</td>
                                        {tpl?.use_product && tpl?.selectable ? (
                                          <td>
                                            <Button
                                              size={'small'}
                                              block
                                              style={{ fontSize: 12, color: '#777', textAlign: 'center' }}
                                              onClick={() => {
                                                const ix = comSelected.indexOf(sitem);
                                                if (ix >= 0) {
                                                  showProduct(tpl, sitem.products, async (values) => {
                                                    setComSelected(
                                                      produce((draft) => {
                                                        draft[ix].products = values;
                                                      }),
                                                    );
                                                  });
                                                } else {
                                                  alert('not found selected..');
                                                }
                                              }}
                                            >
                                              {productInfos?.map((x) => x.name)?.join(',') ?? '(상품선택)'}
                                            </Button>
                                          </td>
                                        ) : (
                                          <td className={styles.tc}>-</td>
                                        )}
                                        <td className={styles.tc}>{tpl?.use_product ? '자재비' : '인건비'}</td>
                                        {!hideExtra && (
                                          <>
                                            <td className={styles.tc}>{tpl.print_mode}</td>
                                            <td className={styles.tr}>{field?.toFixed?.(2)}</td>
                                            <td className={styles.tr}>{comma(Math.round(nowPrice / field) ?? 0)}</td>
                                          </>
                                        )}
                                        <td className={styles.tr}>{comma(nowPrice)}</td>
                                        <td className={styles.tc}>
                                          {!tpl.selectable ? undefined : (
                                            <Button.Group>
                                              <Button
                                                size={'small'}
                                                onClick={() =>
                                                  handleAddRow(
                                                    {
                                                      area_id,
                                                      area_type,
                                                      main_category_id,
                                                    },
                                                    addAllowCategories,
                                                    addAllowTpls,
                                                  )
                                                }
                                              >
                                                <PlusOutlined size={5} />
                                              </Button>
                                              <Button size={'small'} onClick={() => handleRemove(sitem)}>
                                                <MinusOutlined size={5} />
                                              </Button>
                                            </Button.Group>
                                          )}
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                        <td>
                          <table>
                            {ix === 0 && (
                              <thead>
                                <tr>
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}></th>
                                </tr>
                              </thead>
                            )}
                            <tbody>
                              {crs?.map((sitem, ix) => {
                                const dimensions = item.getDimen();

                                const ur = urs[ix];

                                const utpl = estimateTpls?.find((x) => x.id === ur?.tid);
                                let uBasePrice = ur?.custom ? 0 : utpl?.price ?? 0;
                                let ufield = 1;
                                let uprice = uBasePrice;
                                if (utpl) {
                                  if (utpl.use_product) {
                                    const { products: ups } = ur;
                                    uBasePrice = ups
                                      ?.map((x) => products[x.product_id])
                                      ?.filter((x) => !!x)
                                      ?.map((x) => x.user_price)
                                      .reduce((a, b) => a + b, 0);
                                    ufield = ups?.reduce((a, b) => a + b.count, 0);
                                  } else {
                                    ufield = parsePrintField(utpl.print_field, dimensions);
                                  }
                                  uprice = Math.floor(uBasePrice * ufield + (utpl.extra_price ?? 0));
                                }

                                const ctpl = estimateTpls?.find((x) => x.id === sitem.tid);
                                let cBasePrice = ctpl?.price ?? sitem?.price ?? 0;
                                let cfield = 1;
                                let cprice = cBasePrice;
                                if (ctpl) {
                                  if (ctpl.use_product) {
                                    const { products: ups } = sitem;
                                    cBasePrice = ups
                                      ?.map((x) => products[x.product_id])
                                      ?.filter((x) => !!x)
                                      ?.map((x) => x.user_price)
                                      .reduce((a, b) => a + b, 0);
                                    cfield = ups?.reduce((a, b) => a + b.count, 0);
                                  } else {
                                    cfield = parsePrintField(ctpl.print_field, dimensions);
                                  }
                                  cprice = sitem.disabled
                                    ? 0
                                    : Math.floor(cBasePrice * cfield + (ctpl.extra_price ?? 0));
                                }

                                return (
                                  <tr key={`${ix}`}>
                                    <td className={styles.tr}>{comma(cprice - uprice)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                        <td>
                          <table>
                            {ix === 0 && (
                              <thead>
                                <tr>
                                  <th style={{ position: 'relative', borderBottom: '1px solid #333' }}></th>
                                </tr>
                              </thead>
                            )}
                            <tbody>
                              {crs?.map((sitem, ix) => {
                                const tpl = estimateTpls?.find((x) => x.id === sitem?.tid);
                                return (
                                  <tr key={`${ix}`}>
                                    <td className={styles.tc}>
                                      {tpl && !tpl?.selectable ? (
                                        '-'
                                      ) : (
                                        <Button
                                          size={'small'}
                                          block
                                          type={sitem?.memo ? 'primary' : undefined}
                                          style={{ fontSize: 12 }}
                                          onClick={() =>
                                            setTxtModal({
                                              show: true,
                                              ix: comSelected.indexOf(sitem),
                                              text: sitem?.memo,
                                            })
                                          }
                                        >
                                          비고
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td style={{ position: 'relative', borderBottom: '1px solid #333', height: 40 }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          height: 40,
                          padding: '0px 5px',
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>{item.name} 소계</span>
                        <span style={{ fontWeight: 'bold' }}>{comma(totalPrice)}</span>
                      </div>
                    </td>
                    <td style={{ position: 'relative', borderBottom: '1px solid #333', height: 40 }}></td>

                    {comSelected !== undefined && (
                      <>
                        <td style={{ position: 'relative', borderBottom: '1px solid #333', height: 40 }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              height: 40,
                              padding: '0px 5px',
                              paddingRight: 75,
                            }}
                          >
                            <span style={{ fontWeight: 'bold' }}></span>
                            <span style={{ fontWeight: 'bold' }}>{comma(totalPrice2)}</span>
                          </div>
                        </td>
                        <td
                          style={{
                            position: 'relative',
                            borderBottom: '1px solid #333',
                            verticalAlign: 'middle',
                            textAlign: 'right',
                            padding: '0px 5px',
                            fontWeight: 'bold',
                          }}
                        >
                          {comma(totalPrice2 - totalPrice)}
                        </td>
                        <td style={{ position: 'relative', borderBottom: '1px solid #333' }}></td>
                      </>
                    )}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        style={{
          position: 'fixed',
          background: '#fff',
          height: 50,
          bottom: 0,
          width: 'calc(100% - 240px)',
          left: 240,
          zIndex: 50,
          boxShadow: '0px 0px 5px rgba(0,0,0,0.5)',
          padding: '0px 20px',
        }}
      >
        <div style={{ height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ width: 70 }}></div>
          <div style={{ flex: 1, textAlign: 'right' }}>
            <div>
              <span style={{ fontSize: 12 }}>소비자 견적</span>
              <b style={{ fontSize: 26, marginLeft: 20 }}>
                {comma(calcPriceWithOption(usrTotalPrice, estimateOptions))}
              </b>
            </div>
          </div>
          {comSelected !== undefined && (
            <>
              <div style={{ flex: 1, textAlign: 'right' }}>
                <div>
                  <span style={{ fontSize: 12 }}>제안 견적</span>
                  <b style={{ fontSize: 26, marginLeft: 20 }}>
                    {comma(calcPriceWithOption(comTotalPrice, estimateOptions))}
                  </b>
                </div>
              </div>
              <div style={{ textAlign: 'right', width: 172, fontSize: 20, color: '#db2929' }}>
                {comma(comTotalPrice - usrTotalPrice)}
              </div>
              <div style={{ width: 72, textAlign: 'center ' }}>
                {!label && (
                  <Button onClick={() => handleReset()} size={'small'}>
                    초기화
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiffTable;
