import React from 'react';
import { comma, parseImageUri } from '../../common/utils';
import { SearchOutlined } from '@ant-design/icons';

const ProductListItem = ({ item, imageFocus, onImageClick, textFocus, onTextClick }) => {
  return (
    <div>
      <div style={{ position: 'relative', padding: 2, border: imageFocus ? '2px solid #db2929' : '2px solid #eee' }}>
        <img src={parseImageUri(item.image, 400, 300)} style={{ width: '100%' }} onClick={!textFocus && onTextClick} />
        <div
          style={{
            position: 'absolute',
            right: 2,
            top: 2,
            background: 'rgba(0,0,0,0.5)',
            height: 30,
            width: 30,
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={onImageClick}
        >
          <SearchOutlined color={'#fff'} style={{ color: '#fff', lineHeight: '33px' }} />
        </div>
        {textFocus && (
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background: 'rgba(0,0,0,0.5)',
              textAlign: 'center',
              color: '#fff',
              fontWeight: 'bold',
              padding: 10,
            }}
          >
            선택한 상품
          </div>
        )}
      </div>
      <button
        style={{ textAlign: 'left', border: '0px', background: 'transparent' }}
        onClick={!onTextClick ? undefined : onTextClick}
      >
        <b style={{ fontSize: 14, display: 'block', margin: '7px 0px 2px', color: '#333' }}>{item.name}</b>
        <small style={{ fontSize: 12, display: 'block', margin: '0px 0px 10px' }}>{item.organization?.name}</small>
        <p style={{ marginBottom: 5 }}>
          {[item.maker_name, item.brand_name, item.collection_name].filter((v) => !!v).join(' ')}
        </p>
        <p>
          업체가: {comma(item.corp_price)}원 / 소비자: {comma(item.user_price)}원
        </p>
      </button>
    </div>
  );
};

export default ProductListItem;
