import React from 'react';
import dayjs from 'dayjs';

import { comma } from '../../../common/utils';

import { ESTIMATE_APPLICATION_DATA } from '../../../mocks';

export const estimate_application_columns = [
  {
    title: '번호',
    dataIndex: 'id',
    key: 'id',
    width: 100,
    align: 'center',
    sorter: true,
  },
  {
    title: '상태',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    align: 'center',
    sorter: true,
  },
  {
    title: '주택명',
    dataIndex: 'apt_name',
    key: 'apt_name',
    width: 200,
    align: 'center',
    sorter: true,
  },
  // {
  //   title: '평수',
  //   dataIndex: 'area_size',
  //   key: 'area_size',
  //   width: 100,
  //   align: 'center',
  // },
  // {
  //   title: '주소',
  //   dataIndex: 'address',
  //   key: 'address',
  // },
  {
    title: '고객정보',
    dataIndex: 'consumer',
    key: 'consumer',
    width: 300,
    align: 'center',
    render: (value) => `${value.name} / ${value.phone}`,
    sorter: true,
  },
  {
    title: '고객견적가',
    dataIndex: 'customer_price',
    key: 'customer_price',
    width: 200,
    align: 'center',
    render: (value) => `${comma(value)}`,
    sorter: true,
  },
  {
    title: '최초제안가',
    dataIndex: 'first_price',
    key: 'first_price',
    width: 120,
    align: 'center',
    render: (value) => `${comma(value)}`,
    sorter: true,
  },
  {
    title: '최종제안가',
    dataIndex: 'final_price',
    key: 'final_price',
    width: 200,
    align: 'center',
    render: (value) => `${comma(value)}`,
    sorter: true,
  },
  {
    title: '상담요청일시',
    dataIndex: 'updated_at',
    key: 'updated_at',
    width: 200,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },
  {
    title: '최종제안서 제출일',
    dataIndex: 'submit_date',
    key: 'submit_date',
    width: 300,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },
  {
    title: '고객조회일시',
    dataIndex: 'view_day',
    key: 'view_day',
    width: 300,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
    sorter: true,
  },
  // {
  //   title: '',
  //   dataIndex: 'id',
  //   key: 'detail',
  //   width: 100,
  //   align: 'center',
  // },
];

const useContract = () => {
  const [data, setData] = React.useState(ESTIMATE_APPLICATION_DATA);

  // estimate_contract_columns[9].render = (value) => (
  //   <div style={{ display: 'flex', gap: '10px' }}>
  //     <Button type="primary">견적보기</Button>
  //     <Button type="primary">공사보기</Button>
  //   </div>
  // );

  return {
    data,
  };
};

export default useContract;
