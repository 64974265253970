import React from 'react';
import { PageHeader } from 'antd';

const SalesCallIndex = (props) => {
  return (
    <div>
      <PageHeader className="site-page-header" title="발주 목록" subTitle="발주 관리" />
    </div>
  );
};

export default SalesCallIndex;
