import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';

const useQuery = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const initializeSearchOpt = React.useCallback(
    (initObj) => {
      navigate(`?${qs.stringify(initObj)}`, { replace: true });
    },
    [navigate],
  );

  // 초기에 설정되어야 하는 쿼리스트링이 있다면 설정해주는 함수
  const initQueryFn = React.useCallback(
    (initObj) => {
      if (!(Object.keys(query).length > 0)) {
        initializeSearchOpt(initObj);
      }
    },
    [query, initializeSearchOpt],
  );

  return {
    location,
    navigate,
    query,
    params,
    initQueryFn,
    initializeSearchOpt,
  };
};

export default useQuery;
