import React, { useEffect, useState } from 'react';
import './App.less';
import BasicLayout from './nav/BasicLayout';
import store from './data';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { me } from './data/auth';
import { getEstimateTpls, getProductCategories } from './data/common';

const Container = React.memo(() => {
  const dispatch = useDispatch();
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      await dispatch(me());
      await Promise.all([dispatch(getEstimateTpls()), dispatch(getProductCategories())]);
    };

    loadData()
      .catch(console.warn)
      .then(() => setLoading(false));
  }, []);

  return <BasicLayout loading={loading} />;
});

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Container />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
