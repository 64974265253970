import React, { useMemo, useState } from 'react';
import { Button, Form, Input, Modal, notification, Radio, Result, Steps, Upload } from 'antd';
import { PlusOutlined, ShopOutlined, ToolOutlined } from '@ant-design/icons';
import { authApi, getAPIHost } from '../../apis';
import { Postcode } from '@actbase/react-daum-postcode';

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

const JoinForm = ({ onExit }) => {
  const [showPostcode, setShowPostcode] = useState(false);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    type: undefined,

    name: '',
    tax_no: '',
    tax_img: 0,
    tax_ceo: '',
    zipcode: '',
    address: '',
    address_details: '',

    image: 0,
    description: '',
    homepage: '',
    main_phone: '',

    signname: '',
    password: '',
    password_confirm: '',
    manager_name: '',
    manager_email: '',
  });

  const nextDisabled = useMemo(() => {
    if (step === 0) {
      return !data.type;
    } else if (step === 1) {
      return !data.name || !data.tax_no || !data.tax_img || !data.tax_ceo || !data.address;
    } else if (step === 2) {
      return !data.image || !data.main_phone;
    } else if (step === 3) {
      return (
        !data.signname ||
        !data.password ||
        data.password !== data.password_confirm ||
        !data.manager_name ||
        !data.manager_email
      );
    }

    return true;
  }, [step, data]);

  const [loading, setLoading] = useState(false);
  const handleNext = async () => {
    if (nextDisabled) return;
    try {
      if (step === 3) {
        setLoading(true);
        await authApi.post(`/organizations`, {
          ...data,
          tax_no: data?.tax_no?.replace(/[^0-9]+/g, ''),
          main_phone: data?.main_phone?.replace(/[^0-9]+/g, ''),
        });
      }
      setStep((x) => x + 1);
    } catch (e) {
      notification.error({
        message: '가입신청',
        description: e?.response?.data?.message ?? '서버와 연결이 올바르지 않습니다.',
      });
    }
    setLoading(false);
  };

  const [fileList1, setFileList1] = useState();
  const [fileList2, setFileList2] = useState();

  return (
    <div>
      <Modal
        open={showPostcode}
        title={'주소찾기'}
        bodyStyle={{ padding: 0, margin: 0 }}
        width={500}
        footer={false}
        onCancel={() => setShowPostcode(false)}
      >
        <Postcode
          style={{ width: '100%' }}
          onSelected={(d) => {
            setData((x) => ({
              ...x,
              zipcode: d.zonecode,
              address: d.roadAddress,
            }));
            setShowPostcode(false);
          }}
        />
      </Modal>
      <Steps current={step} size={'small'}>
        <Steps.Step title="종류" />
        <Steps.Step title="사업자" />
        <Steps.Step title="소개" />
        <Steps.Step title="담당자" />
        <Steps.Step title="완료" />
      </Steps>
      <div style={{ margin: step === 4 ? '50px 0px 0px' : '50px 0px 30px' }}>
        <Form onFinish={handleNext}>
          {step === 0 ? (
            <div style={{ textAlign: 'center' }}>
              <Radio.Group
                style={{ width: '80%', minWidth: '300px', margin: '0px auto', height: 'auto' }}
                value={data?.type}
                onChange={(e) => {
                  setData((x) => ({ ...x, type: e.target.value }));
                }}
              >
                <Radio.Button style={{ width: '50%', height: 'auto', textAlign: 'center', padding: 20 }} value={'W'}>
                  <ToolOutlined style={{ fontSize: 40 }} />
                  <div style={{ margin: '10px 0px 0px', fontSize: 16, lineHeight: '16px' }}>시공사</div>
                  <small style={{ margin: 0, fontSize: 12, lineHeight: '11px' }}>공사를 진행하는 업체</small>
                </Radio.Button>
                <Radio.Button style={{ width: '50%', height: 'auto', textAlign: 'center', padding: 20 }} value={'P'}>
                  <ShopOutlined style={{ fontSize: 40 }} />
                  <div style={{ margin: '10px 0px 0px', fontSize: 16, lineHeight: '16px' }}>제품제공사</div>
                  <small style={{ margin: 0, fontSize: 12, lineHeight: '11px' }}>판매를 위한 유통사</small>
                </Radio.Button>
              </Radio.Group>
            </div>
          ) : step === 1 ? (
            <div>
              <Form.Item label={'업체명'} required>
                <Input
                  value={data.name}
                  onChange={(e) => {
                    setData((x) => ({ ...x, name: e.target.value }));
                  }}
                />
              </Form.Item>
              <Form.Item label={'대표자명'} required>
                <Input
                  value={data.tax_ceo}
                  onChange={(e) => {
                    setData((x) => ({ ...x, tax_ceo: e.target.value }));
                  }}
                />
              </Form.Item>
              <Form.Item label={'사업자번호'} required>
                <Input
                  value={data.tax_no}
                  onChange={(e) => {
                    setData((x) => ({ ...x, tax_no: e.target.value }));
                  }}
                />
              </Form.Item>
              <Form.Item label={'사업자등록증'} required style={{ minHeight: 120 }}>
                <Upload
                  action={getAPIHost() + '/files'}
                  method={'PUT'}
                  name={'filedata'}
                  maxCount={1}
                  listType="picture-card"
                  fileList={fileList1}
                  onChange={({ fileList }) => {
                    setFileList1(fileList);
                    if (!fileList?.length) {
                      setData((x) => ({ ...x, tax_img: 0 }));
                    } else if (fileList[0].status === 'done') {
                      setData((x) => ({ ...x, tax_img: fileList[0].response.id }));
                    }
                  }}
                >
                  {data.tax_img ? null : uploadButton}
                </Upload>
              </Form.Item>
              <Form.Item label={'주소'} required>
                <div style={{ marginBottom: 5 }}>
                  <Input
                    value={data.address}
                    readOnly
                    onClick={() => {
                      setShowPostcode(true);
                    }}
                  />
                </div>
                <div>
                  <Input
                    value={data.address_details}
                    onChange={(e) => {
                      setData((x) => ({ ...x, address_details: e.target.value }));
                    }}
                  />
                </div>
              </Form.Item>
            </div>
          ) : step === 2 ? (
            <div>
              <Form.Item label={'업체이미지'} required style={{ minHeight: 120 }}>
                <Upload
                  action={getAPIHost() + '/files'}
                  method={'PUT'}
                  name={'filedata'}
                  maxCount={1}
                  listType="picture-card"
                  fileList={fileList2}
                  onChange={({ fileList }) => {
                    setFileList2(fileList);
                    if (!fileList?.length) {
                      setData((x) => ({ ...x, image: 0 }));
                    } else if (fileList[0].status === 'done') {
                      setData((x) => ({ ...x, image: fileList[0].response.id }));
                    }
                  }}
                >
                  {data.image ? null : uploadButton}
                </Upload>
              </Form.Item>
              <Form.Item label={'업체설명'}>
                <Input.TextArea
                  value={data.description}
                  onChange={(e) => {
                    setData((x) => ({ ...x, description: e.target.value }));
                  }}
                />
              </Form.Item>
              <Form.Item label={'홈페이지'}>
                <Input
                  value={data.homepage}
                  onChange={(e) => {
                    setData((x) => ({ ...x, homepage: e.target.value }));
                  }}
                />
              </Form.Item>
              <Form.Item label={'연락처'} required>
                <Input
                  value={data.main_phone}
                  onChange={(e) => {
                    setData((x) => ({ ...x, main_phone: e.target.value }));
                  }}
                />
              </Form.Item>
            </div>
          ) : step === 3 ? (
            <div>
              <Form.Item label={'로그인 아이디'} required>
                <Input
                  value={data.signname}
                  onChange={(e) => {
                    setData((x) => ({ ...x, signname: e.target.value }));
                  }}
                />
              </Form.Item>
              <Form.Item label={'비밀번호'} required>
                <Input.Password
                  value={data.password}
                  onChange={(e) => {
                    setData((x) => ({ ...x, password: e.target.value }));
                  }}
                />
              </Form.Item>
              <Form.Item label={'비밀번호 확인'} required>
                <Input.Password
                  value={data.password_confirm}
                  onChange={(e) => {
                    setData((x) => ({ ...x, password_confirm: e.target.value }));
                  }}
                />
              </Form.Item>
              <Form.Item label={'이름'} required>
                <Input
                  value={data.manager_name}
                  onChange={(e) => {
                    setData((x) => ({ ...x, manager_name: e.target.value }));
                  }}
                />
              </Form.Item>
              <Form.Item label={'이메일'} required>
                <Input
                  value={data.manager_email}
                  onChange={(e) => {
                    setData((x) => ({ ...x, manager_email: e.target.value }));
                  }}
                />
              </Form.Item>
            </div>
          ) : step === 4 ? (
            <Result
              status="403"
              title="가입신청이 완료되었습니다."
              subTitle="영업일 기준 3일 이내 연락드리겠습니다."
              extra={
                <Button onClick={onExit} type="primary">
                  완료
                </Button>
              }
            />
          ) : null}
        </Form>
      </div>
      {step < 4 && (
        <div style={{ textAlign: 'right' }}>
          {step !== 0 && (
            <Button
              style={{ marginRight: 5 }}
              onClick={() => {
                setStep((x) => x - 1);
              }}
            >
              이전
            </Button>
          )}
          <Button type={'primary'} disabled={nextDisabled} loading={loading} onClick={handleNext}>
            다음
          </Button>
        </div>
      )}
    </div>
  );
};

export default JoinForm;
