import React from 'react';
import { PageHeader, Pagination, Table } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { CONSTRUCTION_SEARCH_FIELDS } from '../../../constants/searchFields';
import useList, { columns } from './useList';

const ConstructionList = () => {
  const { data } = useList();
  return (
    <div>
      <PageHeader title="공사 목록" />
      <SearchForm fields={CONSTRUCTION_SEARCH_FIELDS} />
      {/*TODO API 연동하면서 CommTable 변경*/}
      <Table columns={columns} dataSource={data} pagination={false} />
      <Pagination style={{ textAlign: 'center', marginTop: 30 }} defaultCurrent={1} total={50} />
    </div>
  );
};

export default ConstructionList;
