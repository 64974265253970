import React from 'react';

const useDetail = () => {
  const [isMemoVisible, setIsMemoVisible] = React.useState(false);

  const handleClose = React.useCallback(() => {
    setIsMemoVisible((p) => !p);
  }, []);

  return {
    isMemoVisible,
    handleClose,
  };
};

export default useDetail;
