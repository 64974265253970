import React from 'react';
import styles from './SquadMatchModal.module.less';
import { Col, Input, Modal, Row, Select, Switch, Table, Button } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

const CustomTable = styled(Table)``;

const dummy = [
  {
    id: 1,
    constructs: '철거',
    corp_name: '성진철거',
    name: '김환기',
    title: '사장',
    phone: '010-5452-5244',
    corp_num: '235-25-25422',
    area: '서울시 분당구, 서울시 강남구',
    pre_pay: '50',
    bankname: '기업',
    account_num: '378-060038-01-066',
    agency_cnt: 5,
    construct_cnt: 25,
    intro:
      '저희 성진철거를 찾아주셔서 감사합니다. 아파트, 상업현장, 학원등 모든 철거 가능합니다. 성실시동 약속드립니다.',
  },
  {
    id: 2,
    constructs: '철거',
    corp_name: '다부셔흥업',
    name: '리쳐드박',
    title: '팀장',
    phone: '010-5452-5244',
    corp_num: '235-25-25422',
    area: '서울시 분당구, 서울시 강남구',
    pre_pay: '50',
    bankname: '기업',
    account_num: '378-060038-01-066',
    agency_cnt: 5,
    construct_cnt: 25,
    intro:
      '저희 성진철거를 찾아주셔서 감사합니다. 아파트, 상업현장, 학원등 모든 철거 가능합니다. 성실시동 약속드립니다.',
  },
  {
    id: 3,
    constructs: '철거',
    corp_name: '데몰리션맨',
    name: '제갈철수',
    title: '실장',
    phone: '010-5452-5244',
    corp_num: '235-25-25422',
    area: '서울시 분당구, 서울시 강남구',
    pre_pay: '50',
    bankname: '기업',
    account_num: '378-060038-01-066',
    agency_cnt: 5,
    construct_cnt: 25,
    intro:
      '저희 성진철거를 찾아주셔서 감사합니다. 아파트, 상업현장, 학원등 모든 철거 가능합니다. 성실시동 약속드립니다.',
  },
  {
    id: 4,
    constructs: '철거',
    corp_name: '태진기업',
    name: '윤상옥',
    title: '대표',
    phone: '010-5452-5244',
    corp_num: '235-25-25422',
    area: '서울시 분당구, 서울시 강남구',
    pre_pay: '50',
    bankname: '기업',
    account_num: '378-060038-01-066',
    agency_cnt: 5,
    construct_cnt: 25,
    intro:
      '저희 성진철거를 찾아주셔서 감사합니다. 아파트, 상업현장, 학원등 모든 철거 가능합니다. 성실시동 약속드립니다.',
  },
  {
    id: 5,
    constructs: '철거',
    corp_name: '미진철거',
    name: '박채기',
    title: '본부장',
    phone: '010-5452-5244',
    corp_num: '235-25-25422',
    area: '서울시 분당구, 서울시 강남구',
    pre_pay: '50',
    bankname: '기업',
    account_num: '378-060038-01-066',
    agency_cnt: 5,
    construct_cnt: 25,
    intro:
      '저희 성진철거를 찾아주셔서 감사합니다. 아파트, 상업현장, 학원등 모든 철거 가능합니다. 성실시동 약속드립니다.',
  },
  {
    id: 6,
    constructs: '철거',
    corp_name: '형제인테리어 철거',
    name: '배철수',
    title: '사장',
    phone: '010-5452-5244',
    corp_num: '235-25-25422',
    area: '서울시 분당구, 서울시 강남구',
    pre_pay: '50',
    bankname: '기업',
    account_num: '378-060038-01-066',
    agency_cnt: 5,
    construct_cnt: 25,
    intro:
      '저희 성진철거를 찾아주셔서 감사합니다. 아파트, 상업현장, 학원등 모든 철거 가능합니다. 성실시동 약속드립니다.',
  },
  {
    id: 7,
    constructs: '철거',
    corp_name: '맨해트 철거',
    name: '이민우',
    title: '팀장',
    phone: '010-5452-5244',
    corp_num: '235-25-25422',
    area: '서울시 분당구, 서울시 강남구',
    pre_pay: '50',
    bankname: '기업',
    account_num: '378-060038-01-066',
    agency_cnt: 5,
    construct_cnt: 25,
    intro:
      '저희 성진철거를 찾아주셔서 감사합니다. 아파트, 상업현장, 학원등 모든 철거 가능합니다. 성실시동 약속드립니다.',
  },
  {
    id: 8,
    constructs: '철거',
    corp_name: '펭귄철거',
    name: '제임스최',
    title: '실장',
    phone: '010-5452-5244',
    corp_num: '235-25-25422',
    area: '서울시 분당구, 서울시 강남구',
    pre_pay: '50',
    bankname: '기업',
    account_num: '378-060038-01-066',
    agency_cnt: 5,
    construct_cnt: 25,
    intro:
      '저희 성진철거를 찾아주셔서 감사합니다. 아파트, 상업현장, 학원등 모든 철거 가능합니다. 성실시동 약속드립니다.',
  },
  {
    id: 9,
    constructs: '철거',
    corp_name: '성화상사',
    name: '김말자',
    title: '이사',
    phone: '',
    corp_num: '',
    area: '',
    pre_pay: '',
    bankname: '',
    account_num: '',
    agency_cnt: '',
    construct_cnt: '',
    intro: '',
  },
];

const COLUMNS = [
  {
    title: '공종',
    dataIndex: 'constructs',
    align: 'center',
  },
  {
    title: '업체명',
    dataIndex: 'corp_name',
    align: 'center',
  },
  {
    title: '성명',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '직함',
    dataIndex: 'title',
    align: 'center',
  },
  {
    title: '삭제',
    dataIndex: 'id',
    align: 'center',
    render: (id) => {
      return (
        <button style={{ border: 'none', backgroundColor: '#fff' }}>
          <img
            src={require('../../assets/icon/icon_trash.png')}
            alt="작업팀 삭제 버튼"
            style={{ width: 20, height: 20, cursor: 'pointer' }}
          />
        </button>
      );
    },
  },
];

const SquadMatchModal = ({ isModalOpen, setIsModalOpen, tplCategories }) => {
  const [target, setTarget] = React.useState(null);

  const worktypeOptions = React.useMemo(() => {
    return tplCategories?.map((tpl) => ({
      key: tpl?.id,
      label: tpl?.name,
      value: tpl?.name,
    }));
  }, [tplCategories]);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setTarget(record);
      },
    };
  };

  console.log({ target });

  return (
    <Modal
      open={isModalOpen?.squad}
      footer={null}
      width={'60%'}
      centered
      onCancel={() =>
        setIsModalOpen((prev) => ({
          ...prev,
          squad: false,
        }))
      }
      bodyStyle={{ backgroundColor: '#eee' }}
    >
      <div className={styles.modalView}>
        {/* 헤더 + 스위치 */}
        <div className={styles.header}>
          <span>스쿼드 매칭 시스템</span>
          <div className={styles.switchContainer}>
            <span>작업팀 선택</span>
            <Switch style={{ marginInline: 5 }} />
            <span>인재검색</span>
          </div>
        </div>

        <Row gutter={24}>
          <Col span={12}>
            <div className={styles.section}>
              <span className={styles.sectionTitle}>작업팀 선택</span>
              <div className={classNames([styles.form, styles.select])}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 16 }}>
                  <span style={{ marginRight: 20 }}>공종구분</span>
                  <Select style={{ flex: 1 }} options={worktypeOptions} />
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.switchContainer} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <span>전체작업팀</span>
                <Switch style={{ marginInline: 5 }} />
                <span>일정가능팀</span>
              </div>
              <Table
                dataSource={dummy}
                columns={COLUMNS}
                scroll={{ y: 200 }}
                pagination={false}
                style={{ backgroundColor: '#fff' }}
                onRow={onRow}
              />
            </div>
            <div className={styles.section}>
              <span className={styles.sectionTitle}>작업자 등록요청</span>
              <div className={classNames([styles.form, styles.input])}>
                <div className={styles.phone}>
                  <span>전화번호</span>
                  <Input style={{ flex: 1 }} />
                  <Button
                    style={{
                      backgroundColor: '#3d5bc1',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <span style={{ margin: 0 }}>등록요청</span>
                  </Button>
                </div>
                <div className={styles.description}>
                  <span>
                    당사 파트너 앱 설치 및 회원가입을 마친 회원에 한해 작업팀 배치 할 수 있습니다. 등록요청 후 유선
                    연락을 통해 앱 설치 및 가입 여부 확인 바랍니다. 작업팀 등록여부는 작업팀센터 > 작업팀등록 메뉴에서
                    확인할 수 있습니다.
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <span className={styles.sectionTitle}>작업팀 정보</span>
            <div
              className={styles.summaryContainer}
              style={
                !target?.id
                  ? {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }
                  : null
              }
            >
              {!!target?.id ? (
                <>
                  <div className={styles.summaryHeader}>
                    <img
                      src={require('../../assets/icon/icon_user_default.png')}
                      alt="유저 프로필 이미지"
                      style={{ width: 70, height: 70, borderRadius: '50%' }}
                    />
                    <div className={styles.summaryHeaderTitle}>
                      <span>{target?.corp_name}</span>
                      <span>{target?.name}</span>
                    </div>
                  </div>
                  <div className={styles.summaryTableWrapper}>
                    <table className={styles.summaryTable}>
                      <tr>
                        <th>공종</th>
                        <td>{target?.constructs || '-'}</td>
                      </tr>
                      <tr>
                        <th>연락처</th>
                        <td>{target?.phone || '-'}</td>
                      </tr>
                      <tr>
                        <th>주소</th>
                        <td>{target?.address || '-'}</td>
                      </tr>
                      <tr>
                        <th>사업자등록번호</th>
                        <td>
                          <span>{target?.corp_num || '-'}</span>
                          <img
                            src={require('../../assets/icon/icon_search_sample.png')}
                            alt="찾기 버튼 이미지"
                            style={{ width: 20, height: 20 }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>지역</th>
                        <td>
                          <span>{target?.area || '-'}</span>

                          <img
                            src={require('../../assets/icon/icon_search_sample.png')}
                            alt="찾기 버튼 이미지"
                            style={{ width: 20, height: 20 }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>결재조건</th>
                        <td>{`${target?.pre_pay ? `선급금${target?.pre_pay}%` : '-'} + 잔금`}</td>
                      </tr>
                      <tr>
                        <th>결재정보</th>
                        <td>
                          <span>{`${target?.bankname || '-'}${target?.account_num || '-'}`}</span>
                          <img
                            src={require('../../assets/icon/icon_search_sample.png')}
                            alt="찾기 버튼 이미지"
                            style={{ width: 20, height: 20 }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>관련시공사수</th>
                        <td>{target?.agency_cnt > 0 ? `${target?.agency_cnt}개 업체` : '-'}</td>
                      </tr>
                      <tr>
                        <th>바바공사진행횟수</th>
                        <td>{target?.construct_cnt > 0 ? `${target?.construct_cnt}회` : '-'}</td>
                      </tr>
                      <tr>
                        <th>인삿말</th>
                        <td></td>
                      </tr>
                    </table>
                    <div className={styles.introContainer}>
                      <span>{target?.intro || '-'}</span>
                    </div>
                  </div>
                  <div className={styles.btnContainer}>
                    <button>작업팀 배치</button>
                  </div>
                </>
              ) : (
                <img
                  src={require('../../assets/icon/icon_list_empty_sample.png')}
                  alt="작업팀 빈 이미지"
                  style={{ width: 200, height: 150 }}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default SquadMatchModal;
