import React, { useEffect, useMemo, useState } from 'react';
import { Button, PageHeader } from 'antd';
import { restApi } from '../apis';

const PageTitleWithProposal = ({ children, construct, ...props }) => {
  const { estimate, account } = construct ?? {};

  const [data, setData] = useState();

  useEffect(() => {
    const loadData = async () => {
      const { data: sizes } = await restApi.get(`/apartments/${estimate?.apt_id}/sizes`);
      const size = sizes.find((x) => x.id === estimate?.apt_size_id);
      setData(size);
    };
    loadData().catch(console.warn);
  }, [estimate?.apt_size_id]);

  console.log(data);

  const [roomSize, crSize] = useMemo(() => {
    const c = estimate?.calc_dimensions;
    if (!c) return [0, 0];

    const roomSize = c.master_rooms?.length + c.rooms?.length;
    const crSize = c.living_room_cr?.length + c.master_room_cr?.length + c.room_cr?.length;
    return [roomSize, crSize];
  }, [estimate?.calc_dimensions]);

  return (
    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', position: 'relative' }}>
      <PageHeader {...props} style={{ padding: 0 }} />
      <div style={{ flex: 1 }} />
      <table className={'summary_table'}>
        <tbody>
          <tr>
            <th>주택명</th>
            <td>{estimate?.title}</td>
            <th>주소</th>
            <td>{estimate?.address}</td>
            <th>면적</th>
            <td>{estimate?.area_size} PY</td>
            <th>방,화장실</th>
            <td>
              {roomSize} / {crSize}
            </td>
          </tr>
          <tr>
            <th>공사시작일</th>
            <td>-</td>
            <th>관리실연락처</th>
            {!account ? (
              <td colSpan={5}>{data?.apartment?.manager_phone}</td>
            ) : (
              <>
                <td>{data?.apartment?.manager_phone}</td>
                <th>고객정보</th>
                <td colSpan={3}>
                  {account?.nickname} ({account?.phone})
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>
      <div style={{ position: 'absolute', right: 0, top: 60 }}>
        <Button type={'primary'} size="large" style={{ width: 120 }} onClick={() => window.history.back()}>
          나가기
        </Button>
        {children}
      </div>
    </div>
  );
};

export default PageTitleWithProposal;
