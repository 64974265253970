import React from 'react';
import { comma } from '../../../../../../common/utils';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const payment_columns = [
  {
    title: '일자',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center',
    width: 200,
    render: (value) => moment(value).format('YYYY.MM.DD'),
  },
  {
    title: '구분',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 100,
  },
  {
    title: '입금/출금 처',
    dataIndex: 'from',
    key: 'from',
    align: 'center',
  },
  {
    title: '입금액',
    dataIndex: 'income',
    key: 'income',
    align: 'center',
    width: 100,
    render: (value) => `${comma(value)}`,
  },
  {
    title: '남은금액',
    dataIndex: 'remain',
    key: 'remain',
    align: 'center',
    width: 100,
    render: (value) => `${comma(value)}`,
  },
  {
    title: '비고',
    dataIndex: 'etc',
    key: 'etc',
    align: 'center',
    width: 200,
  },
  {
    title: '',
    dataIndex: 'dummy',
    key: 'dummy',
    align: 'center',
    width: 100,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'detail',
    align: 'center',
    width: 100,
    render: (_, row) => <Link to={'#'}>상세보기</Link>,
  },
];

export const used_columns = [
  {
    title: '일자',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center',
    width: 200,
    render: (value) => moment(value).format('YYYY.MM.DD'),
  },
  {
    title: '구분',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 100,
  },
  {
    title: '입금/출금 처',
    dataIndex: 'from',
    key: 'from',
    align: 'center',
  },
  {
    title: '출금액',
    dataIndex: 'outcome',
    key: 'outcome',
    align: 'center',
    width: 200,
    render: (value) => `${comma(value)}`,
  },
  {
    title: '비고',
    dataIndex: 'etc',
    key: 'etc',
    align: 'center',
    width: 200,
  },
  {
    title: '구분',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 100,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'detail',
    align: 'center',
    width: 100,
    render: (_, row) => <Link to={'#'}>상세보기</Link>,
  },
];

const useCalculate = () => {
  const [paymentData, setPaymentData] = React.useState();
  const [usedData, setUsedData] = React.useState();
  const [modal, setModal] = React.useState(undefined);

  const addPayment = React.useCallback(() => {
    setModal('payment');
  }, []);

  const addUsed = React.useCallback(() => {
    setModal('used');
  }, []);

  const closeModal = React.useCallback(() => {
    setModal(undefined);
  }, []);

  const onSubmit = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  return {
    paymentData,
    usedData,
    addPayment,
    addUsed,
    onSubmit,
    modal,
    closeModal,
  };
};

export default useCalculate;
