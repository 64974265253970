import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restApi } from '../apis/index';

export const getProductCategories = createAsyncThunk('productCategories/get', async (thunkAPI) => {
  const { data: productCategories } = await restApi.get(`/product_categories`);
  return { productCategories };
});

export const getEstimateTpls = createAsyncThunk('estimates/tpls', async () => {
  const { data: tpls } = await restApi.get(`/estimates/tpls`);
  const { data: options } = await restApi.get(`/estimate-options`);

  return { tpls, options };
});

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    productCategories: [],
    estimateTpls: undefined,
    tplCategories: [],
    counts: {},
    contructs: [],
    applications: [],
  },
  reducers: {
    setProductCategories: (state, action) => {
      state.productCategories = action.payload.productCategories;
    },
    setContructs: (state, action) => {
      state.contructs = action.payload;
      state.counts['/customers/call'] = state.contructs?.filter((x) => !x.readed)?.length;
    },
    setApplications: (state, action) => {
      state.applications = action.payload;
      state.counts['/customers/application'] = state.applications?.length ?? 0;
    },
  },
  extraReducers: {
    [getEstimateTpls.fulfilled]: (state, action) => {
      state.estimateTpls = action.payload.tpls;
      state.estimateOptions = action.payload.options;

      const tplCategories = [];
      action.payload.tpls.forEach((item) => {
        let ix = tplCategories.findIndex((x) => x.id === item.main_category.id);
        if (ix < 0) {
          ix = tplCategories.length;
          tplCategories.push(item.main_category);
          tplCategories[ix].children = [];
        }
        let ix2 = tplCategories[ix]?.children?.findIndex((x) => x.id === item.side_category.id);
        if (ix2 < 0) {
          tplCategories[ix].children?.push(item.side_category);
        }
      });
      state.tplCategories = tplCategories;
    },
    [getProductCategories.fulfilled]: (state, action) => {
      state.productCategories = action.payload.productCategories;
    },
  },
});

export const { setProductCategories, setContructs, setApplications } = commonSlice.actions;

export default commonSlice.reducer;
