import { PageHeader } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.less';

export default function ManagerApplicationIndex() {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <PageHeader title="IM 시작하기" />
      <div className={styles.contentContainer}>
        <span>골치아픈 현장관리</span>
        <br />
        <span style={{ marginBottom: 10 }}>
          이젠 <span style={{ fontweight: 'bold' }}>IM서비스</span>로 편리하게 원격으로 관리해 보세요.
        </span>

        <div className={styles.template}>
          <span style={{ fontSize: 30, fontweight: '700', marginLeft: 20 }}>서비스 시작하기</span>
          <div className={styles.navBtnContainer}>
            <div className={styles.navBtn} onClick={() => navigate('/im/application/request')}>
              <img src={require('../../../assets/icon/icon_introduction_bg.png')} alt="접수리스트 바로가기" />
              <div className={styles.navText}>
                <p>IM요청</p>
                <p>접수리스트</p>
              </div>
            </div>
            <div className={styles.navBtn} onClick={() => navigate('/im/application/direct')}>
              <img src={require('../../../assets/icon/icon_introduction_bg.png')} alt="직접시작하기" />
              <div className={styles.navText}>
                <p>직접</p>
                <p>시작하기</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // const [visible, setVisible] = React.useState(true);
  // const handleVisible = () => {
  //   setVisible(false);
  // };
  // const navigate = useNavigate();
  // return (
  //   <div>
  //     <PageHeader title="IM 서비스 신청" />
  //     <Modal
  //       open={visible}
  //       width={450}
  //       onCancel={handleVisible}
  //       title="IM 서비스 신청"
  //       footer={[
  //         <div style={{ textAlign: 'center' }}>
  //           <Button key="application" type="primary">
  //             서비스 신청
  //           </Button>
  //           <Button key="cancel" type="primary" onClick={handleVisible}>
  //             취소
  //           </Button>
  //         </div>,
  //       ]}
  //     >
  //       <Form
  //         className={styles.itemContainer}
  //         name="basic"
  //         labelCol={{
  //           span: 6,
  //         }}
  //         wrapperCol={{
  //           span: 12,
  //         }}
  //         style={{
  //           maxWidth: 600,
  //         }}
  //         initialValues={{
  //           remember: true,
  //         }}
  //         autoComplete="off"
  //       >
  //         <Form.Item
  //           label="고객명"
  //           name=""
  //           rules={[
  //             {
  //               required: true,
  //               message: '이름을 입력해주세요!',
  //             },
  //           ]}
  //         >
  //           <Input />
  //         </Form.Item>
  //         <Form.Item
  //           label="전화번호"
  //           name="number"
  //           rules={[
  //             {
  //               required: true,
  //               message: '전화번호를 입력해주세요!',
  //             },
  //           ]}
  //         >
  //           <Input />
  //         </Form.Item>
  //         <Form.Item
  //           label="계약 견적서"
  //           name=""
  //           rules={[
  //             {
  //               required: true,
  //               message: '견적서를 선택해주세요!',
  //             },
  //           ]}
  //         >
  //           <Button
  //             type="primary"
  //             style={{ width: '100%' }}
  //             onClick={() => {
  //               navigate(`/estimate/application`);
  //             }}
  //           >
  //             견적서 선택하기
  //           </Button>
  //         </Form.Item>
  //         <Form.Item
  //           label="공사시작일"
  //           name="전화번호"
  //           style={{ marginBottom: 100 }}
  //           rules={[
  //             {
  //               required: true,
  //               message: '공사시작일을 입력해주세요!',
  //             },
  //           ]}
  //         >
  //           <Input />
  //         </Form.Item>
  //       </Form>
  //     </Modal>
  //   </div>
  // );
}
