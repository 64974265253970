import { Collapse, Layout } from 'antd';
import React from 'react';
import classNames from '../../utils/classnames';
import styles from './FeedLayout.module.less';
const { Sider } = Layout;
const { Panel } = Collapse;

const siderStyle = {
  padding: 15,
  backgroundColor: '#fff',
};
const FeedText = (
  <>
    <div className={classNames(styles.leftRed, styles.leftBlue)} />
    <div style={{ display: 'flex' }}>
      <span style={{ fontSize: 12, fontWeight: 600, flex: 1 }}>이매촌 진흥아파트</span>
      <span style={{ fontSize: 12, fontWeight: 300 }}>2023-01-01 00:00</span>
    </div>
    <div style={{ textAlign: 'right', fontWeight: 600, fontSize: 16 }}>도배 오전 작업사진 미결</div>
  </>
);

export default function FeedLayout() {
  const onChange = (key) => {
    console.log(key);
  };

  return (
    <Sider style={siderStyle} width={300}>
      <p style={{ fontSize: 18 }}>실시간 피드</p>
      <Collapse onChange={onChange} className={styles.feed} bordered={false}>
        <Panel showArrow={false} header={FeedText} key="1">
          <div style={{ height: '100%', width: 8, backgroundColor: 'red', top: 0, left: 0, position: 'absolute' }} />
          <img src={require(`../../assets/icon/icon_pass_default.png`)} alt="공사일보" style={{ width: '100%' }} />
        </Panel>
      </Collapse>
    </Sider>
  );
}
