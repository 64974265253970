import React from 'react';
import { PageHeader } from 'antd';

const SalesReceiptIndex = (props) => {
  return (
    <div>
      <PageHeader className="site-page-header" title="정산 처리" subTitle="발주 관리" />
    </div>
  );
};

export default SalesReceiptIndex;
