import React, { useState } from 'react';
import styles from './BasicLayout.module.less';
import AuthAside from '../components/auth/Aside';
import useRoutes from './useRoutes';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Dropdown, Menu, Row, Spin } from 'antd';
import Login from '../components/auth/Login';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { BellOutlined, CloseOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import { setToken } from '../apis';
import { clearPrincipal } from '../data/auth';
import NotifyController from "../components/NotifyController";

const childRenderer = (page, parent) => {
  const args = {
    path: [parent, page.path].filter((v) => !!v).join('/'),
  };
  if (page.children?.length) {
    //, args.path
    args.path = args.path + '/*';
    args.children = page.children?.map((child) => childRenderer(child));
  }
  if (page.screen) {
    const Elem = page.screen;
    args.element = <Elem />;
  }
  return <Route key={page.path} {...args} />;
};

const BasicLayout = ({ children, loading }) => {
  const { counts } = useSelector((s) => s.common, shallowEqual);
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { routes, pages } = useRoutes();
  const [sideOpen, setSideOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) return;
    setToken({});
    dispatch(clearPrincipal());
  };

  const menuItems = [
    {
      label: '정보수정',
      key: 'profile',
    },
    {
      label: <a onClick={handleLogout}>로그아웃</a>,
      key: 'logout',
    },
  ];

  const gnbItems = routes?.map((route) => {
    const Icon = route.icon;
    if (!route.children?.length) {
      return {
        label: route.title,
        key: route.path,
        icon: <Icon />,
      };
    }

    const items = route?.children ?? [];
    return {
      label: route.title,
      key: route.path,
      icon: <Icon />,
      children: items.map((child) => ({
        label: (
          <>
            {child.title}
            <div style={{ right: 15, position: 'absolute', zIndex: 10, top: 0 }}>
              <Badge count={counts[child.path] ?? 0} size={'small'} />
            </div>
          </>
        ),
        key: child.path,
      })),
    };
  });

  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <div
      className={`${styles.basic_layout} ${!principal || loading ? styles.auth : ''} ${
        sideOpen ? styles.side_open : ''
      }`}
    >
      <aside>
        <div className={styles.intro_bg} />
        <div className={styles.bi}>
          <img src={require('../assets/logo.png')} />
          {!!principal && (
            <Button
              onClick={() => setSideOpen((x) => !x)}
              type="text"
              icon={<CloseOutlined style={{ color: '#fff' }} />}
            />
          )}
        </div>
        {!principal ? (
          <div className={styles.center_contents}>
            <AuthAside />
            <div className={styles.aside_footer}>
              <b>주식회사 제이에스쿠퍼</b>
              <address>경기도 성남시 분당구 서현로180번길13 서현프라자806-1</address>
              <p>고객센터 : 031. 8022. 7701</p>
            </div>
          </div>
        ) : (
          <div className={styles.aside_body}>
            <nav className={styles.menu}>
              <Menu
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                onClick={(item) => navigate(item.key)}
                style={{ width: '100%' }}
                theme={'dark'}
                mode="inline"
                items={gnbItems}
              />
            </nav>
          </div>
        )}
      </aside>

      {!principal ? (
        <div className={styles.auth_body}>{!!loading ? <Spin size={'large'} /> : <Login />}</div>
      ) : (
        <div className={styles.body}>
          <NotifyController />
          <header className={styles.bodyHeader}>
            <Row gutter={8}>
              <Col className={styles.menu_button}>
                <Button onClick={() => setSideOpen((x) => !x)} shape="circle" icon={<MenuOutlined />} />
              </Col>
              <Col flex={'1'} />
              <Col>
                <Button shape="round" icon={<BellOutlined />}>
                  알림
                </Button>
              </Col>
              <Col>
                <Dropdown menu={{ items: menuItems }} placement="bottomRight" arrow>
                  <Button shape="round" icon={<UserOutlined />}>
                    {principal.nickname}, {principal.organization?.name}
                  </Button>
                </Dropdown>
              </Col>
              <Col></Col>
            </Row>
          </header>
          <Routes>{pages?.filter((v) => !!v.screen)?.map((page) => childRenderer(page, undefined))}</Routes>
        </div>
      )}
    </div>
  );
};

export default BasicLayout;
