import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import useQuery from '../../../hooks/useQuery';
import styles from './styles.module.less';
import { ESTIMATE_DETAIL_DATA } from '../../../mocks';

const useCreate = () => {
  const [data, setData] = React.useState(ESTIMATE_DETAIL_DATA);
  const [isVisible, setIsVisible] = React.useState(false);
  const { id } = useParams();
  const { navigate } = useQuery();

  const goToList = React.useCallback(() => {
    navigate(`/estimate/detail/${id}`);
  }, [navigate, id]);

  const footer = React.useMemo(() => {
    return (
      <div className={styles.footer}>
        <Button type="primary" onClick={() => setIsVisible(false)}>
          전송하기
        </Button>
      </div>
    );
  }, []);

  const sendEstimate = React.useCallback(() => {
    setIsVisible(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  const topButtons = React.useMemo(
    () => (
      <>
        <Button onClick={goToList}>상담용 목록보기</Button>
        <Button type="primary">임시저장</Button>
        <Button type="primary" onClick={sendEstimate}>
          견적서 보내기
        </Button>
      </>
    ),
    [],
  );

  return {
    data,
    topButtons,
    isVisible,
    closeModal,
    footer,
  };
};

export default useCreate;
