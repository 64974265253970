import React from 'react';
import { Button, Col, PageHeader, Row, Table } from 'antd';
import styles from './index.module.less';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ListTable = styled(Table)`
  th.ant-table-cell {
    background-color: #000;
    color: #fff;
  }
  td.ant-table-cell {
    background-color: #fff;
  }
`;
export default function ProposalTerminate() {
  const [target, setTarget] = React.useState(null);
  const navigate = useNavigate();
  const dummy = [
    // {
    //   id: 1,
    //   type: true,
    //   pawn_name: '이매촌 진흥아파트', // 주택명
    //   construction_date: '2023-04-25', // 공사시작일
    //   deadline: '2023-04-18', // 상담종료일
    //   user_info: '김 봉 순 / 010-1234-5678', // 건축주 연락처
    //   address: '서울', //주소
    //   measerment: '81.33m/59.95m', // 면적
    //   rooms: '3/2', // 방,화장실
    //   move_date: '2023-05-25', // 입주예정일
    //   phone: '010-1234-5678', // 관리실 연락처
    // },
    // {
    //   id: 2,
    //   type: false,
    //   pawn_name: '안양 청신아파트',
    //   construction_date: '2023-05-25',
    //   deadline: '2023-04-18',
    //   user_info: '남 행 선 / 010-1234-5678',
    //   address: '인천',
    //   measerment: '10.33m/10.95m',
    //   rooms: '2/2',
    //   move_date: '2023-06-25',
    //   phone: '010-1234-5678',
    // },
    // {
    //   id: 3,
    //   type: false,
    //   pawn_name: '이매촌 진흥아파트',
    //   deadline: '2023-04-18',
    //   construction_date: '2023-04-25',
    // },
    // {
    //   id: 4,
    //   type: true,
    //   pawn_name: '이매촌 진흥아파트',
    //   deadline: '2023-04-18',
    //   construction_date: '2023-04-25',
    // },
    // {
    //   id: 5,
    //   type: true,
    //   pawn_name: '이매촌 진흥아파트',
    //   deadline: '2023-04-18',
    //   construction_date: '2023-04-25',
    // },
    // {
    //   id: 6,
    //   type: false,
    //   pawn_name: '이매촌 진흥아파트',
    //   deadline: '2023-04-18',
    //   construction_date: '2023-04-25',
    // },
    // {
    //   id: 7,
    //   type: true,
    //   pawn_name: '이매촌 진흥아파트',
    //   deadline: '2023-04-18',
    //   construction_date: '2023-04-25',
    // },
    // {
    //   id: 8,
    //   type: true,
    //   pawn_name: '이매촌 진흥아파트',
    //   deadline: '2023-04-18',
    //   construction_date: '2023-04-25',
    // },
    // {
    //   id: 1,
    //   type: false,
    //   pawn_name: '이매촌 진흥아파트',
    //   deadline: '2023-04-18',
    //   construction_date: '2023-04-25',
    // },
  ];

  const COLUMNS = [
    {
      title: '프로필',
      key: 'id',
      dataIndex: 'type',
      align: 'center',
      render: (value) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={styles.typeContainer}>
              <img
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                alt="user_img"
                style={{ width: 60, height: 60, borderRadius: 36 }}
              />
              {value && (
                <div className={styles.newTagWrapper}>
                  <span className={styles.newTag} style={{ fontSize: 8 }}>
                    NEW
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: '주택명',
      key: 'id',
      dataIndex: 'pawn_name',
      align: 'center',
    },
    {
      title: '공사시작일',
      key: 'id',
      dataIndex: 'construction_date',
      align: 'center',
      render: (value) => {
        return <span>{value}</span>;
      },
    },
    {
      title: '상담종료일',
      key: 'id',
      dataIndex: 'deadline',
      align: 'center',
      render: (value) => {
        return <span>{value}</span>;
      },
    },
  ];
  const onRow = (record) => {
    return {
      onClick: (event) => {
        setTarget(record);
      },
    };
  };
  return (
    <div>
      <PageHeader title="상담종료 리스트" />
      <Row>
        <Col span={12}>
          <ListTable dataSource={dummy} columns={COLUMNS} scroll={{ y: 546 }} onRow={onRow} />
        </Col>
        <Col span={12}>
          <div style={{ height: 600, backgroundColor: '#fff', marginLeft: 50 }}>
            <div className={styles.summaryContainer}>
              <div style={{ marginBottom: 30, display: 'flex', alignItems: 'center' }}>
                <div className={styles.typeContainer} style={{ marginRight: 40 }}>
                  <img
                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    alt="user_img"
                    style={{ width: 80, height: 80, borderRadius: 36 }}
                  />
                  {target?.type && (
                    <div className={styles.newTagWrapper}>
                      <span className={styles.newTag}>NEW</span>
                    </div>
                  )}
                </div>
                <span style={{ fontSize: 32, fontWeight: 600 }}>개봉 푸르지오</span>
              </div>
              <div style={{ marginBottom: 20 }}>
                <table className={styles.summaryTable}>
                  <tr>
                    <th>건축주 / 연락처</th>
                    <td>{target?.user_info || '-'}</td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td>{target?.address || '-'}</td>
                  </tr>
                  <tr>
                    <th>면적(분양/전용)</th>
                    <td>{target?.measerment || '-'}</td>
                  </tr>
                  <tr>
                    <th>방/화장실</th>
                    <td>{target?.rooms || '-'}</td>
                  </tr>
                  <tr>
                    <th>공사시작일</th>
                    <td>{target?.construction_date || '-'} (22일전)</td>
                  </tr>
                  <tr>
                    <th>입주예정일</th>
                    <td>{target?.move_date || '-'} (52일전)</td>
                  </tr>
                  <tr>
                    <th>관리실 연락처</th>
                    <td>{target?.phone || '-'}</td>
                  </tr>
                </table>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  style={{
                    width: 120,
                  }}
                  type="primary"
                  size="large"
                  onClick={() => {
                    if (target?.id) {
                      // 2차 상담제안 종료표시
                      navigate(`/customers/contract/${target?.id}`);
                    }
                  }}
                >
                  내용확인
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
