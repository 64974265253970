import React from 'react';
import styles from './index.module.less';
import { Button, Col, Modal, PageHeader, Row, Table } from 'antd';
import styled from 'styled-components';
import { comma } from '../../../../common/utils';

const ListTable = styled(Table)`
  th.ant-table-cell {
    background-color: #000;
    color: #fff;
    font-size: 15px;
    padding-inline: 5px;
  }

  td.ant-table-cell {
    padding-inline: 5px;
    background-color: #fff;
  }
`;

const dummy = [
  {
    id: 1,
    image: 'icon_floorplan.png',
    type: true,
    pawn_name: '이매촌 진흥아파트',
    created_at: '203-01-12 11:25',
    estimate: '203-01-23',
    construction_cheif: '김봉순',
    construction_phone: '010-4253-6547',
    price: 5238400,
    address: '서울시 구로구 남부순환로 775',
    area: '81.33m/59.95m',
    room: ' 3/22',
    construction_created_at: '2023-05-25',
    move_date: '2023-04-25',
    phone: '02-2354-1234',
  },
  {
    id: 2,
    image: '',
    type: true,
    pawn_name: '안양 청신아파트',
    created_at: '203-01-12 11:25',
    estimate: '203-01-23',
    construction_cheif: '김봉순',
    construction_phone: '010-4253-6547',
    price: 5238400,
    address: '서울시 구로구 남부순환로 775',
    area: '81.33m/59.95m',
    room: ' 3/22',
    construction_created_at: '2023-05-25',
    move_date: '2023-04-25',
    phone: '02-2354-1234',
  },
];

const COLUMNS = [
  {
    title: '프로필',
    dataIndex: 'image',
    align: 'center',
    render: (image, array) => {
      console.log({ image });
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ position: 'relative', width: 'fit-content' }}>
            <img
              src={require(`../../../../assets/icon/icon_${image ? 'floorplan' : 'user_default'}.png`)}
              alt="프로필이미지"
              style={{ width: 55, height: 55, borderRadius: '50%' }}
            />
            {!!array?.type && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: -10,
                  backgroundColor: '#3d5bc1',
                  borderRadius: 3,
                  padding: '2px 3px',
                }}
              >
                <span style={{ fontSize: 8, color: '#fff', fontWeight: '700' }}>NEW</span>
              </div>
            )}
          </div>
        </div>
      );
    },
  },
  {
    title: '주택명',
    dataIndex: 'pawn_name',
    align: 'center',
    render: (pawn_name) => {
      return <span>{pawn_name}</span>;
    },
  },
  {
    title: '요청일시',
    dataIndex: 'created_at',
    align: 'center',
    render: (created_at) => {
      return <span>{created_at}</span>;
    },
  },
  {
    title: '고객예상견적금액',
    dataIndex: 'estimate',
    align: 'center',
    render: (estimate) => {
      return <span>{estimate}</span>;
    },
  },
];

export default function ManagerApplicationRequestIndex() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [target, setTarget] = React.useState(null);

  console.log({ target });

  const onRow = (record) => {
    return {
      onClick: (e) => {
        setTarget(record);
      },
    };
  };

  return (
    <div className={styles.container}>
      <PageHeader title="IM 시작하기 > 고객IM요청리스트" />

      <Row gutter={16}>
        <Col span={12}>
          <ListTable dataSource={dummy} columns={COLUMNS} scroll={{ y: 500 }} onRow={onRow} />
        </Col>
        <Col span={12}>
          <div
            className={styles.summaryContainer}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: !!target?.id ? 'column' : 'row',
            }}
          >
            {!!target?.id ? (
              <>
                <div className={styles.summaryHeader}>
                  <div className={styles.profileWrapper}>
                    <img
                      src={require('../../../../assets/icon/icon_user_default.png')}
                      alt="유저 프로필"
                      style={{ width: 85, height: 85 }}
                    />
                    <div className={styles.newTagWrapper}>
                      <span className={styles.newTag}>NEW</span>
                    </div>
                  </div>
                  <span style={{ fontSize: 40, fontWeight: '700' }}>{target?.pawn_name}</span>
                  {/* <span style={{ marginRight: 5, position: 'relative', top: 10, fontWeight: '500' }}>
                    고객예상견적금액
                  </span> */}
                  {/* <span style={{ fontSize: 40, fontWeight: '700' }}>{`${comma(detailData?.total_price)}원`}</span> */}
                </div>
                <div className={styles.tableWrapper} style={{ marginBottom: 20 }}>
                  <table className={styles.summaryTable}>
                    <tr>
                      <th>건축주 / 연락처</th>
                      <td>{`${!!target?.construction_cheif ? target?.construction_cheif : '-'} / ${
                        !!target?.construction_phone ? target?.construction_phone : '-'
                      }`}</td>
                    </tr>
                    <tr>
                      <th>계약금액</th>
                      <td>{target?.price > 0 ? comma(target?.price) : '-'}</td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td>{!!target?.address ? target?.address : '-'}</td>
                    </tr>
                    <tr>
                      <th>면적(분양/전용)</th>
                      <td>{!!target?.area ? target?.area : '-'}</td>
                    </tr>
                    <tr>
                      <th>방/화장실</th>
                      <td>{!!target?.room ? target?.room : '-'}</td>
                    </tr>
                    <tr>
                      <th>공사시작일</th>
                      <td>{!!target?.construction_created_at ? target?.construction_created_at : '-'}</td>
                    </tr>
                    <tr>
                      <th>입주예정일</th>
                      <td>{!!target?.move_date ? target?.move_date : '-'}</td>
                    </tr>
                    <tr>
                      <th>관리실 연락처</th>
                      <td>{!!target?.phone ? target?.phone : '-'}</td>
                    </tr>
                    <tr>
                      <th>네이버 평면도 보기</th>
                      <td>네이버 평면도 보기</td>
                    </tr>
                  </table>
                </div>
                <div className={styles.btnContainer}>
                  <Button
                    style={{
                      backgroundColor: '#3d5bc1',
                      color: '#fff',
                      width: '20%',
                      height: 40,
                      marginInline: 3,

                      fontWeight: '700',
                      fontSize: 16,
                    }}
                    onClick={() => setIsModalOpen(true)}
                  >
                    IM서비스 시작
                  </Button>
                  <Button
                    style={{
                      backgroundColor: '#ff3f3f',
                      color: '#fff',
                      width: '20%',
                      height: 40,
                      marginInline: 3,
                      fontWeight: '700',
                      fontSize: 16,
                    }}
                  >
                    거절
                  </Button>
                </div>
              </>
            ) : (
              <img
                src={require('../../../../assets/icon/icon_list_empty_sample.png')}
                alt="서머리 빈 이미지"
                style={{ width: 200, height: 150 }}
              />
            )}
          </div>
          {/* <div className={styles.summaryContainer}>
            <div className={styles.summaryHeader}>
              <img
                alt="customer"
                width={100}
                src={require(`../../../../assets/icon/icon_${!!target?.id ? 'floorplan' : 'user_default'}.png`)}
                style={{ borderRadius: '50%' }}
              />
              <span className={styles.pawnName}>{!!target?.id ? target?.address : '-'}</span>
            </div>

            <div className={styles.summaryTableWrapper}>
              <table className={styles.summaryTable}>
                <tr>
                  <th>건축주 / 연락처</th>
                  <td>{`${!!target?.construction_cheif ? target?.construction_cheif : '-'} / ${
                    !!target?.construction_phone ? target?.construction_phone : '-'
                  }`}</td>
                </tr>
                <tr>
                  <th>계약금액</th>
                  <td>{target?.price > 0 ? comma(target?.price) : '-'}</td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td>{!!target?.address ? target?.address : '-'}</td>
                </tr>
                <tr>
                  <th>면적(분양/전용)</th>
                  <td>{!!target?.area ? target?.area : '-'}</td>
                </tr>
                <tr>
                  <th>방/화장실</th>
                  <td>{!!target?.room ? target?.room : '-'}</td>
                </tr>
                <tr>
                  <th>공사시작일</th>
                  <td>{!!target?.construction_created_at ? target?.construction_created_at : '-'}</td>
                </tr>
                <tr>
                  <th>입주예정일</th>
                  <td>{!!target?.move_date ? target?.move_date : '-'}</td>
                </tr>
                <tr>
                  <th>관리실 연락처</th>
                  <td>{!!target?.phone ? target?.phone : '-'}</td>
                </tr>
                <tr>
                  <th>네이버 평면도 보기</th>
                  <td>네이버 평면도 보기</td>
                </tr>
              </table>
            </div>

            <div className={styles.btnContainer}>
              <button style={{ backgroundColor: '#3d5bc1' }} onClick={() => setIsModalOpen(true)}>
                IM서비스 시작
              </button>
              <button style={{ backgroundColor: '#ff3f3f' }}>거절</button>
            </div>
          </div> */}
        </Col>
      </Row>

      <Modal
        open={isModalOpen}
        centered
        footer={null}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={500}
        bodyStyle={{ padding: 40 }}
        closable={null}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>결재</h2>
          <img
            src={require('../../../../assets/icon/icon_template_close_sample.png')}
            alt="템플릿 제거 버튼"
            style={{ width: 30, height: 30 }}
            onClick={() => setIsModalOpen(false)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ fontSize: 18, fontWeight: 300 }}>50,000포인트가 결재됩니다.</p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className={styles.pointLine}>
              <strong className={styles.pointTitle}>보유 포인트</strong>
              <span className={styles.textContent}>100,000</span>
            </div>
            <div className={styles.pointLine}>
              <strong className={styles.pointTitle}>차감 포인트</strong>
              <span className={styles.textContent}>5,000</span>
            </div>
            <div className={styles.pointLine}>
              <strong className={styles.pointTitle}>잔여 포인트</strong>
              <span className={styles.textContent}>5,000</span>
            </div>
          </div>
          <div className={styles.imgBox}>
            <img
              src={require('../../../../assets/icon/baba_pass.png')}
              style={{ width: '100%', height: 'auto' }}
              alt="baba_pass"
            />
            <div className={styles.pointBox}>
              <span style={{ fontSize: 22, fontWeight: 600 }}>300,000</span>
              <span>포인트</span>
            </div>
          </div>
          <p style={{ fontSize: 16, fontWeight: 600 }}>결재 후 제공 내용</p>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>현장관리지원</div>
            <span className={styles.infoContent}>공사일보, 작업자출퇴근관리, 작업현황관리</span>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>업무지원</div>
            <span className={styles.infoContent}>공사스케줄관리시스템, 발주관리시스템 등</span>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>고객서비스</div>
            <span className={styles.infoContent}>작업상황공유, 스케줄공유</span>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>기타서비스</div>
            <span className={styles.infoContent}>발주업무관리 서비스, 인력알선서비스(준비중)</span>
          </div>
          <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              type="primary"
              size="large"
              style={{ width: 130 }}
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              포인트 결제
            </Button>
            <Button type="danger" size="large" style={{ width: 130 }} onClick={() => setIsModalOpen(false)}>
              포인트 충전
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
