import React from 'react';
import styles from './FilterNavigation.module.less';

const ORDER_MENUS = [
  { label: '전체리스트', value: 'all', image: 'icon_all.png' },
  { label: 'NEW', value: 'new', image: 'icon_new.png' },
  { label: '마감임박', value: 'deadline', image: 'icon_clock.png' },
  { label: '관심', value: 'favorite', image: 'icon_hot.png' },
  { label: '내주변', value: 'area', image: 'icon_marker.png' },
  { label: '제안대기', value: 'waiting', image: 'icon_proposal.png' },
  { label: '제안완료', value: 'finished', image: 'icon_rocket.png' },
  { label: '마감', value: 'terminate', image: 'icon_deadline.png' },
];

const FilterNavigation = ({ values, state, setState, containerStyle }) => {
  const orderMenus = React.useMemo(() => {
    return ORDER_MENUS?.filter((v) => values?.includes(v?.value));
  }, [values]);

  return (
    <div className={styles.container} style={containerStyle}>
      {orderMenus?.map((menu, index) => {
        const active = state === menu?.value;

        return (
          <button
            key={`ORDER_MENUS_${index}`}
            className={styles.orderBtn}
            onClick={(e) => {
              e.preventDefault();
              setState(menu?.value);
            }}
          >
            <img
              src={require(`../../assets/icon/${menu?.image}`)}
              alt="목록 필터이미지"
              style={{ width: 50, height: 50, marginBottom: 10, opacity: active ? 1 : 0.5 }}
              draggable={false}
            />
            <span className={styles.menuLabel} style={{ opacity: active ? 1 : 0.5 }}>
              {menu?.label}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default FilterNavigation;
