import React from 'react';
import styles from './AnnexTable.module.less';
import { Descriptions } from 'antd';

const INFO = {
  apt_name: '주택명',
  area_size: '면적',
  address: '현장주소',
  account_info: '고객정보',
  created_at: '공사시작일',
  phone: '관리실연락처',
  room: '방',
  room_cr: '화장실',
  finished_at: '제안마감일',
};

const AnnexTable = ({ column, items }) => {
  return (
    <div className={styles.container}>
      <Descriptions column={column} bordered>
        {items?.map((item, index) => {
          return (
            <Descriptions.Item key={`ANNEX_INFO_${index}`} label={item?.label} labelStyle={{ fontWeight: '700' }}>
              {item?.value}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    </div>
  );
};

export default AnnexTable;
