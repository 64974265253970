import { getAPIHost } from '../apis';
import { uniq } from 'lodash';

export const comma = (x) => {
  if (!x) return 0;
  return (
    x
      // .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  );
};

export const parseImageUri = (id, w, h) => {
  const uri = `${getAPIHost()}/files/${id}`;
  const params = [];
  if (w) params.push(`w=${w}`);
  if (h) params.push(`h=${h}`);
  return uri + (!params?.length ? '' : `?${params.join('&')}`);
};

const expendCategory = (children) => {
  let items = [];
  children?.map((item) => {
    items.push({
      code: item.code,
      label: item.label,
    });
    if (item.children) {
      items = items.concat(expendCategory(item.children));
    }
  });
  return items;
};

export const parseCategory = (categories, value, separator = ', ') => {
  const keys = [];
  for (let i = 1; i <= Math.floor(value.length / 3); i++) {
    keys.push(value.substring(0, i * 3));
  }

  const c = categories.reduce((a, c) => {
    // a[c.code] = c;

    a[c.code] = {
      code: c.code,
      label: c.label,
    };
    if (c.children) {
      expendCategory(c.children).forEach((item) => {
        a[item.code] = {
          code: item.code,
          label: item.label,
        };
      });
    }

    return a;
  }, {});

  if (separator && typeof separator === 'string') {
    return keys?.map((v) => c[v]?.label)?.join(separator);
  } else {
    return keys.map((v, i) => (
      <>
        {c[v]?.label}
        {i !== keys?.length - 1 && separator}
      </>
    ));
  }
};

export const getRealSelected = (estimateTpls, selected, tplCategories) => {
  let o = [...(selected ?? [])];
  estimateTpls
    ?.filter((v) => !!v.parents?.length)
    ?.forEach((extraTpl) => {
      const parents = (selected ?? [])?.filter((v) => {
        if (!v) return false;
        const b1 = extraTpl?.parents?.includes(v?.tid);
        if (!extraTpl?.requireds?.length) return b1;

        const c1 = extraTpl?.requireds?.includes(v.tid);
        return b1 && c1 === !!extraTpl.required_having;
      });

      let addItems = uniq(parents?.map((v) => v.key))?.filter((v) => {
        if (!v) return false;
        const type = v?.substring(0, v.lastIndexOf('_'));
        return extraTpl?.element_types?.includes(type);
      });

      if (o?.findIndex((x) => !!x && x.tid === extraTpl.id) >= 0) return;

      o = o.concat(
        addItems.map((key) => {
          const disableds = parents?.filter((x) => x.key === key)?.map((x) => !!x.disabled);
          const disabled = disableds.every((v) => v === true); //disableds.filter((x) => !!x)?.length == disableds?.length;
          const customs = parents?.filter((x) => x.key === key)?.map((x) => !!x.custom);
          const custom = customs.every((v) => v === true); //disableds.filter((x) => !!x)?.length == disableds?.length;
          return {
            disabled,
            custom,
            extra_add_price: extraTpl?.extra_add_price ?? 0,
            extra_price: extraTpl?.extra_price ?? 0,
            key,
            main_category_id: extraTpl?.main_category?.id,
            price: extraTpl?.price ?? 0,
            print_field: extraTpl?.print_field,
            side_category_id: extraTpl?.side_category?.id,
            tid: extraTpl?.id,
          };
        }),
      );
    });

  if (tplCategories) {
    o = o?.sort((a, b) => {
      const a1 = tplCategories?.find((x) => x.id === a.main_category_id);
      const b1 = tplCategories?.find((x) => x.id === b.main_category_id);

      const a2 = a1.order_index || a1?.id;
      const b2 = b1.order_index || b1?.id;

      const a3 = a1.children?.find((x) => x.id === a.side_category_id);
      const b3 = b1.children?.find((x) => x.id === b.side_category_id);

      const a4 = (a3?.order_index || a3?.id) ?? -1;
      const b4 = (b3?.order_index || b3?.id) ?? -1;

      const a5 = estimateTpls?.find((x) => x.id === a.tid)?.order_index ?? 9000;
      const b5 = estimateTpls?.find((x) => x.id === b.tid)?.order_index ?? 9000;

      const a6 = a.custom ? 1 : 0;
      const b6 = b.custom ? 1 : 0;

      return a2 > b2
        ? -1
        : a2 < b2
        ? 1
        : a4 > b4
        ? -1
        : a4 < b4
        ? 1
        : a6 > b6
        ? 1
        : a6 < b6
        ? -1
        : a5 > b5
        ? 1
        : a5 < b5
        ? -1
        : 0;
    });
  }

  return o;
};

export const getPriceItems = ({ estimateTpls, dimensions, selected, realSelected, products }) => {
  return realSelected?.reduce((a, item) => {
    if (!item || item.disabled) return a;
    const key = `${item.key}.${item.main_category_id}.${item.side_category_id}.${item.tid}`;
    const tpl = estimateTpls?.find((v) => v.id === item.tid);

    if (!tpl) {
      console.log(item);
    }

    // console.log(dimensions)

    const area = item.key.substring(0, item.key.lastIndexOf('_'));
    const areaIx = parseInt(item.key.substring(item.key.lastIndexOf('_') + 1), 10);

    const fieldDimen = { ...dimensions?.[area]?.[areaIx] };
    if (tpl?.parents) {
      const parents = estimateTpls
        ?.filter((v) => tpl.parents?.includes(v.id) && v.use_product && v.use_product_multi)
        ?.map((v) => v.id);

      const productCount = selected
        ?.filter((v) => parents?.includes(v.tid) && v.key === item.key)
        ?.map((v) => v.products?.reduce((a, c) => a + c?.count, 0))
        ?.reduce((a, c) => a + c, 0);

      fieldDimen.product_count = productCount;
    }
    const value = !tpl ? 1 : parsePrintField(tpl.print_field, fieldDimen) ?? 1;

    let itemPrice = item?.price || tpl?.price || 0;

    if (tpl?.use_product) {
      if (item.products?.length) {
        itemPrice = item.products?.reduce((a, c) => {
          const product = products[c.product_id];
          return a + (!product ? 0 : product?.user_price * (c?.count ?? 1));
        }, 0);
      } else {
        return a;
      }
    }

    const basePrice = value * itemPrice;
    const price = Math.floor(basePrice + (tpl?.extra_price ?? 0));

    if (tpl?.extra_add_price && basePrice > 0) {
      const len = realSelected?.filter((v) => !!v && v.tid === item.tid)?.length;
      const extra_add_price = tpl.extra_add_price / len;
      a[key] = Math.ceil(price + extra_add_price);
    } else {
      a[key] = price;
    }

    return a;
  }, {});
};

export const parsePrintField = (field, dimen) => {
  let print_field = String(field);
  for (const key of [...Object.keys(dimen ?? {}), 'product_count']) {
    const r = new RegExp('\\$\\{' + key + '\\}', 'g');
    if (key === 'product_count') {
      print_field = print_field?.replace?.(r, '1') ?? 1;
    } else if (dimen[key]) {
      print_field = print_field?.replace?.(r, dimen[key]) ?? 1;
    } else {
      print_field = 1;
    }
  }

  let value = 0;
  try {
    global.SQRT = Math.sqrt;
    global.IF = (v, t, f) => (v ? t : f);
    value = eval(print_field);
  } catch (e) {}

  return value;
};

export const cleanSelected = (estimateTpls, selected) => {
  const tids = estimateTpls?.filter((x) => x.selectable)?.map((x) => x.id);
  return selected?.filter((x) => tids.includes(x.tid) || x.tid < 0);
};

export const calcPriceWithOption = (price, options) => {
  let p = price || 0;

  const basicPrice = options?.filter((x) => x.type === 'BASIC').reduce((a, b) => a + Math.ceil(p * (b.rate / 100)), 0);

  const extraPrice = options
    ?.filter((x) => x.type === 'EXTRA')
    ?.reduce((a, b) => a + Math.ceil((p + basicPrice) * (b.rate / 100)), 0);

  return p + basicPrice + extraPrice;
};
