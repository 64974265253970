import { Button, Card, Descriptions, Modal, PageHeader, Space, Switch, Typography } from 'antd';
import styles from './style.module.less';
import React, { useState } from 'react';

import EstimateTabs from '../../../../tab';
import { comma } from '../../../../../../common/utils';

export default function EstimateContreactDetails() {
  const { Text } = Typography;
  const [isOpen, setIsOpen] = useState(false);
  const { Paragraph } = Typography;
  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <div>
        <PageHeader title="상담진행" />
        <Card>
          <Descriptions bordered column={4}>
            <Descriptions.Item label={'주택명'}>개봉 푸르지오</Descriptions.Item>
            <Descriptions.Item label={'면적'}>34PY</Descriptions.Item>
            <Descriptions.Item label={'현장주소'}>서울시 구로구 남부순환로 775</Descriptions.Item>
            <Descriptions.Item label={'고객정보'}>김철수 / 010-4363-3306</Descriptions.Item>
            <Descriptions.Item label={'업체명'}>살리고 디자인</Descriptions.Item>
            <Descriptions.Item label={'담당자'}>개똥이</Descriptions.Item>
            <Descriptions.Item label={'담당자 연락처'}>010-1234-5678</Descriptions.Item>
            <Descriptions.Item label={'업체주소'}>경기도 성남시 분당구 이매동</Descriptions.Item>
          </Descriptions>
        </Card>
        <div className={styles.tabContainer}>
          <div className={styles.switchContainer}>
            <Text>공종별 보기</Text>
            <Switch />
            <Text>공간별 보기</Text>
          </div>
        </div>
        {/* <EstimateTabs /> */}
        <Card className={styles.EstimateJSX}>
          <Space className={styles.sum}>
            <Space className={styles.price}>
              <Text>총 견적 금액</Text>
              <Text>
                <strong>{comma(33950000)}</strong>원
              </Text>
            </Space>

            <Space className={styles.price}>
              <Text>바닥공사소계</Text>
              <Text>
                <strong>{comma(2120000)}</strong>원
              </Text>
            </Space>
          </Space>
          <EstimateTabs />
        </Card>
        <Space className={styles.modifyBtn}>
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            type="primary"
          >
            견적서 수정
          </Button>
        </Space>
        <Modal
          width={400}
          open={isOpen}
          title="견적서 수정"
          onCancel={handleCancel}
          footer={[
            <div style={{ textAlign: 'center' }}>
              <Button key="approval" type="primary">
                결재하기
              </Button>
              <Button key="cancel" type="primary" onClick={handleCancel}>
                거절
              </Button>
            </div>,
          ]}
        >
          <Paragraph className={styles.txtContainer}>견적서를 저장하였습니다.</Paragraph>
        </Modal>
      </div>
    </div>
  );
}
