import React from 'react';
import { Button, Card, Form, message, Modal, PageHeader, Popconfirm, Radio, Table } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import useEstimateDetail, { columns } from './useEstimateDetail';
import Info from './Info';
import Memo from '../../../components/memo';

import styles from './detail.module.less';
import useQuery from '../../../hooks/useQuery';
import { MEMOS } from '../../../mocks';

const EstimateDetail = () => {
  const { data, isMemoVisible, handleClose } = useEstimateDetail();
  const confirm = () => {
    message.success('공사생성 처리');
  };
  // const cancel = (e) => {
  //   console.log(e);
  //   // message.error('Click on No');
  // };
  const [isVisible, setIsVisible] = React.useState(false);
  const closeModal = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  const { navigate } = useQuery();

  return (
    <div className={`${styles.wrapper} ${isMemoVisible ? '' : styles.side_close}`}>
      <PageHeader title="견적 상세" />
      <Info data={data} />

      {/*TODO 푸터에 제안 견적서 보내기, 공사 진행하기 api 연동할때 추가 (CommTable 사용 예정)*/}
      <Table style={{ width: 1200 }} dataSource={[data]} columns={columns} pagination={false} />

      <div style={{ width: 1200, textAlign: 'right', marginTop: 10 }}>
        <Modal className={styles.modalCenter} open={isVisible} onCancel={closeModal} footer={null}>
          <Card title="제안 견적서">
            <Form className={styles['center-container']}>
              <Button
                onClick={() => {
                  navigate(`/estimate/create/${1}`);
                }}
                className={styles.btnStyle}
              >
                고객견적 <br /> 불러오기
              </Button>
              <Button
                onClick={() => {
                  navigate(`/estimate/create/${1}`);
                }}
                // id 바인딩
                className={styles.btnStyle}
              >
                새롭게 <br /> 견적 작성하기
              </Button>
            </Form>
          </Card>
        </Modal>
        <Button
          type="primary"
          onClick={() => {
            setIsVisible(true);
          }}
        >
          제안 견적서 보내기
        </Button>
        <Popconfirm
          title="공사내용을 생성하시겠습니까?"
          description="Are you sure to delete this task?"
          onConfirm={confirm}
          // onCancel={cancel}
          okText="예"
          cancelText="아니요"
        >
          <Button type="primary" style={{ textAlign: 'center', marginLeft: 10 }}>
            공사 진행하기
          </Button>
        </Popconfirm>
      </div>
      {/*TODO 메모 접었을 때 디자인이 없네?..*/}
      <aside>
        <div className={styles.aside_header}>
          <div className={styles.title}>
            {isMemoVisible ? (
              <RightOutlined size={18} onClick={handleClose} />
            ) : (
              <LeftOutlined size={18} onClick={handleClose} />
            )}
            <b>메모</b>
          </div>
          <Radio.Group defaultValue={'all'}>
            <Radio.Button value={'all'}>전체</Radio.Button>
            <Radio.Button value={'selected'}>해당견적만</Radio.Button>
          </Radio.Group>
        </div>
        <div className={styles.aside_body}>
          {MEMOS.map((memo, index) => (
            <Memo key={index} data={memo} />
          ))}
        </div>
      </aside>
    </div>
  );
};

export default EstimateDetail;
