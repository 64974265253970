import React from 'react';
import { Button, Card, Checkbox, DatePicker, Image, Input, Space, Typography } from 'antd';
import moment from 'moment';

import styles from './style.module.less';
import useDailyReport from './useDailyReport';

const DailyReport = () => {
  const { data } = useDailyReport();
  const { Text } = Typography;
  return (
    <Card className={styles.card}>
      <Space align="center" style={{ float: 'right' }}>
        <Button type="primary">이전</Button>
        <DatePicker defaultValue={moment()} />
        <Button type="primary">다음</Button>
      </Space>
      <div className={styles.report}>
        <div>
          <Image src={'https://via.placeholder.com/600'} width={600} />
        </div>
        <div className={styles.work}>
          {Array.from({ length: 10 }).map((item, idx) => (
            <div className={styles.item}>
              <span className={styles.tag}>바닥철거 업체</span>
              <Checkbox>출근{`${idx}`}</Checkbox>
            </div>
          ))}
          <Text>메모</Text>
          <Input.TextArea style={{ minHeight: 52 }} />
        </div>
      </div>
    </Card>
  );
};

export default DailyReport;
