import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, PageHeader, Pagination, Table } from 'antd';

import styles from './style.module.less';
import SearchForm from '../../../components/datalist/SearchForm';

import { ESTIMATE_REQUEST_SEARCH_FIELDS } from '../../../constants/searchFields';
import { useNavigate } from 'react-router-dom';
import { restApi } from '../../../apis';
import dayjs from 'dayjs';
import { calcPriceWithOption, comma } from '../../../common/utils';
import { shallowEqual, useSelector } from 'react-redux';

const COLUMNS = [
  {
    title: '번호',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    align: 'center',
  },
  //   {
  //     title: '상태',
  //     dataIndex: 'status',
  //     key: 'status',
  //     width: 70,
  //     align: 'center',
  //   },
  {
    title: '주택명',
    dataIndex: 'estimate',
    key: 'apt_name',
    minWidth: 200,
    render: (data) => data.title,
  },
  {
    title: '평수',
    dataIndex: 'estimate',
    key: 'area_size',
    width: 100,
    align: 'center',
    sorter: true,
    render: (data) => data.area_size + ' PY',
  },
  {
    title: '주소',
    dataIndex: 'estimate',
    minWidth: 200,
    key: 'address',
    sorter: true,
    render: (data) => data.address,
  },
  {
    title: '소비자 예상견적가',
    dataIndex: 'estimate',
    key: 'total_price',
    width: 160,
    align: 'right',
    sorter: true,
    render: (d) => comma(d.total_price) + ' 원',
  },
  {
    title: '공사도움받기 요청일시',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 160,
    align: 'center',
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm'),
  },
  {
    title: '잔여기간/마감일',
    dataIndex: 'listen_at',
    key: 'listen_at',
    width: 160,
    align: 'center',
    sorter: true,
    render: (value) => `${0 - dayjs().diff(value, 'days')}일 / ${dayjs(value).format('YYYY.MM.DD')}`,
  },
  {
    title: '공사시작일(예정)',
    dataIndex: 'bulid_at',
    key: 'bulid_at',
    width: 160,
    align: 'center',
    sorter: true,
    render: (value) => dayjs(value).format('YYYY.MM.DD'),
  },
  //   {
  //     title: '',
  //     dataIndex: 'id',
  //     key: 'detail',
  //     width: 100,
  //     align: 'center',
  //   },
];

const ESTIMATERequest = () => {
  const navigate = useNavigate();

  const { estimateOptions } = useSelector((s) => s.common, shallowEqual);
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/estimate/request/${record.estimate.id}`);
      },
    };
  };

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/partners/constructs`);
    setData(data);
  }, []);
  console.log('data', data);
  const columns = useMemo(() => {
    const output = [...COLUMNS];
    output[0] = {
      title: '번호',
      key: 'no',
      width: 70,
      align: 'center',
      render: (_, row, ix) => data?.total_elements - ix,
    };
    output[4].render = (d) => comma(calcPriceWithOption(d.total_price, estimateOptions)) + ' 원';
    return output;
  }, [data]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  return (
    <div>
      <PageHeader title="공사도움받기 신청" />
      <SearchForm fields={ESTIMATE_REQUEST_SEARCH_FIELDS} />
      <Divider />
      {/*TODO api 연동할 때 CommTable 로 변경*/}
      {/* <Table columns={ESTIMATE_REQUEST_COLUMNS} dataSource={data} pagination={false} /> */}
      <Table
        columns={columns}
        dataSource={data?.items}
        pagination={false}
        // pagination={{
        //   current: 1,
        //   total: 100,
        //   pageSize: 20,
        //   pageSizeOptions: data?.total_elements >= 500 ? ['10', '20', '30', '50', '100'] : ['10', '20', '30', '50'],
        //   //   onShowSizeChange: (current, size) => setPageSize(size),
        //   showSizeChanger: true,
        // }}
        onRow={onRow}
      />
      <Pagination defaultCurrent={1} total={50} className={styles.page} />
    </div>
  );
};

export default ESTIMATERequest;
