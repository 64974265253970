import React from 'react';
import { Card, Descriptions, PageHeader } from 'antd';
// import EstimateTabs from '../tab';
import EstimateTabs from './tab';

const EstimateContract = () => {
  return (
    <div>
      <PageHeader title="상담진행" />
      <Card>
        <Descriptions bordered column={4}>
          <Descriptions.Item label={'주택명'}>개봉 푸르지오</Descriptions.Item>
          <Descriptions.Item label={'면적'}>34PY</Descriptions.Item>
          <Descriptions.Item label={'현장주소'}>서울시 구로구 남부순환로 775</Descriptions.Item>
          <Descriptions.Item label={'고객정보'}>
            {/* <Space size="large"> */}
            김철수 / 010-4363-3306
            {/* </Space> */}
          </Descriptions.Item>
          <Descriptions.Item label={'업체명'}>살리고 디자인</Descriptions.Item>
          <Descriptions.Item label={'담당자'}>개똥이</Descriptions.Item>
          <Descriptions.Item label={'담당자 연락처'}>010-1234-5678</Descriptions.Item>
          <Descriptions.Item label={'업체주소'}>경기도 성남시 분당구 이매동</Descriptions.Item>
        </Descriptions>
      </Card>
      <EstimateTabs />
    </div>
  );
};

export default EstimateContract;
