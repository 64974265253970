import { PageHeader } from 'antd';
import React from 'react';
import styles from './index.module.less';
import TextArea from 'antd/lib/input/TextArea';
import { MinusSquareFilled, PlusSquareFilled } from '@ant-design/icons';
import SingleUploader from '../../../../../components/uploader/SingleUploader';
import produce from 'immer';
import AnnexTable from '../../../../../components/table/AnnexTable';
import dayjs from 'dayjs';

const INIT_STATE = {
  intro_files: [],
};

export default function WriteIntroduction() {
  // const { form, setValue, onReset } = useForm(INIT_STATE);
  const [requestBody, setRequestBody] = React.useState(INIT_STATE);

  console.log({ requestBody });

  React.useEffect(() => {
    if (requestBody?.intro_files?.length === 0) {
      setRequestBody(
        produce((draft) => {
          draft.intro_files.push({ content: '', image: null });
        }),
      );
    }
  }, []);

  return (
    <div style={{ marginBottom: 20 }}>
      <PageHeader
        title="회사소개서 및 기타자료 첨부"
        extra={[
          <AnnexTable
            column={4}
            items={[
              { label: '주택명', value: '개봉 푸르지오' },
              { label: '면적', value: '34PY' },
              { label: '현장주소', value: '서울시 구로구 남부순환로 775' },
              {
                label: '제안마감일',
                value: (
                  <span>
                    2023-04-27
                    {dayjs().diff('2023-04-27', 'days') > 0 && (
                      <span style={{ marginLeft: 15, fontWeight: '700' }}>{`${dayjs().diff(
                        '2023-04-27',
                        'days',
                      )}일전`}</span>
                    )}
                  </span>
                ),
              },
            ]}
          />,
        ]}
      />
      <div className={styles.btnContainer}>
        <button className={styles.btn}>나가기</button>
        <button className={styles.btn}>저장</button>
      </div>
      {/* <table className={styles.summaryTable}>
        <tr>
          <th>주택명</th>
          <td>개봉 푸르지오</td>
          <th>면적</th>
          <td>34PY</td>
          <th>현장주소</th>
          <td>서울시 구로구 남부순환로 775</td>
          <th>제안마감일</th>
          <td>2023-01-23 12:00</td>
          <th>마감 3일전</th>
        </tr>
      </table> */}
      <hr style={{ borderWidth: 1, borderStyle: 'solid', marginBlock: 20 }} />
      <div className={styles.btnContainer}>
        <button className={styles.btn}>보관함 가져오기</button>
        <button className={styles.btn}>예시보기</button>
      </div>

      {/* form */}
      <div className={styles.formWrapper}>
        <table className={styles.formTable}>
          <tr>
            <th>파일</th>
            <th>내용</th>
          </tr>
          {requestBody?.intro_files?.map((intro, index) => {
            return (
              <tr key={`Intro_Form_${index}`}>
                <td>
                  <SingleUploader />
                </td>
                <td>
                  <TextArea
                    style={{ height: 102, resize: 'none' }}
                    value={requestBody?.intro_files?.[index]?.content}
                    onChange={(e) => {
                      setRequestBody(
                        produce((draft) => {
                          draft.intro_files[index].content = e.target.value;
                        }),
                      );
                    }}
                  />
                </td>
                <td>
                  <div className={styles.counter}>
                    <PlusSquareFilled
                      style={{ fontSize: 30 }}
                      onClick={() => {
                        setRequestBody(
                          produce((draft) => {
                            draft.intro_files.splice(index + 1, 0, { content: '', image: null });
                          }),
                        );
                      }}
                    />
                    <MinusSquareFilled
                      style={{ fontSize: 30 }}
                      onClick={() => {
                        if (requestBody?.intro_files?.length === 1) {
                          return;
                        }
                        setRequestBody(
                          produce((draft) => {
                            draft.intro_files.splice(index, 1);
                          }),
                        );
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
}
