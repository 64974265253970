export const ESTIMATE_REQUEST_SEARCH_FIELDS = [
  {
    type: 'inputrange',
    width: 0.5,
    label: '비용',
    key: 'cost',
    keys: ['start_cost', 'end_cost'],
  },
  {
    type: 'selectrange',
    width: 0.5,
    label: '지역',
    key: 'area',
  },
  {
    type: 'daterange',
    width: 0.5,
    label: '공사시작일',
    key: 'date',
    keys: ['start_at', 'end_at'],
  },
  {
    type: 'radiobutton',
    width: 0.5,
    key: 'range',
    items: [
      { value: '1m', label: '1개월' },
      { value: '3m', label: '3개월' },
      { value: '6m', label: '6개월' },
    ],
  },
  // {
  //   type: 'checkbox',
  //   width: 0.5,
  //   key: 'is_include',
  //   items: [{ value: 'true', label: '미정포함여부' }],
  // },
];

export const ESTIMATE_ONGOING_SEARCH_FIELDS = [
  {
    type: 'inputrange',
    width: 0.5,
    label: '비용',
    key: 'cost',
    keys: ['start_cost', 'end_cost'],
  },
  {
    type: 'selectrange',
    width: 0.5,
    label: '지역',
    key: 'area',
  },
  {
    type: 'daterange',
    width: 0.5,
    label: '공사시작일',
    key: 'date',
    keys: ['start_at', 'end_at'],
  },
  {
    type: 'radiobutton',
    width: 0.5,
    key: 'range',
    items: [
      { value: '1m', label: '1개월' },
      { value: '3m', label: '3개월' },
      { value: '6m', label: '6개월' },
    ],
  },
  // {
  //   type: 'radiobutton',
  //   width: 0.5,
  //   key: 'range',
  //   items: [
  //     { value: '1m', label: '-1개월' },
  //     { value: '6m', label: '-6개월' },
  //     { value: '1y', label: '-1년' },
  //   ],
  // },
];

export const CONSTRUCTION_SEARCH_FIELDS = [
  {
    type: 'inputrange',
    width: 0.5,
    label: '비용',
    key: 'cost',
    keys: ['start_cost', 'end_cost'],
  },
  {
    type: 'selectrange',
    width: 0.5,
    label: '지역',
    key: 'area',
  },
  {
    type: 'daterange',
    width: 0.5,
    label: '공사시작일',
    key: 'date',
    keys: ['start_at', 'end_at'],
  },
  {
    type: 'radiobutton',
    width: 0.5,
    key: 'range',
    items: [
      { value: '1m', label: '1개월' },
      { value: '3m', label: '3개월' },
      { value: '6m', label: '6개월' },
    ],
  },
  // {
  //   type: 'select',
  //   width: 0.25,
  //   label: '기간',
  //   key: 'range_type',
  //   items: [
  //     { value: 'created_at', label: '작성일' },
  //     { value: 'constructionDate', label: '공사 예정일' },
  //   ],
  // },
  // {
  //   type: 'daterange',
  //   width: 0.25,
  //   key: 'date',
  //   keys: ['start_at', 'end_at'],
  // },
  // {
  //   type: 'radiobutton',
  //   width: 0.5,
  //   key: 'range',
  //   items: [
  //     { value: 'today', label: '오늘' },
  //     { value: '1w', label: '1주일' },
  //     { value: '1m', label: '1개월' },
  //     { value: '3m', label: '3개월' },
  //   ],
  // },
  // {
  //   type: 'select',
  //   width: 0.5,
  //   label: '진행상태',
  //   key: 'status',
  //   items: [{ value: 'created_at', label: '진행상태' }],
  // },

  // {
  //   type: 'select',
  //   width: 0.5,
  //   label: '검색조건',
  //   key: 'keywordType',
  //   items: [
  //     { value: 'address', label: '주소' },
  //     { value: 'name', label: '의뢰고객명' },
  //   ],
  // },
  // {
  //   type: 'input',
  //   width: 0.5,
  //   label: '검색어',
  //   key: 'keyword',
  // },
];

export const ORDER_SEARCH_FIELDS = [
  {
    type: 'select',
    width: 0.25,
    label: '기간',
    key: 'range_type',
    items: [{ value: 'created_at', label: '발주일' }],
  },
  {
    type: 'daterange',
    width: 0.25,
    key: 'date',
    keys: ['start_at', 'end_at'],
  },
  {
    type: 'checkbox',
    width: 0.5,
    key: 'searchType',
    items: [
      { value: 'pending', label: '수락대기' },
      { value: 'reject', label: '거절' },
      { value: 'need', label: '결제필요' },
      { value: 'done', label: '결제완료' },
    ],
  },
  {
    type: 'select',
    width: 0.25,
    label: '검색조건',
    key: 'keywordType',
    items: [
      { value: 'all', label: '전체' },
      { value: 'project', label: '제품명' },
      { value: 'option', label: '옵션' },
      { value: 'info', label: '배송정보' },
    ],
  },
  {
    type: 'input',
    width: 0.75,
    label: '검색어',
    key: 'keyword',
  },
];

export const CALCULATE_SEARCH_FIELDS = [
  {
    type: 'select',
    width: 0.5,
    label: '기간',
    key: 'range_type',
    items: [{ value: 'created_at', label: '발주일' }],
  },
  {
    type: 'daterange',
    width: 0.5,
    key: 'date',
    keys: ['start_at', 'end_at'],
  },
  {
    type: 'select',
    width: 0.5,
    label: '검색조건',
    key: 'keywordType',
    items: [
      { value: 'all', label: '전체' },
      { value: 'project', label: '제품명' },
      { value: 'option', label: '옵션' },
      { value: 'info', label: '배송정보' },
    ],
  },
  {
    type: 'input',
    width: 0.5,
    label: '검색어',
    key: 'keyword',
  },
];

export const MANAGER_STATUS_FIELDS = [
  {
    type: 'inputrange',
    width: 0.5,
    label: '비용',
    key: 'cost',
    keys: ['start_cost', 'end_cost'],
  },
  {
    type: 'selectrange',
    width: 0.5,
    label: '지역',
    key: 'area',
  },
  {
    type: 'daterange',
    width: 0.5,
    label: '공사시작일',
    key: 'date',
    keys: ['start_at', 'end_at'],
  },
  {
    type: 'radiobutton',
    width: 0.5,
    key: 'range',
    items: [
      { value: '1m', label: '1개월' },
      { value: '3m', label: '3개월' },
      { value: '6m', label: '6개월' },
    ],
  },
];
