import React from 'react';
import { useParams } from 'react-router-dom';

// Mock data
import { ESTIMATE_DETAIL_DATA } from '../../../mocks';
import { comma } from '../../../common/utils';
import moment from 'moment';
import { Button } from 'antd';
import useQuery from '../../../hooks/useQuery';

export const columns = [
  {
    title: '번호',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 100,
  },
  {
    title: '최종견적가',
    dataIndex: 'total_price',
    key: 'total_price',
    align: 'center',
    width: 200,
    render: (value) => `${comma(value)}만원`,
  },
  {
    title: '메모수',
    dataIndex: 'memo_count',
    key: 'memo_count',
    align: 'center',
    width: 100,
  },
  {
    title: '등록일',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center',
    width: 200,
    render: (value) => moment(value).format('YYYY.MM.DD'),
  },
  {
    title: '최종응답일',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center',
    width: 200,
    render: (value) => moment(value).format('YYYY.MM.DD HH:mm:ss'),
  },
  {
    title: '조회일시',
    dataIndex: 'view_at',
    key: 'view_at',
    align: 'center',
    width: 200,
    render: (value) => moment(value).format('YYYY.MM.DD HH:mm:ss'),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'detail',
    align: 'center',
  },
];

const useEstimateDetail = () => {
  const [data, setData] = React.useState(ESTIMATE_DETAIL_DATA);
  const [isMemoVisible, setIsMemoVisible] = React.useState(true);
  const { id } = useParams();
  const { navigate } = useQuery();

  const handleClose = React.useCallback(() => {
    setIsMemoVisible((p) => !p);
  }, []);

  const goToCreate = React.useCallback(
    (id) => {
      return () => navigate(`/estimate/create/${id}`);
    },
    [navigate],
  );

  columns[6].render = (value) => {
    return (
      <>
        <Button style={{ marginRight: 10 }} onClick={goToCreate(value)}>
          상세
        </Button>
        {/* TODO 수정 후 보내기 : 해당 견적서 복사 후 수정해서 전송*/}
        <Button onClick={goToCreate(value)}>수정 후 보내기</Button>
      </>
    );
  };

  return {
    data,
    isMemoVisible,
    handleClose,
  };
};

export default useEstimateDetail;
