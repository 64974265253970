import React from 'react';
import { Card, Typography } from 'antd';
import moment from 'moment';

const Memo = ({ data, key }) => {
  if (!data) return <React.Fragment />;

  // TODO 메모 전체보기가 있어서 어떤 견적의 메모인지도 보여줘야 할듯
  return (
    <Card
      key={key}
      style={{
        backgroundColor: '#c8c8c8',
        margin: 10,
        borderRadius: 10,
      }}
    >
      <Typography.Title level={5}>{data?.content}</Typography.Title>
      <Typography.Text>{data?.name}</Typography.Text>
      <br />
      <Typography.Text>{`견적 일시 : ${moment(data?.created_at).format('YYYY-MM-DD HH:mm:ss')}`}</Typography.Text>
    </Card>
  );
};

export default Memo;
