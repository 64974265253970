import React, { useCallback, useMemo } from 'react';
import { Button, Modal, PageHeader, Table } from 'antd';
import FilterNavigation from '../../components/nav/FilterNavigation';
import styled from 'styled-components';
import { calcPriceWithOption, comma } from '../../common/utils';
import styles from './index.module.less';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { getEstimateDetail } from '../../apis/estimate';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import qs from 'query-string';
import { restApi } from '../../apis';

const ListTable = styled(Table)`
  th.ant-table-cell {
    background-color: #000;
    color: #fff;
    font-size: 14px;
  }
  td.ant-table-cell {
    background-color: #fff;
  }
`;

const ProposalModal = styled(Modal)`
  .ant-modal {
    display: flex;
  }
  .ant-modal-content {
    width: 420px;
    padding: 15px;
  }
`;

const COLUMNS = [
  {
    title: '구분',
    key: 'id',
    dataIndex: 'type',
    align: 'center',
    width: 100,
    render: (type, array) => {
      return (
        <div className={styles.alignHorizontal}>
          <div className={styles.userType}>
            <img
              src={require('../../assets/icon/icon_user_default.png')}
              alt="유저 구분 이미지"
              style={{ width: 45, height: 45 }}
            />
            {!array?.readed && (
              <div className={styles.newTagWrapper} style={{ padding: '1px 2px', right: -8 }}>
                <span className={styles.newTag}>NEW</span>
              </div>
            )}
          </div>
        </div>
      );
    },
  },
  {
    title: '주택명',
    key: 'id',
    dataIndex: 'estimate',
    align: 'left',
    render: (estimate) => {
      return <span style={{ fontSize: 14 }}>{estimate?.title}</span>;
    },
  },
  {
    title: '면적',
    key: 'id',
    dataIndex: 'estimate',
    align: 'center',
    width: 100,
    render: (estimate) => {
      return <span style={{ fontSize: 14 }}>{`${estimate?.area_size}PY`}</span>;
    },
  },
  {
    title: '제안마감일',
    key: 'id',
    dataIndex: 'estimate',
    align: 'center',
    width: 180,
    render: (estimate, array) => {
      const diff = dayjs(dayjs().format('YYYY-MM-DD')).diff(dayjs(array?.listen_at).format('YYYY-MM-DD'), 'day');
      return (
        <span style={{ fontSize: 14 }}>
          {`${dayjs(array?.listen_at)?.format('YYYY-MM-DD')}`}
          <span style={{ fontWeight: '700' }}>{`${`(D${diff > 0 ? `-${diff}` : `+${diff * -1}`})`}`}</span>
        </span>
      );
    },
  },
  {
    title: '고객예상견적금액',
    key: 'id',
    dataIndex: 'estimate',
    align: 'right',
    width: 160,
    render: (estimate) => {
      return <span style={{ fontSize: 18, fontWeight: '700' }}>{comma(estimate?.total_price)}</span>;
    },
  },
];

export default function CustomersIndex() {
  const params = qs.parse(window.location.search);

  const { estimateOptions } = useSelector((s) => s.common, shallowEqual);

  const navigate = useNavigate();
  const [state, setState] = React.useState('all');
  const [isProposalModalOpen, setIsProposalModalOpen] = React.useState(false);
  const [detailData, setDetailData] = React.useState(null);
  const [targetId, setTargetId] = React.useState(params?.tid ?? undefined);

  const loadDetailData = useCallback(async () => {
    const { data } = await getEstimateDetail(targetId);

    const { data: sizes } = await restApi.get(`/apartments/${data?.apt_id}/sizes`);
    const size = sizes.find((x) => x.id === data.apt_size_id);
    setDetailData({ ...data, size });
  }, [targetId]);

  const onRow = (record) => {
    return {
      onClick: (e) => {
        navigate(`/customers/call?tid=${record?.estimate?.id}`, { replace: true });
        setTargetId(record?.estimate?.id);
      },
    };
  };

  React.useEffect(() => {
    if (targetId) {
      loadDetailData().catch(console.warn);
    } else {
      setDetailData(undefined);
    }
  }, [targetId, loadDetailData]);

  const { contructs } = useSelector((s) => s.common, shallowEqual);
  const items = useMemo(() => {
    let o = [...(contructs ?? [])];
    if (state === 'favorite' || state === 'area') {
      o = [];
    } else if (state === 'deadline') {
      o = o.filter((x) => moment().add(-1, 'days').isAfter(x.listen_at));
    } else if (state === 'new') {
      o = o.filter((x) => !x.readed);
    }
    return o;
  }, [contructs, state]);

  const target = contructs?.find((x) => x.estimate?.id == targetId);

  const columns = useMemo(() => {
    const o = [...COLUMNS];
    o[4].render = (estimate) => {
      return (
        <span style={{ fontSize: 18, fontWeight: '700' }}>
          {comma(calcPriceWithOption(estimate?.total_price, estimateOptions))}
        </span>
      );
    };
    return o;
  });

  const handleProposal = async () => {
    const { data } = await restApi.put(`/partners/constructs/${detailData?.id}`);
    navigate(`/customers/details/${data}`);
  };

  return (
    <div className={'fh-body'}>
      <PageHeader title="고객제안 요청 리스트" />
      <div className={'content'}>
        <div className={'col-12'}>
          <div className={'col-content'}>
            <FilterNavigation
              values={['all', 'new', 'deadline', 'favorite', 'area']}
              state={state}
              setState={setState}
              containerStyle={{ marginBottom: 10 }}
            />
            <ListTable dataSource={items} columns={columns} scroll={{ y: 500 }} onRow={onRow} pagination={false} />
          </div>
        </div>
        <div className={'col-12'}>
          <div className={'col-content'}>
            <div style={{ position: 'relative' }}>
              <img
                src={require('../../assets/icon/icon_building_bg.png')}
                alt="제안요청 서머리 헤더"
                style={{ width: '100%', height: 120 }}
              />
              <span
                style={{
                  position: 'absolute',
                  top: 40,
                  left: 50,
                  fontSize: 30,
                  color: '#fff',
                  fontWeight: '700',
                  textShadow: '2px 2px 6px gray',
                }}
              >
                제안요청 서머리
              </span>
            </div>
            <div
              className={styles.summaryContent}
              style={detailData?.id ? null : { display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {!!detailData?.id ? (
                <>
                  <div className={styles.summaryHeader}>
                    <div className={styles.profileWrapper}>
                      <img
                        src={require('../../assets/icon/icon_user_default.png')}
                        alt="유저 프로필"
                        style={{ width: 85, height: 85 }}
                      />
                      {!target?.readed && (
                        <div className={styles.newTagWrapper}>
                          <span className={styles.newTag}>NEW</span>
                        </div>
                      )}
                    </div>
                    <span style={{ marginRight: 5, position: 'relative', top: 10, fontWeight: '500' }}>
                      고객예상견적금액
                    </span>
                    <span style={{ fontSize: 40, fontWeight: '700' }}>{`${comma(
                      calcPriceWithOption(detailData?.total_price, estimateOptions),
                    )}원`}</span>
                  </div>
                  <div>
                    <div className={styles.tableWrapper} style={{ marginBottom: 20 }}>
                      <table className={styles.summaryTable}>
                        <tr>
                          <th>주택명</th>
                          <td>{detailData?.apt_name}</td>
                        </tr>
                        <tr>
                          <th>주소</th>
                          <td>{detailData?.address}</td>
                        </tr>
                        <tr>
                          <th>방/화장실</th>
                          <td>{`${detailData?.size?.room_count}/${detailData?.size?.bathroom_count}`}</td>
                        </tr>
                        <tr>
                          <th>면적(분양/전용)</th>
                          <td>{`${detailData?.size?.supply_m2}m² / ${detailData?.size?.exclusive_m2}m²`}</td>
                        </tr>
                        <tr>
                          <th>입주예정일</th>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th>공사시작일</th>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th>관리실 연락처</th>
                          <td>{detailData?.size?.apartment?.manager_phone || '-'}</td>
                        </tr>
                        <tr>
                          <th>네이버 평면도 보기</th>
                          <td>네이버 평면도 보기</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className={styles.btnContainer}>
                    <Button
                      onClick={() => {
                        navigate(`/customers/estimates/${detailData?.id}`);
                      }}
                      style={{
                        color: '#fff',
                        width: '28%',
                        height: 40,
                        backgroundColor: '#3d5bc1',
                        fontWeight: '700',
                        fontSize: 16,
                      }}
                    >
                      견적서 보기
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(`/customers/styles/${detailData?.id}`);
                      }}
                      style={{
                        color: '#fff',
                        width: '28%',
                        height: 40,
                        backgroundColor: '#3d5bc1',
                        fontWeight: '700',
                        fontSize: 16,
                      }}
                    >
                      고객 스타일 보기
                    </Button>
                    <Button
                      onClick={() => setIsProposalModalOpen(true)}
                      style={{
                        color: '#fff',
                        width: '28%',
                        height: 40,
                        backgroundColor: '#ff3f3f',
                        fontWeight: '700',
                        fontSize: 16,
                      }}
                    >
                      제안하기
                    </Button>
                  </div>
                </>
              ) : (
                <img
                  src={require('../../assets/icon/icon_list_empty_sample.png')}
                  alt="서머리 빈 이미지"
                  style={{ width: 200, height: 150 }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/*<Row gutter={[24]}>*/}
      {/*  <Col span={12}>*/}

      {/*  </Col>*/}
      {/*  <Col span={12}>*/}

      {/*  </Col>*/}
      {/*</Row>*/}
      <ProposalModal open={isProposalModalOpen} footer={null} closable={false} centered>
        <div className={styles.modalView}>
          <p>
            해당 견적의
            <br />
            공사제안에 참여합니다.
          </p>
          <p>
            고객의 니즈와 귀사만의 노하우가
            <br />
            듬뿍 담긴 제안서로, 고객에게 어필해보세요.
          </p>
          <p>
            제안서는 제안진행현황에서 작성하여
            <br />
            송부할 수 있습니다.
          </p>
          <div className={styles.checkBtnWrapper}>
            <button className={styles.checkBtn} onClick={handleProposal}>
              확인
            </button>
          </div>
          <button
            className={styles.modalCloseBtn}
            onClick={(e) => {
              setIsProposalModalOpen(false);
            }}
          >
            <img
              src={require('../../assets/icon/icon_close.png')}
              alt="모달 닫기 버튼"
              style={{ width: 35, height: 35 }}
            />
          </button>
        </div>
      </ProposalModal>
    </div>
  );
}
