import React, { useMemo } from 'react';
import {
  AppstoreOutlined,
  AreaChartOutlined,
  CarryOutOutlined,
  CopyOutlined,
  FormatPainterOutlined,
  GiftOutlined,
  HomeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import HomeIndex from '../pages';
import ProductsItemsIndex from '../pages/products/items';
import ProductsItemsDetails from '../pages/products/items/details';
import { shallowEqual, useSelector } from 'react-redux';
import ProductsCreate from '../pages/products/create';
import SalesReceiptIndex from '../pages/sales/receipt';
import SalesCallIndex from '../pages/sales/call';
import CollectionsIndex from '../pages/collections';
import SettingsNoticeIndex from '../pages/settings/notice';
import SettingsAccountsIndex from '../pages/settings/accounts';
import SettingsQnaIndex from '../pages/settings/qna';
import ESTIMATERequest from '../pages/estimate/request';
import ESTIMATERequestDetails from '../pages/estimate/request/[id]';
import ESTIMATEApplication from '../pages/estimate/application';
import EstimateContract from '../pages/estimate/contract';
import EstimateContreactDetails from '../pages/estimate/contract/tab/estimate/[id]';
import ManagerApplicationIndex from '../pages/im/application';
import ManagerStatusIndex from '../pages/im/status';
import ESTIMATEOngoing from '../pages/estimate/ongoing';
import ESTIMATEDetail from '../pages/estimate/detail';
import ESTIMATECreate from '../pages/estimate/create';
import ConstructionList from '../pages/construction/list';
import ConstructionDetail from '../pages/construction/list/detail';
import ProposalProgress from '../pages/customers/progress';
import ProposalApplication from '../pages/customers/application';
import ProposalContract from '../pages/customers/contracts';
import ProposalTerminate from '../pages/customers/terminate';
import ProposalContractDetails from '../pages/customers/contracts/[id]';
import WriteEstimate from '../pages/customers/contracts/[id]/estimate';
import ProposalExpertStyleIndex from '../pages/customers/expert/style/[id]';
import AddDepthProposal from '../pages/customers/contracts/[id]/depth_proposal';
import WriteIntroduction from '../pages/customers/contracts/[id]/introduction';
import WritePortfolio from '../pages/customers/contracts/[id]/portfolio';
import WriteFloorPlan from '../pages/customers/contracts/[id]/floor';
import ManagerApplicationRequestIndex from '../pages/im/application/request';
import ManagerApplicationDirectIndex from '../pages/im/application/direct';
import ManagerCompleteIndex from '../pages/im/complete';
import ManagerConstructIndex from '../pages/im/status/construct';
import ManagerConstructScheduleIndex from '../pages/im/status/construct/schedule';
import CustomersIndex from '../pages/customers';
import CustomerEstimateDetails from '../pages/customers/estimates/[id]';
import CustomersProgress from '../pages/customers/progress';
import CustomersDetailsEstimateForm from "../pages/customers/details/estimates";

// 유통사 메뉴
export const DISTRIBUTOR_PAGES = [
  {
    path: '/',
    title: '홈',
    icon: HomeOutlined,
    screen: HomeIndex,
    isMenu: true,
  },
  {
    path: '/sales',
    title: '발주 관리',
    icon: CarryOutOutlined,
    isMenu: true,
  },
  {
    path: '/sales/call',
    title: '발주 목록',
    screen: SalesCallIndex,
    isMenu: true,
  },
  {
    path: '/sales/receipt',
    title: '정산 처리',
    screen: SalesReceiptIndex,
    isMenu: true,
  },
  {
    path: '/products',
    title: '제품 관리',
    icon: GiftOutlined,
    isMenu: true,
  },
  {
    path: '/products/create',
    title: '제품 일괄등록',
    screen: ProductsCreate,
    isMenu: true,
  },
  {
    path: '/products/items',
    title: '등록제품 관리',
    screen: ProductsItemsIndex,
    isMenu: true,
  },
  {
    path: '/products/items/:id',
    title: '제품 관리 상세',
    screen: ProductsItemsDetails,
  },
  {
    path: '/collections',
    title: '스타일',
    icon: AppstoreOutlined,
    isMenu: true,
    screen: CollectionsIndex,
  },
  {
    path: '/analytics',
    title: '통계',
    icon: AreaChartOutlined,
    isMenu: true,
  },
  {
    path: '/settings',
    title: '설정',
    icon: SettingOutlined,
    isMenu: true,
  },
  {
    path: '/settings/accounts',
    title: '직원 관리',
    screen: SettingsAccountsIndex,
    isMenu: true,
  },
  {
    path: '/settings/notice',
    title: '공지사항',
    screen: SettingsNoticeIndex,
    isMenu: true,
  },
  {
    path: '/settings/qna',
    title: 'Q&A',
    screen: SettingsQnaIndex,
    isMenu: true,
  },
];

// 시공사 메뉴
export const CONSTRUCTOR_PAGES = [
  {
    path: '/',
    title: '홈',
    icon: HomeOutlined,
    screen: HomeIndex,
    isMenu: true,
  },
  // {
  //   path: '/',
  //   title: '센터 업무',
  //   icon: HomeOutlined,
  //   // screen: HomeIndex,
  //   isMenu: true,
  // },
  {
    path: '/customers',
    title: '고객찾기',
    icon: CopyOutlined,
    isMenu: true,
  },
  {
    path: '/customers/call',
    title: '고객제안요청리스트',
    screen: CustomersIndex,
    isMenu: true,
  },
  {
    path: '/customers/estimates/:id',
    title: '고객제안요청리스트',
    screen: CustomerEstimateDetails,
    isMenu: false,
  },
  {
    path: '/customers/styles/:id',
    title: '고객스타일보기',
    screen: ProposalExpertStyleIndex,
    isMenu: false,
  },
  {
    path: '/customers/details/:id',
    screen: ProposalContractDetails,
    // isMenu: true,
  },

  {
    path: '/customers/details/:id/estimates/:eid',
    screen: CustomersDetailsEstimateForm,
    // isMenu: true,
  },

  //

  {
    path: '/customers/progress',
    title: '제안진행현황',
    screen: CustomersProgress,
    isMenu: true,
  },
  {
    path: '/customers/application',
    title: '상담신청',
    screen: ProposalApplication,
    isMenu: true,
  },
  {
    path: '/customers/contract',
    title: '상담진행',
    screen: ProposalContract,
    isMenu: true,
  },
  {
    path: '/customers/contract/:id/estimate',
    title: '견적서(필수)',
    screen: WriteEstimate,
    // isMenu: true,
  },
  {
    path: '/customers/contract/:id/introduction',
    title: '회사소개서및기타자료첨부(선택)',
    screen: WriteIntroduction,
    // isMenu: true,
  },
  {
    path: '/customers/contract/:id/floor',
    title: '도면(선택)',
    screen: WriteFloorPlan,
    // isMenu: true,
  },
  {
    path: '/customers/contract/:id/portfolio',
    title: '3D포트폴리오(선택)',
    screen: WritePortfolio,
    // isMenu: true,
  },
  {
    path: '/customers/contract/:id/depth_proposal',
    title: '상담진행',
    screen: AddDepthProposal,
    // isMenu: true,
  },
  {
    path: '/customers/terminate',
    title: '상담종료리스트',
    screen: ProposalTerminate,
    isMenu: true,
  },
  {
    path: '/estimate',
    title: '견적',
    icon: CopyOutlined,
    isMenu: true,
  },
  {
    path: '/estimate/request',
    title: '공사도움받기 신청',
    screen: ESTIMATERequest,
    isMenu: true,
  },
  {
    path: '/estimate/request/:id',
    title: '공사도움받기 신청 상세',
    screen: ESTIMATERequestDetails,
    isMenu: false,
  },
  {
    path: '/estimate/ongoing',
    title: '제안한 견적',
    screen: ESTIMATEOngoing,
    isMenu: true,
  },
  {
    path: '/estimate/detail/:id',
    title: '견적 상세',
    screen: ESTIMATEDetail,
    isMenu: false,
  },
  {
    path: '/estimate/create/:id',
    title: '견적 작성',
    screen: ESTIMATECreate,
    isMenu: false,
  },
  {
    path: '/estimate/application',
    title: '상담신청',
    screen: ESTIMATEApplication,
    isMenu: true,
  },
  {
    path: '/estimate/contract',
    title: '상담진행',
    screen: EstimateContract,
    isMenu: true,
  },
  {
    path: '/estimate/contract/:id',
    title: '상담진행',
    screen: EstimateContreactDetails,
    isMenu: false,
  },
  {
    path: '/im',
    title: 'IM 공사관리',
    icon: FormatPainterOutlined,
    isMenu: true,
  },
  {
    path: '/im/application',
    title: 'IM시작하기',
    screen: ManagerApplicationIndex,
    isMenu: true,
  },
  {
    path: '/im/application/request',
    title: 'IM요청접수리스트',
    screen: ManagerApplicationRequestIndex,
    isMenu: false,
  },
  {
    path: '/im/application/direct',
    title: '직접접수하기',
    screen: ManagerApplicationDirectIndex,
    isMenu: false,
  },
  {
    path: '/im/status',
    title: 'IM 진행 현황',
    screen: ManagerStatusIndex,
    isMenu: true,
  },
  {
    path: '/im/status/construct',
    title: 'IM 진행 현황',
    screen: ManagerConstructIndex,
    isMenu: false,
  },
  {
    path: '/im/status/construct/schedule',
    title: '일정표작성/수정',
    screen: ManagerConstructScheduleIndex,
    isMenu: false,
  },

  {
    path: '/im/complete',
    title: 'IM 완료 현황',
    screen: ManagerCompleteIndex,
    isMenu: true,
  },
  {
    path: '/construction',
    title: '공사',
    icon: FormatPainterOutlined,
    isMenu: true,
  },
  {
    path: '/construction/list',
    title: '공사 목록',
    screen: ConstructionList,
    isMenu: true,
  },
  {
    path: '/construction/:id',
    title: '공사목록',
    screen: ConstructionDetail,
    isMenu: false,
  },
  {
    path: '/construction/products',
    title: '발주 목록',
    screen: SettingsAccountsIndex,
    isMenu: true,
  },
  {
    path: '/construction/schedule',
    title: '일정',
    screen: SettingsAccountsIndex,
    isMenu: false,
  },
  {
    path: '/construction/money',
    title: '정산 관리',
    screen: SettingsAccountsIndex,
    isMenu: true,
  },
  {
    path: '/construction/person',
    title: '하도급',
    screen: SettingsAccountsIndex,
    isMenu: false,
  },
  {
    path: '/construction/pds',
    title: '자료실',
    screen: SettingsAccountsIndex,
    isMenu: false,
  },
  {
    path: '/collections',
    title: '스타일',
    icon: AppstoreOutlined,
    isMenu: true,
    screen: CollectionsIndex,
  },
  {
    path: '/analytics',
    title: '통계',
    icon: AreaChartOutlined,
    isMenu: true,
  },
  {
    path: '/points',
    title: '포인트관리',
    icon: SettingOutlined,
    isMenu: true,
  },
  {
    path: '/settings',
    title: '설정',
    icon: SettingOutlined,
    isMenu: true,
  },
  {
    path: '/settings/accounts',
    title: '직원 관리',
    screen: SettingsAccountsIndex,
    isMenu: true,
  },
  {
    path: '/settings/notice',
    title: '공지사항',
    screen: SettingsNoticeIndex,
    isMenu: true,
  },
  {
    path: '/settings/qna',
    title: 'Q&A',
    screen: SettingsQnaIndex,
    isMenu: true,
  },
];

const USER_PAGES_BY_TYPE = {
  // 유통사
  P: DISTRIBUTOR_PAGES,
  // 시공사
  W: CONSTRUCTOR_PAGES,
};

// export const pages = [
//   {
//     path: '/',
//     title: '홈',
//     icon: HomeOutlined,
//     screen: HomeIndex,
//     isMenu: true,
//   },
//   {
//     path: '/center',
//     title: '센터 업무',
//     icon: CopyOutlined,
//     isMenu: true,
//   },
//   {
//     path: '/center/construction',
//     title: '공사관리',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/center/care',
//     title: '케어관리',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/estimate',
//     title: '견적',
//     icon: CopyOutlined,
//     isMenu: true,
//   },
//   {
//     path: '/estimate/money',
//     title: '소비자요청 견적',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/estimate/money',
//     title: '상담중인 견적',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/estimate/money',
//     title: '계약 견적',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/construction',
//     title: '공사',
//     icon: FormatPainterOutlined,
//     isMenu: true,
//   },
//   {
//     path: '/construction/items',
//     title: '공사목록',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/construction/products',
//     title: '발주목록',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/construction/schedule',
//     title: '일정',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/construction/docs',
//     title: '전자결제',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/construction/money',
//     title: '정산관리',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/construction/person',
//     title: '하도급',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/construction/pds',
//     title: '자료실',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/collections',
//     title: '스타일',
//     icon: AppstoreOutlined,
//     isMenu: true,
//     screen: CollectionsIndex,
//   },
//   {
//     path: '/analytics',
//     title: '통계',
//     icon: AreaChartOutlined,
//     isMenu: true,
//   },
//   {
//     path: '/points',
//     title: '포인트관리',
//     icon: SettingOutlined,
//     isMenu: true,
//   },
//   {
//     path: '/settings',
//     title: '설정',
//     icon: SettingOutlined,
//     isMenu: true,
//   },
//   {
//     path: '/settings/accounts',
//     title: '직원 관리',
//     screen: SettingsAccountsIndex,
//     isMenu: true,
//   },
//   {
//     path: '/settings/notice',
//     title: '공지사항',
//     screen: SettingsNoticeIndex,
//     isMenu: true,
//   },
//   {
//     path: '/settings/qna',
//     title: 'Q&A',
//     screen: SettingsQnaIndex,
//     isMenu: true,
//   },
// ];

export const useRoutes = () => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const pages = React.useMemo(() => {
    if (!principal) return [];
    return USER_PAGES_BY_TYPE[principal.type];
  }, [principal]);

  const routes = useMemo(
    () =>
      pages
        ?.filter((v) => !!v.isMenu)
        ?.reduce((output, page) => {
          const path = page.path.substring(1);
          const parentPath = '/' + path?.substring(0, path.indexOf('/'));
          const parentIndex = path?.indexOf('/') >= 0 ? output.findIndex((v) => v.path === parentPath) : undefined;
          if (parentIndex === undefined) {
            output.push({
              path: page.path,
              title: page.title,
              icon: page.icon,
            });
          } else {
            if (!output[parentIndex].children) output[parentIndex].children = [];
            output[parentIndex].children.push({
              path: page.path,
              title: page.title,
            });
          }
          return output;
        }, []),
    [pages],
  );

  return { routes, pages };
};

export default useRoutes;
