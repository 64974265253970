import React from 'react';
import { PageHeader } from 'antd';

const SettingsNoticeIndex = (props) => {
  return (
    <div>
      <PageHeader className="site-page-header" title="공지사항" subTitle="설정" />
    </div>
  );
};

export default SettingsNoticeIndex;
