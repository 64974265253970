import { Tabs } from 'antd';
import React from 'react';
import Estimate from './estimate';
import Etc from './etc';
import FloorPlan from './floorPlan';
import SpaceTab from './space';

export default function EstimateDetailTab() {
  return (
    <Tabs>
      <Tabs.TabPane tab="견적서" key={'estimate'}>
        <Estimate />
      </Tabs.TabPane>
      <Tabs.TabPane tab="도면" key={'floorPlan'}>
        <FloorPlan />
      </Tabs.TabPane>
      <Tabs.TabPane tab="공간별" key={'space'}>
        <SpaceTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="기타" key={'etc'}>
        <Etc />
      </Tabs.TabPane>
    </Tabs>
  );
}
