import React, { useCallback, useEffect, useRef } from 'react';
import { restApi } from '../apis';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setApplications, setContructs } from '../data/common';
import { isEqual } from 'lodash';

const NotifyController = (props) => {
  const audio1 = useRef();
  const dispatch = useDispatch();

  const { contructs, applications } = useSelector((s) => s.common, shallowEqual);

  const loadData = useCallback(async () => {
    const { data } = await restApi.get(`/partners/constructs`);
    if (isEqual(data, contructs)) return;
    const lastAt = data.filter((x) => !x.readed)?.[0]?.created_at;

    const dt = localStorage.getItem('last_at') || '2022-01-01T00:00:00.000Z';
    if (lastAt && moment(dt).isBefore(lastAt)) {
      audio1.current.play();
      localStorage.setItem('last_at', lastAt);
    }
    dispatch(setContructs(data));
  }, [contructs]);

  const loadData2 = useCallback(async () => {
    const { data } = await restApi.get(`/partners/proposals`, { params: { state: 'APP_READY' } });
    if (isEqual(data, applications)) return;
    const lastAt = data?.[0]?.created_at;

    const dt = localStorage.getItem('app_last_at') || '2022-01-01T00:00:00.000Z';
    if (lastAt && moment(dt).isBefore(lastAt)) {
      audio1.current.play();
      localStorage.setItem('app_last_at', lastAt);
    }
    dispatch(setApplications(data));
  }, [applications]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      Promise.all([loadData(), loadData2()]);
      return;
    }
    const tk = setInterval(() => Promise.all([loadData(), loadData2()]), 1000);
    return () => clearInterval(tk);
  }, [loadData, loadData2]);

  return (
    <>
      <audio ref={audio1} src="/sound1.mp3"></audio>
    </>
  );
};

export default NotifyController;
