import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Button, Dropdown, Menu, PageHeader, Space, Switch, Table } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { shallowEqual, useSelector } from 'react-redux';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { restApi } from '../../../apis';
import moment from 'moment';
import { parseCategory, parseImageUri } from '../../../common/utils';

const LIMIT = 20;

const COLUMN = [
  {
    key: 'id',
    dataIndex: 'id',
    align: 'center',
    width: 80,
    title: '번호',
    fixed: 'left',
  },
  {
    key: 'serial',
    dataIndex: 'serial',
    align: 'left',
    width: 170,
    title: '고유키',
    fixed: 'left',
  },
  {
    key: 'image',
    dataIndex: 'image',
    title: '이미지',
    width: 80,
    align: 'center',
    fixed: 'left',
    render: (img) => {
      return <Avatar shape="square" size={60} src={parseImageUri(img, 120, 120)} />;
    },
  },
  {
    key: 'category',
    dataIndex: 'category',
    title: '카테고리',
    width: 100,
  },
  {
    key: 'maker_name',
    dataIndex: 'maker_name',
    title: '제조사',
    width: 140,
    render: (data, row) => {
      return (
        <>
          M. {row.maker_name}
          <br />
          B. {row.brand_name}
          <br />
          C. {row.collection_name}
          <br />
        </>
      );
    },
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: '이름',
    minWidth: 300,
  },
  {
    key: 'state',
    dataIndex: 'state',
    title: '상태',
    width: 110,
    align: 'center',
  },
  {
    key: 'origin_price',
    dataIndex: 'origin_price',
    title: '제공단가',
    width: 100,
    align: 'right',
    render: (d, r) => (
      <>
        {String(d ?? 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
        <br />
        <small>({r.min_sale_price})</small>
      </>
    ),
  },
  {
    key: 'corp_price',
    dataIndex: 'corp_price',
    title: '업체가',
    width: 100,
    align: 'right',
    render: (d, r) => <>{String(d ?? 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}</>,
  },
  {
    key: 'active',
    dataIndex: 'active',
    title: '발주가능',
    width: 80,
    align: 'center',
    render: (d) => {
      return <Switch />;
    },
  },
  {
    key: 'created_at',
    dataIndex: 'created_at',
    title: '등록일',
    width: 140,
    align: 'center',
    render: (d) => {
      return moment(d).format('YYYY-MM-DD HH:mm');
    },
  },
  {
    key: 'mgr',
    dataIndex: 'id',
    title: '관리',
    width: 100,
    align: 'center',
    fixed: 'right',
    render: (d) => {},
  },
];

const ProductsItemsIndex = (props) => {
  const { productCategories } = useSelector((s) => s.common, shallowEqual);

  const treeData = useMemo(() => {
    const parseItem = (d) => {
      return {
        value: d.code,
        label: d.label,
        children: !d.children?.length ? undefined : d.children?.map((v) => parseItem(v)),
      };
    };

    return productCategories?.map((v) => parseItem(v));
  }, [productCategories]);

  const [rowKey, setRowKey] = useState([]);

  const location = useLocation();
  const [page, setPage] = useState(parseInt(qs.parse(location.search)?.page || '0', 10));
  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = {
      limit: LIMIT,
      ...qs.parse(location.search),
    };
    const { data } = await restApi.get(`/products`, { params });
    setData(data);
  }, [location.search]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const searchField = [
    {
      width: 1,
      label: '상태',
      type: 'checkbox',
      key: 'state',
      submit: true,
      items: [
        { label: '대기중', value: 'READY' },
        { label: '게시중', value: 'ACTIVE' },
      ],
    },
    {
      width: 0.5,
      label: '카테고리',
      type: 'tree_select',
      key: 'categories',
      treeData: treeData,
      submit: true,
    },
    {
      width: 0.5,
      label: '검색어',
      type: 'input',
      key: 'keyword',
    },
  ];

  const [columns, tableWidth] = useMemo(() => {
    const o = [...COLUMN];
    o[3].render = (d) => parseCategory(productCategories, d, <br />);

    let tableWidth = o.reduce((a, c) => a + (c.width ?? c.minWidth ?? 200), 0);
    return [o, tableWidth];
  }, [productCategories]);

  return (
    <div>
      <PageHeader className="site-page-header" title="등록상품 관리" subTitle="상품 관리" />
      <SearchForm
        fields={searchField}
        extra={{ page }}
        rightDeco={
          <Space>
            <Dropdown
              overlay={
                <Menu onClick={(e) => {}}>
                  <Menu.Item key="3">발주가능</Menu.Item>
                  <Menu.Item key="3">발주불가</Menu.Item>
                </Menu>
              }
            >
              <Button>관리</Button>
            </Dropdown>
          </Space>
        }
      />
      <Table
        scroll={{ x: tableWidth }}
        rowSelection={{
          selectedRowKeys: rowKey,
          onChange: (rowKey) => {
            setRowKey(rowKey);
            console.log(rowKey);
          },
        }}
        rowKey={(r) => `${r.id}`}
        dataSource={data?.items ?? []}
        columns={columns}
        pagination={{
          current: page + 1,
          total: data?.total_elements,
          pageSize: LIMIT,
        }}
        onChange={(e) => {
          setPage(e.current - 1);
        }}
      />
    </div>
  );
};

export default ProductsItemsIndex;
