import { restApi } from '..';

export const getEstimates = (id) => {
  try {
    const endpoint = '/estimates';
    return restApi.get(endpoint);
  } catch (error) {
    console.log('getEstimates width error');
  }
};

export const getEstimateDetail = async (id) => {
  try {
    const endpoint = `/estimates/${id}`;

    return restApi.get(endpoint);
  } catch (error) {
    console.log('getEstimates with error');
  }
};
